function addScreenSizesMiddleware({ payload, next }: any) {
  if (payload?.obj) {
    payload.obj.context.screen = {
      ...payload.obj.context?.screen,
      width: window?.innerWidth,
      height: window?.innerHeight,
    };
  }

  next(payload);
}

export { addScreenSizesMiddleware };
