import React from 'react';
import styled from 'styled-components';

import { Loading } from '../../icons/loading';
import { H5 } from '../../core/typography/typographyDEPRECATED';

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Header = styled(H5).attrs({ as: 'h1' })`
  margin-bottom: 10px;
`;

export interface LoadingScreenProps {
  title?: string;
}

const LoadingScreen = ({ title }: LoadingScreenProps) => {
  return (
    <Center data-testid="loading-screen">
      {title && <Header textAlign="center">{title}</Header>}
      <Loading />
    </Center>
  );
};

export { LoadingScreen };
