export const ALLERGAN_URLS = {
  abbviePrivacyPolicy:
    'https://abbviemetadata.my.site.com/AbbvieDSRM/s/?language=en_US',
  alleSupport: 'https://support.alle.com/',
  pdfBotoxPI:
    'https://media.alle.com/image/upload/v1729544455/alle/contentful/ISI/botox-cosmetic_pi_1_tvsgkh.pdf',
  privacyAndTerms: 'https://www.allergan.com/privacy-and-terms',
  termsOfUse: 'https://www.abbvie.com/terms-of-use.html',
  californiaPrivacyPolicy: 'https://www.allergan.com/privacy-and-terms/ccpa',
  unitedStatesPrivacyPolicy:
    'https://privacy.abbvie/privacy-policies/us-privacy-policy.html',
  prescribingUrl:
    'https://media.allergan.com/actavis/actavis/media/allergan-pdf-documents/product-prescribing/20190626-BOTOX-Cosmetic-Insert-72715US10-Med-Guide-v2-0MG1145.pdf',
  medicationUrl:
    'https://media.allergan.com/actavis/actavis/media/allergan-pdf-documents/product-prescribing/20190620_BTX-and-BTX-C-Med-Guide-v2-0MG1145.pdf',
  placeholderImageUrl:
    'https://media.alle.com/image/upload/v1588265444/alle/promotions/Abbvie-Hero-2_bgtyup.jpg',
  troubleshooting: 'https://allergandatalabs.zendesk.com/hc/en-us',
  /**
   * TODO: update to production URL when ready
   */
  financing: 'https://cms-preview.stage.alle.com/lp/financing',
};

export const ABBVIE_URLS = {
  cookiesAndTracking: 'https://abbv.ie/PrivacyTrackingCollection',
  dataSubjectRightsRequestForm: 'https://abbviemetadata.my.site.com/AbbvieDSRM',
  mobileTermsAndConditions:
    'https://privacy.abbvie/us-mobile-terms-and-conditions.html',
  privacyPolicy:
    'https://privacy.abbvie/privacy-policies/us-privacy-policy.html',
  termsOfUse: 'https://www.abbvie.com/terms-of-use.html',
  consumerDataHealthPrivacy: 'https://abbv.ie/PrivacyConsumerHealth',
};

/**
 * NOTE: See NOTE below
 */
export const ALLE_URLS = {
  termsAndConditions: 'https://alle.com/terms-and-conditions',
  instagram: 'https://www.instagram.com/alle/',
};

/**
 * NOTE: When there is a way to get the current environment's url
 * such as useConfig/ConfigProvider on mobile you should use these url paths
 * in conjuction with the proper environment base url.
 */
export const ALLE_URL_PATHS = {
  globalIsi: '/global-isi',
  termsAndConditions: '/terms-and-conditions',
  pointsAndEarning: '/points-and-earning',
  howAlleWorks: '/how-alle-works',
  printHipaa: '/legal/print-hipaa',
  helpfulLinks: '/article/a-list-benefits',
  walletPaymentPlans: '/account/wallet?tab=payment-plans',
  botoxTreatmentGuide: '/treatment-guide/botox?isi=open#isi',
  natrelleTreatmentGuide: '/treatment-guide/natrelle?isi=open#isi',
  financingApplication: '/financing/application',
};
