const UTM_TRACKING_KEY: string = 'alle-utm-json';

function utmValueMiddleware({ payload, next }: any): void {
  const rawUTMData = localStorage.getItem(UTM_TRACKING_KEY);
  if (rawUTMData) {
    try {
      const utmPayload = JSON.parse(rawUTMData);
      const now = Date.now();

      // utm values have expired, return early
      if (utmPayload.expiresAt && utmPayload.expiresAt < now) {
        localStorage.removeItem(UTM_TRACKING_KEY);
        return next(payload);
      }

      const sevenDays = 1000 * 60 * 60 * 24 * 7;
      utmPayload.expiresAt = now + sevenDays;
      localStorage.setItem(UTM_TRACKING_KEY, JSON.stringify(utmPayload));

      payload.obj = {
        ...payload.obj,
        properties: {
          ...payload.obj.properties,
          ...utmPayload,
        },
      };
    } catch (e) {
      console.error('segmentEvents#middleware', e);
    }
  }

  next(payload);
}

export { utmValueMiddleware };
