import styled from 'styled-components';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';
import { H3, H5 } from '../core/typography/typographyDEPRECATED';
import { colors } from '../core/colors';

const DrawerButtonContainer = styled.div`
  /* Fix mobile issue where this div has no height */
  min-height: 22px;

  margin-top: 50px;
  margin-bottom: 27px;
  margin-left: 32px;
  margin-right: 25px;

  ${above.desktop`
    margin-left: 28%;
    margin-right: 48px;
  `}

  display: flex;

  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${above.desktop`
    button:hover,
    button.Mui-focusVisible {
      & .MuiSvgIcon-root path {
        fill: ${colors.skin};
      }
    }
  `}
`;

const DrawerContentContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${above.desktop`
    margin-left: 28%;
    width: 45%;
  `}
`;

const DrawerNavButtonContainer = styled.div`
  margin-top: 24px;

  ${H3} {
    margin: 0;
  }

  a {
    display: inline-block;
    text-decoration: none;

    &.active {
      h2,
      h3 {
        color: ${colors.skin};
      }
    }
  }

  a:hover,
  a:focus {
    outline: none;
  }
  ${above.desktop`
    button.Mui-focusVisible,
    button.MuiButtonBase-root:hover,
    a:hover,
    a:focus {
      h2,
      h3 {
        color: ${colors.skin};
      }
    }
  `}
`;

const DrawerRootFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 70px;
  margin-bottom: 30px;

  ${above.desktop`
    margin-bottom: 79px;
  `}
`;

const DrawerRootFooterButtonContainer = styled.div`
  .MuiButtonBase-root,
  a {
    justify-content: flex-start;

    ${H5} {
      margin: 2px 0;
    }

    &.Mui-focusVisible,
    &:focus {
      ${H5} {
        color: ${colors.gray1};
      }
    }
  }

  a {
    text-decoration: none;
    outline: none;
  }

  & + & {
    margin-top: 8px;
  }
`;

export {
  DrawerButtonContainer,
  DrawerContentContainer,
  DrawerNavButtonContainer,
  DrawerRootFooter,
  DrawerRootFooterButtonContainer,
};
