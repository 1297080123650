export interface DatadogConfig {
  prod: {
    clientToken: string;
    applicationId: string;
    trackInteractions: boolean;
    service: string;
    env: 'production' | 'stage';
  };
  stage: {
    clientToken: string;
    applicationId: string;
    trackInteractions: boolean;
    service: string;
    env: 'production' | 'stage';
  };
}

// need to add landing project to datadog
export type Application = 'consumer' | 'cmsPreview' | 'landing';

const datadogConfig: Record<Application, DatadogConfig> = {
  consumer: {
    prod: {
      clientToken: 'pub5be682fa22cc539058ed1a1f21c387c0',
      applicationId: '3853b9c0-a789-4d95-a966-7eaa65157907',
      trackInteractions: false,
      env: 'production',
      service: 'consumer-web-prod',
    },
    stage: {
      clientToken: 'pubcb4a417bbe953716075f043aff986365',
      applicationId: 'bafb54b6-6ed9-4a5f-b811-66113fca7826',
      trackInteractions: true,
      env: 'stage',
      service: 'consumer-web-stage',
    },
  },
  cmsPreview: {
    // should match consumer configurations, except for 'service'
    prod: {
      clientToken: 'pub5be682fa22cc539058ed1a1f21c387c0',
      applicationId: '3853b9c0-a789-4d95-a966-7eaa65157907',
      trackInteractions: false,
      env: 'production',
      service: 'cms-preview-web-prod',
    },
    stage: {
      clientToken: 'pubcb4a417bbe953716075f043aff986365',
      applicationId: 'bafb54b6-6ed9-4a5f-b811-66113fca7826',
      trackInteractions: true,
      env: 'stage',
      service: 'cms-preview-web-stage',
    },
  },
  landing: {
    prod: {
      clientToken: 'pub26a39794fe23fd1b7e76b5c0082c9349',
      applicationId: '2325a811-ec95-49e5-a738-bacba7d848d9',
      trackInteractions: false,
      env: 'production',
      service: 'landing-web-prod',
    },
    stage: {
      clientToken: 'pub980d2bce38f41a1410f786321842a4d5',
      applicationId: '11cce75b-7588-4c73-bcda-b81f96fb0af8',
      trackInteractions: true,
      env: 'stage',
      service: 'landing-web-stage',
    },
  },
};

const getDatadogConfig = (applicationName: Application) => {
  if (
    process.env.REACT_APP_DATADOG_ENV === 'production' ||
    process.env.NEXT_PUBLIC_DATADOG_ENV === 'production'
  ) {
    return datadogConfig[applicationName].prod;
  }

  return datadogConfig[applicationName].stage;
};

export { datadogConfig, getDatadogConfig };
