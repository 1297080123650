import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { ThemeTypes } from '../components/theme';
import { IconContainer } from './iconContainer';

const Close: React.FunctionComponent<
  ThemeTypes & {
    fill?: string;
    width?: string;
    height?: string;
  }
> = ({ dark, className, fill, width, height }) => (
  <IconContainer
    dark={dark}
    className={className}
    pathFill={fill}
    width={width}
    height={height}
  >
    <SvgIcon width="22" height="22" viewBox="0 0 22 22">
      <path d="M16.7346854,6.54461492 C17.0884382,6.19176688 17.0884382,5.61816263 16.7346854,5.26531459 C16.3818374,4.9115618 15.8091379,4.9115618 15.4553851,5.26531459 L11.0004524,9.7202473 L6.54461492,5.26531459 C6.19176688,4.9115618 5.61816263,4.9115618 5.26531459,5.26531459 C4.9115618,5.61816263 4.9115618,6.19176688 5.26531459,6.54461492 L9.7202473,11.0004524 L5.26531459,15.4553851 C4.9115618,15.8091379 4.9115618,16.3818374 5.26531459,16.7346854 C5.61816263,17.0884382 6.19176688,17.0884382 6.54461492,16.7346854 L11.0004524,12.2797527 L15.4553851,16.7346854 C15.8091379,17.0884382 16.3818374,17.0884382 16.7346854,16.7346854 C17.0884382,16.3818374 17.0884382,15.8091379 16.7346854,15.4553851 L12.2797527,11.0004524 L16.7346854,6.54461492 Z"></path>
    </SvgIcon>
  </IconContainer>
);

export { Close };
