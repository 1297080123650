const skin = {
  100: '#FEFCFB',
  200: '#FCF7F5',
  300: '#FAEFEC',
  400: '#F7E8E2',
  500: '#F5E0D8',
  600: '#B8A8A2',
  700: '#7A706C',
  800: '#3D3836',
  900: '#191616',
};

export { skin };
