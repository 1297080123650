import React from 'react';
import styled from 'styled-components';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { colors } from './colors';
import { P } from './typography/typographyDEPRECATED';
import { useLinkContext } from './linkContext';
import { preloadRoute } from './preload';
import { __DEV__ } from '../environment';

const StyledLink = styled(RouterLink)`
  text-decoration: none;
`;

const StyledA = styled.a`
  text-decoration: none;
`;

export interface LinkProps extends RouterLinkProps {
  color?: colors;
  external?: boolean;
  textOnly?: boolean;
  variant?: 'bodyText3' | 'bodyText2' | 'bodyText1';
  weight?: 'normal' | 'bold';
  className?: string;
  activeWhenNotExact?: boolean;
  preload?: boolean;
}

function isString(arg: any): arg is string {
  return typeof arg === 'string' || arg !== null;
}

interface SimpleRouterLink {
  pathname: string;
}

function hasPathname(arg: any): arg is SimpleRouterLink {
  return (arg as SimpleRouterLink)?.pathname !== undefined;
}

/**
 * @deprecated use the link from the EnvironmentContext'.
 */
const LinkDEPRECATED: React.FunctionComponent<LinkProps> = ({
  children,
  color,
  external,
  textOnly = true,
  variant = 'bodyText3',
  weight = 'normal',
  to,
  activeWhenNotExact,
  preload,
  ...forwardProps
}) => {
  const maybeRouterLink: unknown = to as unknown;

  let href: string;

  if (isString(maybeRouterLink)) {
    href = maybeRouterLink;
  } else if (hasPathname(maybeRouterLink)) {
    href = maybeRouterLink.pathname;
  } else {
    if (__DEV__) {
      throw new Error('link#pathname can not be null');
    }

    href = '/';
  }

  const { routes } = useLinkContext();
  const location = useLocation();
  let active = false;

  React.useEffect(() => {
    // No need to preload if external (opens in new tab)
    if (!preload || external) {
      return;
    }

    preloadRoute(routes, href);
  }, [href, routes, external, preload]);

  if (!external) {
    const match = matchPath(location.pathname, href);
    if (match && (activeWhenNotExact || match.isExact)) {
      active = true;
    }
  }

  const LinkComponent: React.ElementType = external ? StyledA : StyledLink;
  const linkProps = external
    ? {
        href: href,
        target: '_blank',
        rel: 'noopener noreferrer',
        ...forwardProps,
      }
    : {
        to: href,
        ...forwardProps,
        className: `${active ? 'active' : ''} ${forwardProps.className || ''}`,
      };

  return (
    <LinkComponent {...linkProps}>
      {textOnly ? (
        <P variant={variant} color={color || colors.accent} weight={weight}>
          {children}
        </P>
      ) : (
        children
      )}
    </LinkComponent>
  );
};

export { LinkDEPRECATED };
