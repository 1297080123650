import React from 'react';

import { GeneralError } from './generalError';
import { ErrorPage400 } from '../components/errorPages/errorPage400';

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  generalErrorRoute?: string;
  generalErrorButtonText?: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false, code: null };

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      code: parseInt(error.networkError?.statusCode || error.code, 10) || null,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.state.code === 400) {
      return <ErrorPage400 />;
    }
    if (this.state.hasError) {
      return (
        <GeneralError
          buttonRoute={this.props.generalErrorRoute}
          buttonText={this.props.generalErrorButtonText}
        />
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
