import { Dispatch, SetStateAction, createContext } from 'react';
import { EventData, State } from 'xstate';
import { ApolloError } from 'apollo-client';
import { ConsumerViewerProfile } from '../utils/hooks/useLazyConsumerProfile';
import { AuthMachineContext, AuthMachineEvent } from './authMachine';

export type AuthState = State<AuthMachineContext, AuthMachineEvent, any, any>;
export type CurrentUser = Partial<ConsumerViewerProfile>;

export interface AuthContextValue {
  authState: AuthState;
  sendAuthEvent: (
    event: AuthMachineEvent['type'],
    payload?: EventData | undefined
  ) => void;
  currentUser: CurrentUser | null;
  consumerProfileLoading: boolean;
  consumerProfileError?: ApolloError;
  setIsInlineAuth: Dispatch<SetStateAction<boolean>>;
}

const defaultContext: AuthContextValue = {
  // @ts-ignore
  authState: null,
  sendAuthEvent: () => {},
  currentUser: null,
};

const AuthContext = createContext<AuthContextValue>(defaultContext);

export { AuthContext };
