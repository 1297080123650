const fullStoryMiddleware = ({
  payload,
  next,
}: {
  payload: {
    obj: {
      traits: object | null | undefined;
    };
  };
  next: (payload: object) => void;
}) => {
  // redact all PII information from accidentally leaking to full story.
  if ('obj' in payload && 'traits' in payload.obj) {
    payload.obj.traits = {};
  }
  next(payload);
};

export { fullStoryMiddleware };
