import React from 'react';
import { Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { defaultRichTextRenderOptions } from './defaultRichTextRenderOptions';
import { CustomizeDefaultOptions } from './types';
import { Flex, FlexProps } from '@allergan-data-labs/alle-elements-layout';

export type RichTextProps = CustomizeDefaultOptions & {
  richText?: Document;
  containerProps?: FlexProps;
};

/**
 * Common component for implementing Contentful rich text
 *
 * @param richText - The rich text json
 * @param customizeDefaultOptions - Params passed through to defaultRichTextRenderOptions (see type CustomizeDefaultOptions)
 */
const RichText = ({
  richText,
  containerProps,
  ...customizeDefaultOptions
}: RichTextProps) => {
  if (!richText) return null;
  return (
    <Flex
      flexDirection="column"
      className="rich-text"
      data-testid="rich-text"
      {...containerProps}
    >
      {documentToReactComponents(
        richText,
        defaultRichTextRenderOptions({
          ...customizeDefaultOptions,
        })
      )}
    </Flex>
  );
};

export { RichText };
