const bone = {
  100: '#FFFEFE',
  200: '#FEFDFC',
  300: '#FDFAFA',
  400: '#FCF8F7',
  500: '#FBF6F5',
  600: '#BCB8B8',
  700: '#7E7B7A',
  800: '#3F3D3D',
  900: '#191919',
};

export { bone };
