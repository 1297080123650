import React from 'react';

import { NotificationContext } from '../notificationBar/notificationContext';

export const useDemoNotification = () => {
  const loaded = React.useRef<boolean>(false);
  const { addNotification } = React.useContext(NotificationContext);

  React.useEffect(
    () => {
      if (!loaded.current && process.env.REACT_APP_ALLE_PROXY === 'demo') {
        addNotification({
          id: 'demo-environment',
          text: (
            <strong>THIS IS A DEMO ENVIRONMENT.</strong>
          ) as React.ReactNode,
          variant: 'warning',
          liveFor: 10,
        });

        loaded.current = true;
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    },
    process.env.REACT_APP_ALLE_PROXY === 'demo' ? [addNotification] : []
  );
};
