import { createContext, useContext } from 'react';
import { EnvironmentContextType } from './types';

/**
 * The Environment context should be used to define specific implementation details (such as what component to use for implementing a Link) based on the 'environment' of the react app.
 * A specific implementation of a component should have props that are generic enough that swapping it with a different implementation is relatively easy
 * See an example of this pattern at {@link  https://www.youtube.com/watch?v=yi8UCnXPu8U}.
 *
 * NOTE: Implementation details ARE tied to our chosen design system (Alle Elements), and therefore the EnvironmentContext.Provider should be nested within AlleElementsProvider
 *
 */
export const EnvironmentContext = createContext<EnvironmentContextType>({
  Link: () => null,
});

/**
 *  Provides specific implementations based on the EnvironmentContext.Provider
 *
 * @returns {object} context object
 * @returns {React.JSX} context.Link - Component that should be used when implementing navigational links
 *
 * @example <caption>Example usages of Link</caption>
 * const { Link } = useEnvironmentContext();
 *
 * <Link href='https://www.abbvie.com' >external link, opens in same tab</Link>
 * <Link href='https://www.abbvie.com' target='_blank'>external link, opens in new tab</Link>
 * <Link href='/how-alle-works'>internal link, opens in same tab</Link>
 * <Link href='/how-alle-works' target='_blank'>internal link, opens in new tab</Link>
 * <Link href='/registration' referrerData={{ authFlowState: { authenticationFlowCodeId: 'botoxDay' } }}>internal link, passes referrerData to the /registration route</Link>
 */
export const useEnvironmentContext = () => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error(
      'useEnvironmentContext must be used within an EnvironmentContext.Provider'
    );
  }
  return context;
};
