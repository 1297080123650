import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
  SecureRoute as SecureRouteComponent,
  SECURE_ROUTE_STATUS,
} from '@packages/deprecated-component-library/src/components/secureRoute';
import { useAuth } from './AuthProvider';
import { AuthState } from './authMachine';

import { Routes } from '../constants/routes';

interface SecureRouteProps extends RouteProps {
  title?: string;
}

const SecureRoute: React.FunctionComponent<SecureRouteProps> = ({
  title,
  ...rest
}) => {
  const { authState } = useAuth();
  return (
    <SecureRouteComponent
      {...rest}
      defaultRoute={Routes.login}
      status={
        authState.matches(AuthState.isLoggedIn)
          ? SECURE_ROUTE_STATUS.isLoggedIn
          : authState.matches(AuthState.isNotLoggedIn)
          ? SECURE_ROUTE_STATUS.isNotLoggedIn
          : SECURE_ROUTE_STATUS.loading
      }
    />
  );
};

export { SecureRoute };
