import React, { useContext } from 'react';
import {
  Heading,
  ListItem,
  Text,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import { getTypographyToken } from '@allergan-data-labs/alle-elements-core';
import { BLOCKS, NodeData, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { ILink } from '@packages/core-contentful-rest';
import { ButtonLink } from '@packages/core-components/src/components/button-link/buttonLink';
import { scrollIntoView } from '@packages/core-components/src/utils/scrollIntoView';

import {
  getGqlEntryHyperlink,
  getOrderedListStyleTypeByLevel,
  getUnorderedListStyleTypeByLevel,
  ListContext,
} from './defaultStyleHelpers';
import { CustomizeDefaultOptions, LinkEntry } from './types';
import { CmsLink } from '../cms-link/cmsLink';

// TODO: update this logic if request for link enhacement is approved/implemented: UC-3754
const typographyCss = getTypographyToken('Body/Medium/Regular');
const linkStyles = typographyCss
  ? Object.keys(typographyCss).reduce(
      (styles, key) => {
        styles[key] = 'inherit';
        return styles;
      },
      { textColor: 'inherit' } as Record<string, string>
    )
  : { textColor: 'inherit' };

export const defaultRichTextRenderOptions = ({
  customRenderNodeOptions,
  customRenderTextOption,
  nodeProps,
  gqlLinks,
  onEntryHyperlinkClick,
}: CustomizeDefaultOptions): Options => {
  return {
    preserveWhitespace: true,
    renderMark: {
      [MARKS.SUPERSCRIPT]: (text: React.ReactNode) => <sup>{text}</sup>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_: NodeData, children: React.ReactNode) => (
        <Heading
          as="h1"
          textStyle={'Headline/Extra large/Primary regular'}
          {...nodeProps?.h1}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_2]: (_: NodeData, children: React.ReactNode) => (
        <Heading
          as="h2"
          textStyle={'Headline/Large/Primary regular'}
          {...nodeProps?.h2}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (_: NodeData, children: React.ReactNode) => (
        <Heading
          as="h3"
          textStyle={'Headline/Medium/Primary regular'}
          {...nodeProps?.h3}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.PARAGRAPH]: (_: NodeData, children: React.ReactNode) => (
        <Text textStyle="Body/Medium/Regular" {...nodeProps?.p}>
          {children}
        </Text>
      ),
      [INLINES.HYPERLINK]: (node: NodeData, children: React.ReactNode) => {
        if (!node?.data?.uri) return;
        if (node?.data?.uri.includes('scrollAnchor=')) {
          // This link text indicates link should scroll to some element on current page
          return (
            <ButtonLink
              {...linkStyles}
              variant="action"
              onClick={() => scrollIntoView(node?.data?.uri)}
            >
              {children}
            </ButtonLink>
          );
        }
        return (
          <CmsLink {...linkStyles} variant="action" href={node.data.uri}>
            {children}
          </CmsLink>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (
        node: NodeData,
        children: React.ReactNode
      ) => {
        const entryLink: LinkEntry | undefined =
          (node?.data?.target as ILink)?.fields ||
          getGqlEntryHyperlink(node.data.target, gqlLinks);
        if (!entryLink?.url) return;
        if (entryLink?.url?.includes('scrollAnchor=')) {
          // This link text indicates link should scroll to some element on current page
          return (
            <ButtonLink
              {...linkStyles}
              variant="action"
              onClick={() => entryLink.url && scrollIntoView(entryLink.url)}
            >
              {children}
            </ButtonLink>
          );
        }
        return (
          <CmsLink
            {...linkStyles}
            href={entryLink.url}
            variant="action"
            onClick={
              onEntryHyperlinkClick
                ? (event) => {
                    onEntryHyperlinkClick({
                      event,
                      link: entryLink,
                      linkText: node.content[0].value,
                    });
                  }
                : undefined
            }
          >
            {children}
          </CmsLink>
        );
      },
      [BLOCKS.UL_LIST]: (_: NodeData, children: React.ReactNode) => {
        const List: React.FunctionComponent = () => {
          const { listLevel } = useContext(ListContext);
          const newListLevel = listLevel + 1;
          return (
            <ListContext.Provider value={{ listLevel: newListLevel }}>
              <UnorderedList
                styleType={getUnorderedListStyleTypeByLevel(newListLevel)}
                marginBlockStart={0}
                marginBlockEnd={0}
                textAlign={'left'}
                paddingInlineStart={0}
                sx={{
                  'li > *:not(ol):not(ul)': {
                    display: 'list-item',
                    listStylePosition: 'inside',
                  },
                }}
              >
                {children}
              </UnorderedList>
            </ListContext.Provider>
          );
        };

        return <List />;
      },
      [BLOCKS.OL_LIST]: (_: NodeData, children: React.ReactNode) => {
        const List: React.FunctionComponent = () => {
          const { listLevel } = useContext(ListContext);
          const newListLevel = listLevel + 1;
          return (
            <ListContext.Provider value={{ listLevel: newListLevel }}>
              <OrderedList
                styleType={getOrderedListStyleTypeByLevel(newListLevel)}
                marginBlockStart={0}
                marginBlockEnd={0}
                textAlign={'left'}
                paddingInlineStart={0}
                sx={{
                  'li > *:not(ol):not(ul)': {
                    display: 'list-item',
                    listStylePosition: 'inside',
                  },
                }}
              >
                {children}
              </OrderedList>
            </ListContext.Provider>
          );
        };

        return <List />;
      },
      [BLOCKS.LIST_ITEM]: (_: NodeData, children: React.ReactNode) => (
        <ListItem
          // to display the li indicator same size as text, display li child as list-item and display list item as 'contents'
          display={'contents'}
        >
          {children}
        </ListItem>
      ),
      ...customRenderNodeOptions,
    },
    renderText: customRenderTextOption,
  };
};
