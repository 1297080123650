import React from 'react';
import {
  Box,
  Button,
  Text,
  forwardRef,
  useColorMode,
  SystemStyleObject,
} from '@chakra-ui/react';
import {
  ButtonGroup,
  IconButton,
} from '@allergan-data-labs/alle-elements-button';
import { ChevronDownIcon } from '@allergan-data-labs/alle-elements-icons/24/chevronDownIcon';
import {
  ColorMode,
  getTypographyToken,
} from '@allergan-data-labs/alle-elements-core';

export const getNavigationDrawerGroupButtonStyles = (): SystemStyleObject => ({
  w: '100%',
  minW: 238,
});

export const getNavigationDrawerButtonStyles = (
  colorMode?: ColorMode
): SystemStyleObject => ({
  w: '100%',
  h: 36,
  maxH: 36,
  py: 8,
  px: 12,
  display: 'flex',
  alignItems: 'center',
  color: `${colorMode}.Text/Neutral/Default`,
  bg: `${colorMode}.Container/Brand/Navlink/Enable`,
  _hover: {
    color: `${colorMode}.Text/Neutral/Support`,
  },
  _active: {
    color: `${colorMode}.Text/Neutral/Support`,
    fontWeight: 'Normal',
  },
  '& svg': {
    w: 20,
    h: 20,
  },
});

export const getNavigationDrawerIconButtonStyles = (
  colorMode?: ColorMode
): SystemStyleObject => ({
  w: 40,
  h: 36,
  minW: 40,
  bg: `${colorMode}.Container/Brand/Navlink/Enable`,
  _hover: {
    bg: `${colorMode}.Container/Brand/Navlink/Hover`,
  },
  _active: {
    bg: `${colorMode}.Container/Brand/Navlink/Active`,
  },
  _focus: {},
  _focusVisible: {
    outline: 'none',
    bg: `${colorMode}.Container/Brand/Navlink/Hover`,
  },
  _pressed: {
    bg: `${colorMode}.Container/Brand/Navlink/Pressed`,
  },
});

export const getNavigationDrawerButtonIconStyles = (): SystemStyleObject => ({
  w: 20,
  h: 20,
  mr: 8,
  ml: -4,
});

export const getNavigationDrawerButtonSwitchIconStyles =
  (): SystemStyleObject => ({
    w: 20,
    h: 20,
    mr: 16,
    mt: 2,
  });

export const getNavigationDrawerTextStyles = (
  boldText?: boolean,
  isActive?: boolean
): SystemStyleObject => ({
  ...getTypographyToken(`Body/Small/${boldText ? 'Medium' : 'Regular'}`),
  h: 24,
  mr: 'auto',
  fontWeight: isActive ? 'Semibold' : boldText ? 'Medium' : 'Regular',
  display: 'inline-block',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '& svg': {
    w: 20,
    h: 20,
  },
});

export const getNavigationDrawerIconFlexStyles = (): SystemStyleObject => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const getNavigationDrawerIconStyles = (
  isOpen?: boolean,
  colorMode?: ColorMode
): SystemStyleObject => {
  const styles: SystemStyleObject = {
    transition: 'transform 0.2s',
    transformOrigin: 'center',
    color: `${colorMode}.Text/Neutral/Default`,
    mr: -2,
  };

  if (isOpen) {
    styles.transform = 'rotate(-180deg)';
  }

  return styles;
};

export const getNavigationItemStackStyles = (): SystemStyleObject => ({
  gap: 8,
});

const NavigationDrawerChevronIcon = ({
  isOpen,
  colorMode = 'light',
}: {
  isOpen?: boolean;
  colorMode?: ColorMode;
}) => {
  return (
    <ChevronDownIcon
      className="navigation-drawer-popover__icon"
      sx={{ ...getNavigationDrawerIconStyles(isOpen, colorMode) }}
    />
  );
};

export interface NavigationDrawerButtonProps {
  /**
   * The left icon to be displayed on the button.
   */
  icon?: React.ReactElement;
  /**
   * The title or label of the button.
   */
  title?: string;
  /**
   *  Custom render function for the title.
   */
  renderTitle?: (
    props: Partial<Omit<NavigationDrawerButtonProps, 'renderTitle'>>
  ) => React.ReactElement;
  /**
   * Callback function when the main button is clicked.
   */
  onClick?: () => void;
  /**
   * Callback function when the Chevron button is clicked.
   */
  onChevronButtonClick?: () => void;
  /**
   * Determines if the button is in an open state.
   */
  isOpen?: boolean;
  /**
   * The chevron right icon to be displayed on the button.
   * @default false
   */
  showChevronIcon?: boolean;
  /**
   * Determines if the text should be bold.
   * @default true
   */
  isBoldText?: boolean;
  /**
   * Determines if the button is in an active state
   * @default false
   */
  isActive?: boolean;
}

/**
 * Represents a button within the NavigationDrawer component.
 * This button can be used to trigger actions or toggle the visibility of nested items.
 */
export const NavigationDrawerButton = forwardRef<
  NavigationDrawerButtonProps,
  'div'
>(
  (
    {
      icon,
      title,
      renderTitle,
      onClick,
      onChevronButtonClick,
      isOpen,
      showChevronIcon = false,
      isBoldText = true,
      isActive = false,
      ...rest
    },
    ref
  ) => {
    const { colorMode } = useColorMode();

    const renderIcon = () => (
      <Box
        aria-hidden={true}
        sx={{
          ...getNavigationDrawerIconFlexStyles(),
          ...getNavigationDrawerButtonIconStyles(),
        }}
      >
        {icon}
      </Box>
    );

    return (
      <ButtonGroup
        ref={ref}
        isAttached
        sx={getNavigationDrawerGroupButtonStyles()}
        colorMode={colorMode}
        {...rest}
      >
        <Button
          onClick={onClick}
          sx={getNavigationDrawerButtonStyles(colorMode)}
        >
          {icon && renderIcon()}
          {(renderTitle && renderTitle({ title })) || (
            <Text
              isTruncated
              sx={getNavigationDrawerTextStyles(isBoldText, isActive)}
            >
              {title}
            </Text>
          )}
          {showChevronIcon && (
            <NavigationDrawerChevronIcon
              isOpen={isOpen}
              colorMode={colorMode}
            />
          )}
        </Button>
        {onChevronButtonClick && (
          <IconButton
            variant="ghost"
            colorScheme="muted"
            size="sm"
            aria-label="Toggle Navigation Drawer"
            colorMode={colorMode}
            sx={getNavigationDrawerIconButtonStyles(colorMode)}
            onClick={onChevronButtonClick}
            icon={
              <NavigationDrawerChevronIcon
                isOpen={isOpen}
                colorMode={colorMode}
              />
            }
          />
        )}
      </ButtonGroup>
    );
  }
);
