import React from 'react';
import { CopyrightFallback } from '@packages/deprecated-component-library/src/constants/copyright';
import { cmsFooterToProps } from '@packages/deprecated-consumer-component-library/src/footers/footerHelpers';
import { useCMSFooterQuery, FooterCodeID } from './useCMSFooterQuery';
import { Footer } from './footer';
import { Skeleton } from '@allergan-data-labs/alle-elements-skeleton';

const DefaultFooter: React.FunctionComponent = () => {
  const { data, isLoading, hasError } = useCMSFooterQuery({
    codeId: FooterCodeID.defaultConsumerFooter,
  });

  if (hasError) {
    return <Footer copyright={<CopyrightFallback />} />;
  } else if (isLoading) {
    return (
      <Footer
        copyright={
          <Skeleton
            colorMode="dark"
            width="100%"
            height="42px"
            delay={1}
            duration={0.5}
          />
        }
      />
    );
  }

  return <Footer {...cmsFooterToProps(data)} />;
};

export { DefaultFooter };
