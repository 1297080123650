import React from 'react';
import { matchPath } from 'react-router-dom';
import { RouteConfig } from './linkContext';

const ReactLazyPreload = (
  importStatement: () => Promise<{ default: React.FunctionComponent<any> }>
) => {
  const Component: React.LazyExoticComponent<React.FC<{}>> & {
    preload?: () => any;
  } = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const preloadRoute = (routes: RouteConfig[], preloadPath: string) => {
  const matchingRoute = routes.find((route) =>
    matchPath(preloadPath, {
      path: route.path,
      exact: true,
    })
  );
  // @ts-ignore
  matchingRoute?.component?.preload?.();
};

export { preloadRoute, ReactLazyPreload };
