import styled from 'styled-components';
import {
  bodyText1,
  heading1Style,
  heading2Style,
  heading3Style,
  heading4Style,
  heading5Style,
} from '../core/typography/typographyDEPRECATED';
import { colors } from '../core/colors';

const HtmlContainer = styled.span`
  p {
    ${bodyText1({ variant: 'bodyText1' })}
  }
  h1 {
    ${heading1Style}
  }
  h2 {
    ${heading2Style}
  }
  h3 {
    ${heading3Style}
  }
  h4 {
    ${heading4Style}
  }
  h5 {
    ${heading5Style}
  }
  a {
    color: ${colors.black};
  }
  li {
    ${bodyText1({ variant: 'bodyText1' })}
  }
  div {
    ${bodyText1({ variant: 'bodyText1' })}
  }
`;

export { HtmlContainer };
