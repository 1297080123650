import React from 'react';
import { ErrorPage } from './errorPage';

const ErrorPage400: React.FunctionComponent = () => (
  <ErrorPage
    title="400"
    subtitle={
      <span>
        A bad request was made,
        <br />
        please refresh the page.
      </span>
    }
  />
);

export { ErrorPage400 };
