import React from 'react';
import styled from 'styled-components';
import { colors } from '../core/colors';
import { LoadingBar, LoadingBarProps } from './loadingBar';
import { LoadingContext } from './loadingContext';

const StyledGlobalLoadingBar = styled(LoadingBar)`
  height: 3px;
  background-color: ${colors.gray200};
  border-radius: 0;
  position: absolute;
  z-index: 1000;

  .progress {
    background-color: ${colors.skin};
  }
`;

const GlobalLoadingBar: React.FunctionComponent<LoadingBarProps> = () => {
  const { isLoading, duration } = React.useContext(LoadingContext);
  return isLoading ? <StyledGlobalLoadingBar durationMs={duration} /> : null;
};

export { GlobalLoadingBar };
