import React from 'react';
import { GqlRichTextLinks } from './types';

export const ListContext = React.createContext<{ listLevel: number }>({
  listLevel: 0,
});
export const useListContext = () => React.useContext(ListContext);

// for unordered list
export const getListStyleTypeByLevel = (listLevel: number): string => {
  switch (listLevel % 3) {
    case 1:
      return 'decimal';
    case 2:
      return 'lower-alpha';
    default:
      return 'lower-roman';
  }
};

// get link from gql entry links
export const getEntryHyperlink = (
  gqlEntry: { sys: { id: string; linkType?: 'Entry'; type: 'Link' } },
  links?: GqlRichTextLinks
) => {
  if (
    !links &&
    gqlEntry.sys.linkType === 'Entry' &&
    gqlEntry.sys.type === 'Link'
  ) {
    console.error(
      "You're trying to render a rich text entry link from a gql response and there are no gql entry links defined, did you forget to pass in the 'links' prop to the rich text component? "
    );
  }
  return links?.find((link) => link?.sys.id === gqlEntry.sys.id);
};
