import { colors } from '../colors';
import { MouseEventHandler } from 'react';

export type ButtonProps = {
  id?: string;
  /**
   * The type of button to use.
   *
   * @default outlined
   */
  buttonType?: 'outlined' | 'contained';
  /**
   * The color scheme to use.
   *
   * @default light
   */
  variant?: 'dark' | 'light' | 'bone';
  /**
   * If `true`, the button will be disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the button will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If an element is provided, it will be rendered inside the button instead of children.
   */
  icon?: any;
  /**
   * Display a loading spinner within the button.
   *
   * @default false
   */
  loading?: boolean;
  /**
   * Optionally provide a custom loading Icon.
   */
  loadingIcon?: React.ReactNode;
  /**
   * Element placed after the children.
   */
  endIcon?: React.ReactNode;
  /**
   * Element placed before the children.
   */
  startIcon?: React.ReactNode;
  /**
   * DEPRECATED: Instead wrap with `styled-components` to add custom styles.
   *
   * @default false
   */
  marginTop?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  children: any;
  className?: string;
  role?: string;
  tabIndex?: string;
  /**
   * DEPRECATED: Instead use `variant="dark"`.
   */
  dark?: boolean;
  ['aria-label']?: string;
};

interface ButtonTheme {
  backgroundColor: string;
  borderColor: string;
  color: string;
  disableBorder: string;
  disabledBackgroundColor: string;
  disabledColor: string;
  disabledOpacity: string;
  hoverBackgroundColor: string;
  hoverBorderColor: string;
  hoverColor: string;
  hoverDisabledBackgroundColor: string;
  loadingBackgroundColor: string;
  loadingStroke: string;
  name: string;
}

type ButtonTypes = 'outlined' | 'contained';
const lightTheme: Record<ButtonTypes, ButtonTheme> = {
  outlined: {
    backgroundColor: colors.white,
    borderColor: `1px solid ${colors.black}`,
    color: colors.black,
    disableBorder: `1px solid ${colors.gray200}`,
    disabledBackgroundColor: colors.gray200,
    disabledColor: colors.black,
    disabledOpacity: '1.0',
    hoverBackgroundColor: colors.black,
    hoverBorderColor: `1px solid ${colors.black}`,
    hoverColor: colors.white,
    hoverDisabledBackgroundColor: 'transparent',
    loadingBackgroundColor: colors.black,
    loadingStroke: colors.white,
    name: 'outlined - light - theme',
  },
  contained: {
    backgroundColor: colors.black,
    borderColor: `1px solid ${colors.black}`,
    color: colors.white,
    disableBorder: `1px solid ${colors.gray200}`,
    disabledBackgroundColor: colors.gray200,
    disabledColor: colors.black,
    disabledOpacity: '1',
    hoverBackgroundColor: colors.darkGray,
    hoverBorderColor: `1px solid ${colors.darkGray}`,
    hoverColor: colors.white,
    hoverDisabledBackgroundColor: colors.darkGray,
    loadingBackgroundColor: colors.black,
    loadingStroke: colors.white,
    name: 'contained - light - theme',
  },
};

const darkTheme: Record<ButtonTypes, ButtonTheme> = {
  outlined: {
    backgroundColor: colors.black,
    borderColor: `1px solid ${colors.white}`,
    color: colors.white,
    disableBorder: `1px solid ${colors.white}`,
    disabledBackgroundColor: colors.black,
    disabledColor: colors.white,
    disabledOpacity: '0.5',
    hoverBackgroundColor: colors.white,
    hoverBorderColor: `1px solid ${colors.white}`,
    hoverColor: colors.black,
    hoverDisabledBackgroundColor: 'transparent',
    loadingBackgroundColor: colors.black,
    loadingStroke: colors.white,
    name: 'outlined - dark - theme',
  },
  contained: {
    backgroundColor: colors.white,
    borderColor: `1px solid ${colors.white}`,
    color: colors.black,
    disableBorder: `1px solid ${colors.black}`,
    disabledBackgroundColor: colors.black,
    disabledColor: colors.white,
    disabledOpacity: '1',
    hoverBackgroundColor: 'rgba(255,255,255,0.9)',
    hoverBorderColor: `1px solid rgba(255,255,255,0.9)`,
    hoverColor: colors.black,
    hoverDisabledBackgroundColor: colors.darkGray,
    loadingBackgroundColor: colors.white,
    loadingStroke: colors.black,
    name: 'contained - dark - theme',
  },
};

const boneTheme: Record<ButtonTypes, ButtonTheme> = {
  outlined: {
    backgroundColor: colors.bone,
    borderColor: `1px solid ${colors.nudeDark}`,
    color: colors.nudeDark,
    disableBorder: `1px solid ${colors.nudeDark}`,
    disabledBackgroundColor: colors.nudeDark,
    disabledColor: colors.black,
    disabledOpacity: '0.5',
    hoverBackgroundColor: colors.nudeDark,
    hoverBorderColor: `1px solid ${colors.white}`,
    hoverColor: colors.white,
    hoverDisabledBackgroundColor: 'transparent',
    loadingBackgroundColor: colors.bone,
    loadingStroke: colors.black,
    name: 'outlined - bone - theme',
  },
  contained: {
    backgroundColor: colors.nudeDark,
    borderColor: `1px solid ${colors.nudeDark}`,
    color: colors.white,
    disableBorder: `1px solid ${colors.gray200}`,
    disabledBackgroundColor: colors.gray200,
    disabledColor: colors.white,
    disabledOpacity: '1',
    hoverBackgroundColor: colors.nudeDark2,
    hoverBorderColor: `1px solid ${colors.nudeDark2}`,
    hoverColor: colors.white,
    hoverDisabledBackgroundColor: colors.nudeDark2,
    loadingBackgroundColor: colors.nudeDark,
    loadingStroke: colors.white,
    name: 'contained - bone - theme',
  },
};

export { lightTheme, darkTheme, boneTheme };
