import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

interface OpenUrlIconProps {
  size?: number;
  fill?: string;
}

const Svg = styled.svg`
  width: ${(props) => (props.width ? props.width : '16px')};
`;

const OpenUrlIcon: React.FunctionComponent<OpenUrlIconProps> = (props) => {
  const size = props.size ? props.size : 16;

  return (
    <Svg
      id={uuid()}
      data-name="Open Url Icon"
      viewBox="0 0 16 16"
      width={`${size}px`}
      {...props}
    >
      <path d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z" />
      <path
        d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z"
        fillOpacity="0.5"
      />
    </Svg>
  );
};

export { OpenUrlIcon };
