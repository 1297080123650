import { OktaAuth } from '@okta/okta-auth-js';
import {
  oktaConfigs,
  userGroups,
} from '@packages/deprecated-shared-sdk/src/application.config';
import { getEnvConfig } from '@packages/deprecated-shared-sdk/src/getEnvConfig';
import {
  generateOktaHelpers,
  validateNotRole,
} from '@packages/deprecated-shared-sdk/src/oktaHelpers';
import { customOktaSettings, oktaTokenIds } from '../application.config';

const oktaAuth = new OktaAuth({
  ...getEnvConfig(oktaConfigs, process.env.REACT_APP_OKTA_ENV),
  ...customOktaSettings,
});

const {
  signOut,
  closeSession,
  getAccessToken,
  validateToken,
  validateTokenSync,
  subscribeToErrorEvents,
  subscribeToLogoutEvents,
  unsubscribeToErrorEvents,
  unsubscribeToLogoutEvents,
  getUniqueId,
} = generateOktaHelpers(oktaAuth, {
  additionalValidation: [
    validateNotRole(userGroups.practice),
    validateNotRole(userGroups.admin),
  ],
  accessTokenId: oktaTokenIds.accessToken,
  idTokenId: oktaTokenIds.idToken,
});

const addOktaTokens = (idToken: any, accessToken: any) => {
  return Promise.all([
    oktaAuth.tokenManager.add(oktaTokenIds.idToken, idToken),
    oktaAuth.tokenManager.add(oktaTokenIds.accessToken, accessToken),
  ]);
};

// errorCodes: https://developer.okta.com/docs/reference/error-codes/
// fromOkta: Note: Only the errorCode property is supported for runtime error flow control. The errorSummary property is only intended for troubleshooting and may change over time.
export type OktaError = {
  errorCode: string;
  errorSummary: string;
  errorLink: string;
  errorId: string;
  errorCauses: [];
};

export {
  signOut,
  closeSession,
  getAccessToken,
  validateToken,
  validateTokenSync,
  subscribeToErrorEvents,
  subscribeToLogoutEvents,
  unsubscribeToErrorEvents,
  unsubscribeToLogoutEvents,
  getUniqueId,
  oktaAuth,
  addOktaTokens,
};
