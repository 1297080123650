/**
 * Given URI, creates an element Id (# + anything after '=' in uri) and scroll to the element on a page (if it exists)
 */
export const scrollIntoView = (uri: string) => {
  const elementId = '#' + uri.split('=')[1];

  if (elementId) {
    const el = document.getElementById(elementId as string);
    el?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};
