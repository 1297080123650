import { Dimensions } from 'react-native';

// How to find Device metrics https://material.io/devices/

// we use the size spec from: https://developer.android.com/guide/practices/screens_support.html across platform
// xlarge screens are at least 960dp x 720dp 'l'
// large screens are at least 640dp x 480dp - medium 'm'
// normal screens are at least 470dp x 320dp - small 's'

type DeviceSize = 's' | 'm' | 'l';

const deviceSizeIsAvailable = (size: DeviceSize) => {
  const { height } = Dimensions.get('window');
  switch (size) {
    case 'm':
      return height >= 640;
    case 'l':
      return height >= 960;
    default:
      return true;
  }
};

export { deviceSizeIsAvailable };
