import React from 'react';

import typewriterConsumer from '@packages/core-segment-tracking/src/generated';

import {
  ExternalIds,
  IdentifyTraitsOptions,
  SegmentContextValue,
  SegmentTraits,
} from './segmentTypes';
import { identifyTraits as segmentIdentifyTraits } from './identifyTraits';

import {
  segmentPublicKey,
  __CMS_PREVIEW__ as isCmsPreviewEnv,
} from './package.config';
import { useSyncSegmentIdentity } from './useSyncSegmentIdentity';

// middlewares
import { fullStoryMiddleware } from './fullStoryMiddleware';
import { alleDebuggingMiddleware } from './alleDebuggingMiddleware';
import { formatPhoneNumberMiddleware } from './formatPhoneNumberMiddleware';
import { utmValueMiddleware } from './utmValuesMiddleware';
import { fbcFbpValueMiddleware } from './fbcFbpValueMiddleware';
import { oneTrustGeoMiddleware } from './oneTrustGeoMiddleware';
import { ttclidValueMiddleware } from './ttclidValueMiddleware';
import { addScreenSizesMiddleware } from './addScreenSizesMiddleware';

const SegmentContext = React.createContext<SegmentContextValue>({
  page: () => {},
  trackConsumer: () => undefined,
  identifyTraits: () => undefined,
  syncSegmentIdentity: () => undefined,
});

type SegmentProviderProps = {
  children: React.ReactNode;
};

const SegmentProvider = ({ children }: SegmentProviderProps) => {
  React.useEffect(() => {
    try {
      if (!segmentPublicKey) {
        return;
      }

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(addScreenSizesMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(formatPhoneNumberMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(utmValueMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(alleDebuggingMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(fbcFbpValueMiddleware);

      // @ts-ignore
      window?.analytics?.addDestinationMiddleware?.('FullStory', [
        fullStoryMiddleware,
      ]);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(oneTrustGeoMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(ttclidValueMiddleware);

      if (!isCmsPreviewEnv) {
        // @ts-ignore
        window?.withOneTrust(window?.analytics)?.load(segmentPublicKey);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const page = React.useCallback((name?: string, properties?: Object) => {
    try {
      window?.analytics?.page?.(name, properties);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const trackConsumer = React.useCallback(() => typewriterConsumer, []);

  const identifyTraits = React.useCallback(
    (
      traits: SegmentTraits,
      externalIds?: ExternalIds,
      options?: IdentifyTraitsOptions
    ) => segmentIdentifyTraits(traits, externalIds, options),
    []
  );
  const syncSegmentIdentity = useSyncSegmentIdentity();

  return (
    <SegmentContext.Provider
      value={{
        page,
        trackConsumer,
        identifyTraits,
        syncSegmentIdentity,
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
};

const useSegment = () => React.useContext(SegmentContext);

export { SegmentProvider, useSegment };
