import React from 'react';
import styled from 'styled-components';
import { BLOCKS, NodeData, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { ILink } from '@packages/core-contentful-rest';
import { scrollIntoView } from '@packages/core-components/src/utils/scrollIntoView';
import {
  Responsify,
  Typography,
  TypographyPreset,
} from '@packages/deprecated-universal-component-library/src/atoms/typography';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';
import { Link } from '../../core/link';
import {
  getEntryHyperlink,
  getListStyleTypeByLevel,
  ListContext,
  useListContext,
} from './defaultStyleHelpers';
import { Paragraph } from './paragraph';
import { CustomizeDefaultOptions, HeaderPresets, LinkEntry } from './types';

export const Text = styled(Typography)`
  color: inherit;
  white-space: pre-wrap;
`;

const Heading3 = styled(Text)`
  line-height: 26px; // +8px compared to font size, keeping in line with other header style patterns
  // reset to match headline-S-petersburg
  ${above.desktop`
      line-height: 28px; 
    `}
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
`;

export const StyledButton = styled.span`
  color: inherit;
  text-decoration: underline;
  font-size: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

const UnorderedList = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: left;
  padding-inline-start: 20px;
  p {
    display: list-item;
  }
`;

const OrderedList = styled.ol`
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: left;
  padding-inline-start: 20px;
  p {
    display: list-item;
  }
  list-style-type: ${({ listStyleType }: { listStyleType: string }) =>
    listStyleType};
`;

export const defaultParagraphPreset: Responsify<TypographyPreset> = {
  s: 'body-M-regular',
  m: 'body-M-regular',
  l: 'body-L-regular',
};

export const defaultHeaderPresets: HeaderPresets = {
  [BLOCKS.HEADING_1]: {
    s: 'headline-M-petersburg',
    m: 'headline-M-petersburg',
    l: 'headline-L-petersburg',
  },
  [BLOCKS.HEADING_2]: {
    s: 'headline-S-petersburg',
    m: 'headline-S-petersburg',
    l: 'headline-M-petersburg',
  },
  [BLOCKS.HEADING_3]: {
    s: 'body-XL-regular',
    m: 'body-XL-regular',
    l: 'headline-S-petersburg',
  },
};
/**
 * @deprecated use defaultRichTextRenderOptions in @packages/deprecated-consumer-component-library/src/chakra-components/richText/defaultRichTextRenderOptions.tsx which uses the new design system styles
 **/
export const defaultRichTextRenderOptions = ({
  paragraphPreset = defaultParagraphPreset,
  headerPresets = defaultHeaderPresets,
  customRenderNodeOptions,
  customRenderTextOption,
  links,
  onEntryHyperlinkClick,
}: CustomizeDefaultOptions): Options => {
  return {
    renderMark: {
      [MARKS.SUPERSCRIPT]: (text: React.ReactNode) => <sup>{text}</sup>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_: NodeData, children: React.ReactNode) => (
        <Text preset={headerPresets[BLOCKS.HEADING_1]} component="h1">
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_2]: (_: NodeData, children: React.ReactNode) => (
        <Text preset={headerPresets[BLOCKS.HEADING_2]} component="h2">
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_3]: (_: NodeData, children: React.ReactNode) => (
        <Heading3 preset={headerPresets[BLOCKS.HEADING_3]} component="h3">
          {children}
        </Heading3>
      ),
      [BLOCKS.PARAGRAPH]: (_: NodeData, children: React.ReactNode) => (
        <Paragraph paragraphPreset={paragraphPreset}>{children}</Paragraph>
      ),
      [INLINES.HYPERLINK]: (node: NodeData, children: React.ReactNode) => {
        if (!node?.data?.uri) return;
        if (node?.data?.uri.includes('scrollAnchor=')) {
          // This link text indicates link should scroll to some element on current page
          return (
            <StyledButton
              as="button"
              onClick={() => {
                scrollIntoView(node?.data?.uri);
              }}
            >
              {children}
            </StyledButton>
          );
        }
        return (
          <StyledLink
            external={
              !node.data.uri.startsWith('/') && !node.data.uri.startsWith('#')
            }
            to={node.data.uri}
          >
            {children}
          </StyledLink>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (
        node: NodeData,
        children: React.ReactNode
      ) => {
        const entryLink: LinkEntry =
          (node?.data?.target as ILink)?.fields ||
          getEntryHyperlink(node.data.target, links);
        if (!entryLink?.url) return;
        if (entryLink?.url?.includes('scrollAnchor=')) {
          // This link text indicates link should scroll to some element on current page
          return (
            <StyledButton
              as="button"
              onClick={() => {
                if (entryLink.url) {
                  scrollIntoView(entryLink.url);
                }
              }}
            >
              {children}
            </StyledButton>
          );
        }
        return (
          <StyledLink
            external={
              !entryLink.url.startsWith('/') && !entryLink.url.startsWith('#')
            }
            forceSameTab={entryLink.forceOpenSameTab}
            to={entryLink.url}
            onClick={
              onEntryHyperlinkClick
                ? (event) => {
                    event.preventDefault();
                    onEntryHyperlinkClick({
                      event,
                      link: entryLink,
                      linkText: node.content[0].value,
                    });
                  }
                : undefined
            }
          >
            {children}
          </StyledLink>
        );
      },
      [BLOCKS.UL_LIST]: (_: NodeData, children: React.ReactNode) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (_: NodeData, children: React.ReactNode) => {
        const List: React.FunctionComponent = () => {
          const { listLevel } = useListContext();
          const newListLevel = listLevel + 1;
          return (
            <ListContext.Provider value={{ listLevel: newListLevel }}>
              <OrderedList
                listStyleType={getListStyleTypeByLevel(newListLevel)}
              >
                {children}
              </OrderedList>
            </ListContext.Provider>
          );
        };

        return <List />;
      },
      [BLOCKS.LIST_ITEM]: (_: NodeData, children: React.ReactNode) => (
        <li style={{ display: 'contents' }}>{children}</li>
      ),
      ...customRenderNodeOptions,
    },
    renderText: customRenderTextOption,
  };
};
