const nude = {
  100: '#F9F3F1',
  200: '#EFE1DD',
  300: '#DFC2BB',
  400: '#D0A498',
  500: '#C08676',
  600: '#906459',
  700: '#60433B',
  800: '#30211E',
  900: '#130D0C',
};

export { nude };
