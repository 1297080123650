function alleDebuggingMiddleware({ payload, next }: any) {
  if (process.env.REACT_APP_SEGMENT_DEBUGGER === 'enabled') {
    const type = payload.obj.type;

    console.log(
      `%cType: ${type}`,
      'color:red;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold'
    );

    // eslint-disable-next-line no-empty
    if (type === 'page') {
      console.table({
        anonymousId: payload.obj.anonymousId,
        userId: payload.obj.userId,
      });
    }

    if (type === 'track') {
      console.log(
        `%cEvent: ${payload.obj.event}`,
        'color:red;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold'
      );
      console.table({
        anonymousId: payload.obj.anonymousId,
        userId: payload.obj.userId,
      });
    }

    if (type === 'identify') {
      console.group('identify');
      console.log('IDs');
      console.table({
        anonymousId: payload.obj.anonymousId,
        userId: payload.obj.userId,
      });
      console.log('ExternalIds');
      console.table(payload.obj.context.externalIds);
      console.log('Traits');
      console.table(payload.obj.traits);
      console.groupEnd();
    }
  }

  next(payload);
}

export { alleDebuggingMiddleware };
