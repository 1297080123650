import { Platform } from 'react-native';
import {
  TypographyTheme,
  TypographyBaseVariant,
  TypographyBaseSize,
  TypographyBaseWeight,
  TypographyBaseFontFamily,
} from './types';

export const DEFAULT_TYPOGRAPHY_VARIANT: TypographyBaseVariant = 'body';
export const DEFAULT_TYPOGRAPHY_SIZE: TypographyBaseSize = 'M';
export const DEFAULT_TYPOGRAPHY_WEIGHT: TypographyBaseWeight = 'regular';
export const DEFAULT_TYPOGRAPHY_FONT_FAMILY: TypographyBaseFontFamily =
  'graphik';

export const defaultTypographyTheme: TypographyTheme = {
  fontConfigs: {
    headline: {
      XS: undefined,
      S: {
        fontSize: 20,
        lineHeight: 28,
        letterSpacing: -0.4,
      },
      M: {
        fontSize: 24,
        lineHeight: 32,
        letterSpacing: -0.8,
      },
      L: {
        fontSize: 36,
        lineHeight: 48,
        letterSpacing: -1,
      },
      XL: {
        fontSize: 48,
        lineHeight: 48,
        letterSpacing: -1,
      },
    },
    body: {
      XS: undefined,
      S: {
        fontSize: 12,
        lineHeight: 16,
      },
      M: {
        fontSize: 14,
        lineHeight: 20,
      },
      L: {
        fontSize: 16,
        lineHeight: 24,
      },
      XL: {
        fontSize: 18,
        lineHeight: 32,
      },
    },
    caption: {
      XS: undefined,
      S: {
        fontSize: 8,
        lineHeight: 11,
        letterSpacing: 1,
        textTransform: 'uppercase',
      },
      M: {
        fontSize: 10,
        lineHeight: 14,
        letterSpacing: 1,
        textTransform: 'uppercase',
      },
      L: {
        fontSize: 12,
        lineHeight: 14,
        letterSpacing: 1,
        textTransform: 'uppercase',
      },
      XL: undefined,
    },
  },
  fontWeights: {
    light: undefined,
    regular: 'normal',
    semibold: undefined,
    bold: 'bold',
  },
  fontFamilys: {
    graphik: Platform.OS === 'web' ? "'Graphik', sans-serif" : 'graphik',
    petersburg: Platform.OS === 'web' ? "'petersburg', serif" : 'petersburg',
  },
};
