import React from 'react';
import queryString, { ParsedQuery } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';
import { Routes } from '../../constants/routes';

import { signOut } from '../../auth/oktaClient';

const LogoutView: React.FC = () => {
  const history = useHistory();
  const { authState, sendAuthEvent } = useAuth();

  // help us avoid triggering the effect twice
  const complete = React.useRef(false);

  const location = useLocation();

  const parsedQuery: ParsedQuery = queryString.parse(location.search);
  const redirectURIEncoded: string | null | undefined = Array.isArray(
    parsedQuery.fromURI
  )
    ? parsedQuery.fromURI[0]
    : parsedQuery.fromURI;

  React.useEffect(() => {
    if (complete.current) {
      return;
    }

    if (authState.matches(AuthState.isLoggedIn)) {
      complete.current = true;

      if (redirectURIEncoded) {
        sendAuthEvent('LOGOUT_CUSTOM_REDIRECT', {
          redirectURI: redirectURIEncoded,
        });
        return;
      }

      sendAuthEvent('LOGOUT');
    } else if (authState.matches(AuthState.isNotLoggedIn)) {
      console.error('logoutView#notLoggedIn');
      complete.current = true;
      // Still sign out, we might not have local storage tokens
      // but we could still have cookies from okta server
      signOut().then(() => {
        if (redirectURIEncoded) {
          window.location.href = decodeURIComponent(redirectURIEncoded);
        } else {
          history.replace(Routes.login);
        }
      });
    }
  }, [authState, history, sendAuthEvent, redirectURIEncoded]);

  return null;
};

export { LogoutView };
