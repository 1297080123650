import React, { forwardRef } from 'react';
import { Link as ReactRouterLinkImplementation } from 'react-router-dom';

// eslint-disable-next-line no-restricted-imports
import { Link } from '@allergan-data-labs/alle-elements-link';
import { LinkProps } from '../types';

/**
 *  Link element use Alle Elements Link component, as React Router Link component for relative links
 */
const ReactRouterLink = forwardRef<typeof Link, LinkProps>((props, ref) => {
  const { href, children, referrerData, ...forwardProps } = props;

  const isRelativeLink = href.startsWith('/');
  if (isRelativeLink) {
    return (
      <Link
        data-type="react-router-link"
        as={ReactRouterLinkImplementation}
        ref={ref}
        to={referrerData ? { pathname: href, state: referrerData } : href}
        {...forwardProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      data-type="hyperlink"
      ref={ref}
      href={href}
      rel="noopener noreferrer"
      {...forwardProps}
    >
      {children}
    </Link>
  );
});

export { ReactRouterLink };
