import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CompatRouter, CompatRoute } from 'react-router-dom-v5-compat';
import { SplitContext, SplitFactory } from '@splitsoftware/splitio-react';

import { getSplitKey } from '@packages/core-split-feature-flag/src/getSplitKey/getSplitKey';
import { NotificationBar } from '@packages/deprecated-component-library/src/notificationBar/notificationBar';
import { ErrorBoundary } from '@packages/deprecated-component-library/src/errorBoundary/errorBoundary';
import { LoadingScreen } from '@packages/deprecated-component-library/src/components/loadingScreen';
import { AlleDownPage } from '@packages/deprecated-component-library/src/components/errorPages/alleDownPage';
import { ReactLazyPreload } from '@packages/deprecated-component-library/src/core/preload';

import { DefaultFooter } from './components/footers/defaultFooter';
import { HeaderDynamic } from './components/headers/headerDynamic';
import { SecureRoute } from './auth/secureRoute';
import { routeConfig, RouteConfig } from './routeConfig';
import { Providers } from './providers';
import { App } from './app';
import {
  isAlleDown,
  SPLIT_DEBUGGER_ENABLED,
  splitAuthorizationKey,
} from './application.config';

const ErrorPage404 = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ErrorPage404' */ './views/errorPage404/errorPage404'
  ).then((mod) => ({
    default: mod.ErrorPage404,
  }))
);

const secureRouter = (route: RouteConfig, component: any) => {
  if (route.secure) {
    return (
      <SecureRoute
        key="no-key"
        title={route.title}
        path={route.path}
        exact={route.exact}
        component={component}
      />
    );
  } else if (route.ssr) {
    const Component = component as any;
    return (
      <CompatRoute
        key="no-key"
        path={route.path}
        exact={route.exact}
        component={() => (
          <>
            <Helmet title={route.title ? route.title : 'Allē'} />
            <Component />
          </>
        )}
      />
    );
  } else {
    return (
      <Route
        key="no-key"
        path={route.path}
        exact={route.exact}
        render={(props) => {
          const Component = component as any;
          return (
            <>
              <Helmet title={route.title ? route.title : 'Allē'} />
              <Component {...props} />
            </>
          );
        }}
      />
    );
  }
};
const SplitLoader = ({ children }: { children: any }) => {
  const { isReady, client } = React.useContext(SplitContext);
  if (isReady) {
    if (SPLIT_DEBUGGER_ENABLED) {
      console.log(
        `%cSplit ready, context splitKey: ${
          //@ts-ignore
          client?.key
        }`,
        'color:red;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold'
      );
    }

    return children;
  }
  return <LoadingScreen />;
};
const AppRouter = () => {
  if (isAlleDown) {
    return <AlleDownPage />;
  }

  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey: splitAuthorizationKey,
          key: getSplitKey(SPLIT_DEBUGGER_ENABLED),
        },
      }}
      updateOnSdkUpdate={true}
      updateOnSdkTimedout={true}
    >
      <SplitLoader>
        <Router basename={process.env.PUBLIC_URL}>
          <CompatRouter>
            <ErrorBoundary>
              <React.Suspense fallback={<LoadingScreen />}>
                <Providers>
                  <App>
                    <div
                      css={`
                        position: relative;
                      `}
                    >
                      <Switch>
                        {routeConfig.map((route: RouteConfig) =>
                          route.header
                            ? secureRouter(route, route.header)
                            : null
                        )}
                        {/* Default to no header */}
                        <Route render={() => null} />
                      </Switch>
                      <NotificationBar responsive zIndex={1101} />
                      <Switch>
                        {routeConfig.map((route: RouteConfig) =>
                          secureRouter(route, route.component)
                        )}
                        {/* Default to 404 page */}
                        <Route
                          render={(props) => (
                            <>
                              <HeaderDynamic
                                keepPathOnLogin
                                compact
                                {...props}
                              />
                              <ErrorPage404 />
                              <DefaultFooter />
                            </>
                          )}
                        />
                      </Switch>
                      <Switch>
                        {routeConfig.map((route: RouteConfig) =>
                          route.hideFooter
                            ? null
                            : secureRouter(route, DefaultFooter)
                        )}
                        {/* Default to no footer */}
                        <Route component={() => null} />
                      </Switch>
                    </div>
                  </App>
                </Providers>
              </React.Suspense>
            </ErrorBoundary>
          </CompatRouter>
        </Router>
      </SplitLoader>
    </SplitFactory>
  );
};

export { AppRouter };
