import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { TextButton } from '@allergan-data-labs/alle-elements-button';
import { getColorToken } from '@allergan-data-labs/alle-elements-core';
import { CloseIcon } from '@allergan-data-labs/alle-elements-icons/20/closeIcon';
import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';
import { screenSizes } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';
import { useSessionStorage } from '@packages/deprecated-component-library/src/hooks/useSessionStorage';
import {
  DefaultOptedInDesktopBanner,
  DefaultOptedInMobileBanner,
  DefaultOptedInContainer,
} from './defaultOptedInBanner';
import {
  DefaultOptedOutContainer,
  TextContainer,
  TextAndButtonsContainer,
  DefaultOptedOutSnippet,
  DefaultOptedOutFullBanner,
  DefaultOptedOutButtons,
} from './defaultOptedOutBanner';
import { getSnippetText } from './shared';

const defaultOptedOutActivator = 'Cookie Consent';
const defaultOptedInActivator = 'Cookie Banner';

interface CookieNotificationBannerProps {
  name: string;
  closedNoSelectionCookieName: string;
}

const CookieNotificationBanner: React.FunctionComponent<
  CookieNotificationBannerProps
> = ({ name, closedNoSelectionCookieName }): any => {
  const isPrerenderAgent = navigator.userAgent
    .toLowerCase()
    .includes('prerender');

  const [cookies] = useCookies([name]);
  const [closedNoSelection, setClosedNoSelection] = useSessionStorage<boolean>(
    closedNoSelectionCookieName,
    false
  );
  const [showCookie, setShowCookie] = useState(
    !cookies[name] && !closedNoSelection
  );
  const [showFullBanner, setShowFullBanner] = useState(false);
  const { trackConsumer } = useSegment();
  const isDesktopOrAbove = useMediaQuery(
    `(min-width:${screenSizes.desktop}px)`
  );
  const isTabletOrAbove = useMediaQuery(`(min-width:${screenSizes.tablet}px)`);
  const oneTrustGeoLocation = (window as any)?.OneTrust?.getGeolocationData?.();
  const isDefaultOptedOut =
    oneTrustGeoLocation?.country?.toLowerCase() === 'us' &&
    oneTrustGeoLocation?.state?.toLowerCase() === 'wa';
  const snippetText = getSnippetText({
    isDefaultOptedOut,
    showLongerSnippet: isTabletOrAbove,
  });

  useEffect(() => {
    const shouldHideCookieBanner = cookies[name];

    if (showCookie && shouldHideCookieBanner) {
      setShowCookie(!showCookie);
    }
  }, [cookies, name, showCookie]);

  const trackPrivacyPolicyClick = () => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Link',
        explainer: 'Tracking if user interacts with OneTrust Banner',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      navigation_type: 'browse',
      site_type: 'consumer web',
      text: 'Privacy Notice',
      url: 'https://privacy.abbvie/',
    });
  };

  const handleCookieSettingsClick = () => {
    (window as any)?.OneTrust?.ToggleInfoDisplay();
    setShowCookie(false);
    trackConsumer()?.siteNavigated({
      event_source_url: `${window.location.href}`,
      navigation_type: 'cookie banner',
      event: {
        action_source: 'consumer',
        activator: isDefaultOptedOut
          ? defaultOptedOutActivator
          : defaultOptedInActivator,
        explainer: 'Tracking user interaction with cookie banner',
        invoked_by: 'consumer',
        event_type: 'privacy',
        user_type: 'consumer',
        user_status: 'logged_out',
      },
    });
  };

  const handleRejectAllClick = () => {
    (window as any)?.OneTrust?.RejectAll();
    setShowCookie(false);
    trackConsumer()?.consentDeclined({
      consent_id: 'na',
      consent_type: ['cookies'],
      event: {
        action_source: 'consumer',
        activator: defaultOptedOutActivator,
        explainer:
          'Tracking users that decline the privacy settings in the cookie consent banner ',
        invoked_by: 'consumer',
        event_type: 'privacy',
        user_type: 'consumer',
        user_status: 'logged out',
      },
    });
  };

  const handleAcceptAllClick = () => {
    (window as any)?.OneTrust?.AllowAll();
    setShowCookie(false);
    trackConsumer()?.consentApproved({
      consent_id: 'na',
      consent_type: ['cookies'],
      event: {
        action_source: 'consumer',
        activator: defaultOptedOutActivator,
        explainer:
          'Tracking users that accept the privacy settings in the cookie consent banner ',
        invoked_by: 'consumer',
        event_type: 'privacy',
        user_type: 'consumer',
        user_status: 'logged out',
      },
    });
  };

  const handleCloseBannerClick = () => {
    setShowCookie(false);
    // for defaultOptedOut banner we don't consider closing banner a selection (will show again next session)
    isDefaultOptedOut
      ? setClosedNoSelection(true)
      : (window as any)?.OneTrust?.Close();
    isDefaultOptedOut
      ? trackConsumer()?.consentDeclined({
          consent_id: 'na',
          consent_type: ['cookies'],
          event: {
            action_source: 'consumer',
            activator: defaultOptedOutActivator,
            explainer:
              'Tracking users that close the privacy banner without making a consent selection',
            invoked_by: 'consumer',
            event_type: 'privacy',
            user_type: 'consumer',
            user_status: 'logged out',
          },
        })
      : trackConsumer()?.consentApproved({
          consent_id: 'na',
          consent_type: ['cookies'],
          event: {
            action_source: 'consumer',
            activator: defaultOptedInActivator,
            explainer: 'Tracking users interaction with cookie banner',
            invoked_by: 'consumer',
            event_type: 'privacy',
            user_type: 'consumer',
            user_status: 'logged out',
          },
        });
  };

  const toggleFullBanner = () => {
    setShowFullBanner(!showFullBanner);
  };

  if (isDefaultOptedOut && showCookie && !isPrerenderAgent) {
    return (
      <DefaultOptedOutContainer
        data-testid="cookie-notification-banner-container"
        role="region"
        aria-label="Cookie Banner"
        $isDefaultOptedOut={isDefaultOptedOut}
      >
        <TextButton
          data-testid={`banner-close-button`}
          onClick={handleCloseBannerClick}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            paddingTop: '12px',
            paddingRight: '16px',
          }}
        >
          <CloseIcon
            sx={{ color: getColorToken('Section bg/Neutral/Inverted', 'dark') }}
          />
        </TextButton>
        <TextAndButtonsContainer>
          <TextContainer>
            {showFullBanner ? (
              <DefaultOptedOutFullBanner
                onShowLessClick={toggleFullBanner}
                onPrivacyPolicyClick={trackPrivacyPolicyClick}
              />
            ) : (
              <DefaultOptedOutSnippet
                onShowMoreClick={toggleFullBanner}
                text={snippetText}
              />
            )}
          </TextContainer>
          {isDefaultOptedOut ? (
            <DefaultOptedOutButtons
              onCookieSettingsClick={handleCookieSettingsClick}
              onRejectAllClick={handleRejectAllClick}
              onAcceptAllClick={handleAcceptAllClick}
            />
          ) : null}
        </TextAndButtonsContainer>
      </DefaultOptedOutContainer>
    );
  }

  if (showCookie && !isPrerenderAgent) {
    return (
      <DefaultOptedInContainer
        data-testid="cookie-notification-banner-container"
        role="region"
        aria-label="Cookie Banner"
        $isDefaultOptedOut={isDefaultOptedOut}
      >
        {isDesktopOrAbove ? (
          <DefaultOptedInDesktopBanner
            showFullBanner={showFullBanner}
            toggleFullBanner={toggleFullBanner}
            onCookieSettingsClick={handleCookieSettingsClick}
            onCloseBannerClick={handleCloseBannerClick}
            onPrivacyPolicyClick={trackPrivacyPolicyClick}
            snippetText={snippetText}
          />
        ) : (
          <DefaultOptedInMobileBanner
            showFullBanner={showFullBanner}
            toggleFullBanner={toggleFullBanner}
            onCookieSettingsClick={handleCookieSettingsClick}
            onCloseBannerClick={handleCloseBannerClick}
            onPrivacyPolicyClick={trackPrivacyPolicyClick}
            snippetText={snippetText}
          />
        )}
      </DefaultOptedInContainer>
    );
  }

  return null;
};

export { CookieNotificationBanner };
