import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const Menu: React.FunctionComponent = () => {
  return (
    <IconContainer>
      <SvgIcon width="22" height="22" viewBox="0 0 22 22">
        <path d="M3,7 C2.447754,7 2,7.447754 2,8 C2,8.55225 2.447754,9 3,9 L19,9 C19.5522,9 20,8.55225 20,8 C20,7.447754 19.5522,7 19,7 L3,7 Z M3,13 C2.447754,13 2,13.44775 2,14 C2,14.55225 2.447754,15 3,15 L19,15 C19.5522,15 20,14.55225 20,14 C20,13.44775 19.5522,13 19,13 L3,13 Z"></path>
      </SvgIcon>
    </IconContainer>
  );
};

export { Menu };
