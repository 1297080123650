import React from 'react';
import { GqlRichTextLinks, LinkEntry } from './types';

export const ListContext = React.createContext<{ listLevel: number }>({
  listLevel: 0,
});

export const getOrderedListStyleTypeByLevel = (listLevel: number): string => {
  switch (listLevel % 3) {
    case 1:
      return 'decimal';
    case 2:
      return 'lower-alpha';
    default:
      return 'lower-roman';
  }
};

export const getUnorderedListStyleTypeByLevel = (listLevel: number): string => {
  switch (listLevel) {
    case 1:
      return 'disc';
    case 2:
      return 'circle';
    default:
      return 'square';
  }
};

// get link from gql entry links
export const getGqlEntryHyperlink = (
  gqlEntry: { sys: { id: string; linkType?: 'Entry'; type: 'Link' } },
  links?: GqlRichTextLinks
): LinkEntry | undefined | null => {
  if (
    !links &&
    gqlEntry.sys.linkType === 'Entry' &&
    gqlEntry.sys.type === 'Link'
  ) {
    console.error(
      "You're trying to render a rich text entry link from a gql response and there are no gql entry links defined, did you forget to pass in the 'gqlLinks' prop to the rich text component?"
    );
  }
  return links?.find((link) => link?.sys.id === gqlEntry.sys.id);
};
