import {
  ExternalIds,
  SegmentTraits,
  IdentifyTraitsOptions,
} from './segmentTypes';

function convertToISODate(alleFormattedDate: string): string {
  const [month, day, year] = alleFormattedDate.split('.');
  return `${year}-${month}-${day}`;
}

const identifyTraits = (
  traits: SegmentTraits,
  externalIds?: ExternalIds,
  options?: IdentifyTraitsOptions
) => {
  if (window.analytics && window.analytics.identify) {
    const skipPhoneEmail = options?.skipAllePhoneAndEmailNormalization;
    const newTraits = {
      ...traits,
      ...(traits.alle_first_name ? { first_name: traits.alle_first_name } : {}),
      ...(traits.alle_last_name ? { last_name: traits.alle_last_name } : {}),
      ...(traits.alle_birthdate
        ? { alle_birthdate: convertToISODate(traits.alle_birthdate) }
        : {}),
      ...(traits.alle_phone && !skipPhoneEmail
        ? { phone: traits.alle_phone }
        : {}),
      ...(traits.alle_email && !skipPhoneEmail
        ? { email: traits.alle_email }
        : {}),
    };

    if (!externalIds) {
      window.analytics.identify(newTraits);
      return;
    }

    const ids = [
      ...(externalIds.alleId
        ? [
            {
              id: externalIds.alleId,
              type: 'alle_id',
              collection: 'users',
              encoding: 'none',
            },
          ]
        : []),
      ...(externalIds.phone
        ? [
            {
              id: externalIds.phone,
              type: 'phone',
              collection: 'users',
              encoding: 'none',
            },
          ]
        : []),
      ...(externalIds.bdConsumerId
        ? [
            {
              id: externalIds.bdConsumerId,
              type: 'bd_consumer_id',
              collection: 'users',
              encoding: 'none',
            },
          ]
        : []),
    ];

    const identifyArgs = [
      ...(externalIds.alleId ? [externalIds.alleId] : []),
      newTraits,
      {
        externalIds: ids,
      },
    ];

    window.analytics.identify(...identifyArgs);
  }
};

export { identifyTraits, convertToISODate };
