const grey = {
  100: '#E6E6E6',
  200: '#CCCCCC',
  300: '#B3B3B3',
  400: '#9A9A9A',
  500: '#808080',
  600: '#676767',
  700: '#4E4E4E',
  800: '#353535',
  900: '#1B1B1B',
  1000: '#111111',
};

export { grey, grey as gray };
