interface ValidationArgs {
  accessToken: any;
  idToken: any;
}
type ValidationStep = (tokens: ValidationArgs) => boolean;

const hasRole = (idToken: any, role: string) => {
  return (
    idToken &&
    idToken.claims &&
    idToken.claims.groups &&
    idToken.claims.groups.includes(role)
  );
};

const validateRole =
  (role: string): ValidationStep =>
  ({ idToken }: ValidationArgs) =>
    hasRole(idToken, role);

const validateNotRole =
  (role: string): ValidationStep =>
  ({ idToken }: ValidationArgs) =>
    !hasRole(idToken, role);

interface GenerateOktaHelpersConfig {
  additionalValidation?: ValidationStep[];
  accessTokenId: string;
  idTokenId: string;
}

export interface SignOutOptions {
  postLogoutRedirectUri?: string;
}

const generateOktaHelpers = (
  authClient: any,
  {
    additionalValidation,
    accessTokenId,
    idTokenId,
  }: GenerateOktaHelpersConfig = {
    accessTokenId: 'accessToken',
    idTokenId: 'idToken',
  }
) => {
  let uniqueId = '';
  let logoutHandler: () => void;

  const clearAll = () => {
    uniqueId = '';
    authClient.tokenManager.off('error');
    localStorage.removeItem('okta-cache-storage');
    return authClient.tokenManager.clear();
  };

  const validateTokenSync = ({
    accessToken,
    idToken,
  }: {
    accessToken: any;
    idToken: any;
  }) => {
    const isValid =
      !additionalValidation ||
      additionalValidation.every((validationStep) =>
        validationStep({ accessToken, idToken })
      );

    if (isValid && accessToken && Date.now() < accessToken.expiresAt * 1000) {
      uniqueId = idToken.claims.sub;
      return;
    }
    throw new Error('invalid id or access token');
  };

  const getAccessToken = async () => {
    return (await authClient.tokenManager.get(accessTokenId))?.accessToken;
  };

  const getAccessTokenHandler = async (event: StorageEvent) => {
    if (
      event.key === 'okta-token-storage' &&
      (!event.newValue || event.newValue === '{}')
    ) {
      logoutHandler();
    }
  };

  return {
    signOut: (options?: SignOutOptions): Promise<any> => {
      return authClient.signOut(options).then(clearAll).catch(clearAll);
    },
    closeSession: (): Promise<any> => {
      return authClient.closeSession().then(clearAll).catch(clearAll);
    },
    getAccessToken,
    validateToken: async () => {
      const [accessToken, idToken] = await Promise.all([
        authClient.tokenManager.get(accessTokenId),
        authClient.tokenManager.get(idTokenId),
      ]);
      validateTokenSync({ accessToken, idToken });
    },
    validateTokenSync,
    subscribeToErrorEvents: (onLoginRequired: () => void) => {
      // Log the user out if the okta session has expired
      // Use https://alle-{environment}.okta.com/api/v1/sessions/me to debug the session
      authClient.tokenManager.on('error', (error: any) => {
        if (error.errorCode === 'login_required') {
          onLoginRequired();
          return;
        }
      });
    },
    subscribeToLogoutEvents: async (onLogout: () => void) => {
      logoutHandler = onLogout;
      window.addEventListener('storage', getAccessTokenHandler);
    },
    unsubscribeToErrorEvents: () => {
      authClient.tokenManager.off('error');
    },
    unsubscribeToLogoutEvents: () => {
      window.removeEventListener('storage', getAccessTokenHandler);
    },
    getUniqueId: (): string => uniqueId,
  };
};

export { generateOktaHelpers, validateRole, validateNotRole };
