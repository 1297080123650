import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { ValuesOf } from '@packages/common-ts-utils/src/valuesOf/valuesOf';

export const SECURE_ROUTE_STATUS = {
  loading: 'loading',
  isLoggedIn: 'isLoggedIn',
  isNotLoggedIn: 'isNotLoggedIn',
} as const;

interface SecureRouteProps extends RouteProps {
  defaultRoute: string;
  title?: string;
  status: ValuesOf<typeof SECURE_ROUTE_STATUS>;
}

const SecureRoute: React.FunctionComponent<SecureRouteProps> = ({
  defaultRoute,
  title,
  component,
  status,
  ...rest
}) => {
  const Component = component as any;

  if (status === SECURE_ROUTE_STATUS.loading) {
    return null;
  }

  if (status === SECURE_ROUTE_STATUS.isLoggedIn) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <>
            <Helmet title={title ? title : 'Allē'} />
            <Component {...props} />
          </>
        )}
      />
    );
  }

  // logged out redirect
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: defaultRoute, state: { from: props.location } }}
        />
      )}
    />
  );
};

export { SecureRoute };
