import { Machine } from 'xstate';

const createRootStateMachine = () => {
  return {
    initial: 'idle',
    states: {
      idle: {
        on: {
          TOGGLE_DRAWER: {
            target: '#closed',
          },
          MY_PROFILE: {
            target: '#open.myProfile',
            actions: () => {},
          },
          SETTINGS: {
            target: '#open.settings',
          },
        },
      },
    },
  };
};

const createMyProfileStateMachine = () => {
  return {
    initial: 'root',
    states: {
      root: {
        on: {
          BACK: {
            target: '#open.root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
          EDIT_PERSONAL_INFORMATION: {
            target: 'editPersonalInformation',
          },
          EDIT_EMAIL_ADDRESS: {
            target: 'editEmailAddress',
          },
          EDIT_PHONE_NUMBER: {
            target: 'editPhoneNumber',
          },
        },
      },
      editPersonalInformation: {
        on: {
          BACK: {
            target: 'root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
        },
      },
      editEmailAddress: {
        on: {
          BACK: {
            target: 'root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
        },
      },
      editPhoneNumber: {
        on: {
          BACK: {
            target: 'root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
        },
      },
    },
  };
};

const createSettingsStateMachine = () => {
  return {
    initial: 'root',
    states: {
      root: {
        on: {
          BACK: {
            target: '#open.root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
          COMMUNICATIONS_PREFERENCES: {
            target: 'communicationsPreferences',
          },
          CHANGE_PASSWORD: {
            target: 'changePassword',
          },
          MERGE_BD_ACCOUNT: {
            target: 'mergeBdAccount',
          },
          DELETE_ACCOUNT: {
            target: 'deleteAccount',
          },
        },
      },
      communicationsPreferences: {
        on: {
          BACK: {
            target: 'root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
        },
      },
      changePassword: {
        on: {
          BACK: {
            target: 'root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
        },
      },
      mergeBdAccount: {
        on: {
          BACK: {
            target: 'root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
        },
      },
      deleteAccount: {
        on: {
          BACK: {
            target: 'root',
          },
          TOGGLE_DRAWER: {
            target: '#closed',
          },
        },
      },
    },
  };
};

const createDrawerStateMachine = () => {
  const machineConfig = {
    id: 'drawer_machine',
    initial: 'closed',
    context: {},
    states: {
      closed: {
        id: 'closed',
        on: {
          TOGGLE_DRAWER: {
            target: 'open',
          },
        },
      },
      open: {
        id: 'open',
        initial: 'root',
        states: {
          root: {
            ...createRootStateMachine(),
          },
          myProfile: {
            ...createMyProfileStateMachine(),
          },
          settings: {
            ...createSettingsStateMachine(),
          },
        },
      },
    },
  };
  /* Uncomment to see config for https://xstate.js.org/viz/
  console.log(
    'Drawer Machine Config: ',
    JSON.stringify(machineConfig, null, 2)
  );
  */

  return Machine(machineConfig);
};

export { createDrawerStateMachine };
