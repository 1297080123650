import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Consumer_Tier } from '@packages/core-alle-graphql-types';
import { useAccountDashboardData } from '../../views/account/accountDashboard/accountDashboardDataLayer';

type HeaderColors = {
  bgColor: string;
  borderColor: string;
  menuIconColor: string;
  navLinkColor: string;
  navLinkHoverColor: string;
  isLogoDark: boolean;
};

const useHeaderColors = ({
  isLoggedIn,
}: {
  isLoggedIn?: boolean;
}): HeaderColors => {
  const [isAListProviders, setIsAListProviders] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsAListProviders(pathname && pathname === '/search' ? true : false);
  }, [pathname]);

  const { profile } = useAccountDashboardData();
  const isAList = profile?.loyalty?.tier === Consumer_Tier.AList;

  const defaultStyles: HeaderColors = {
    bgColor: `light.Section bg/Neutral/Base`,
    borderColor: `light.Border/Neutral/Subtle 2`,
    menuIconColor: `dark.Section bg/Neutral/Base`,
    navLinkColor: 'light.Text/Neutral/Interactive/Enable',
    navLinkHoverColor: 'light.Text/Neutral/Interactive/Hover',
    isLogoDark: false,
  };

  if (isLoggedIn && !isAList) {
    return {
      ...defaultStyles,
      bgColor: `light.Section bg/Brand/Mute 2`,
      borderColor: `light.Border/Neutral/Default 08`,
    };
  } else if (isAList && !isAListProviders) {
    return {
      bgColor: `dark.Section bg/Neutral/Base`,
      borderColor: `dark.Border/Neutral/Subtle 2`,
      menuIconColor: `light.Section bg/Neutral/Base`,
      navLinkColor: 'dark.Text/Neutral/Interactive/Enable',
      navLinkHoverColor: 'dark.Text/Neutral/Interactive/Hover',
      isLogoDark: true,
    };
  }

  return defaultStyles;
};

export { useHeaderColors };
