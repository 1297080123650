const yellow = {
  100: '#FDF6E5',
  200: '#FBE8BF',
  300: '#F7D080',
  400: '#F3B940',
  500: '#EFA200',
  600: '#B37900',
  700: '#785100',
  800: '#3C2800',
  900: '#181000',
};

export { yellow };
