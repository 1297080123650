import React from 'react';
import { Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { defaultRichTextRenderOptions } from './defaultRichTextRenderOptions';
import { CustomizeDefaultOptions } from './types';

export type RichTextProps = CustomizeDefaultOptions & {
  richText?: Document;
};

/**
 * @deprecated use Rich Text component in @packages/domain-cms which uses the new design system styles
 *
 * @param richText - The rich text json
 * @param customizeDefaultOptions - Params passed through to defaultRichTextRenderOptions (see type CustomizeDefaultOptions)
 */
const RichText = ({ richText, ...customizeDefaultOptions }: RichTextProps) => {
  if (!richText) return null;
  return (
    <div className="rich-text" data-testid="rich-text">
      {documentToReactComponents(
        richText,
        defaultRichTextRenderOptions({
          ...customizeDefaultOptions,
        })
      )}
    </div>
  );
};

export { RichText };
