import React from 'react';
import { consumerProfile } from '@packages/deprecated-shared-sdk/src/consumerProfile';
import { ConsumerProfile } from '@packages/core-alle-graphql-types';

/**
 * Utility hook for fetching the currently logged in consumer profile.
 */
const useConsumerProfile = (): [ConsumerProfile | null, string | null] => {
  const mounted = React.useRef(true);
  const [profile, setProfile] = React.useState<ConsumerProfile | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const consumer = await consumerProfile();
        mounted.current && setProfile(consumer);
      } catch (err) {
        mounted.current && setError(err.message);
      }
    };

    fetchProfile();

    return () => {
      mounted.current = false;
    };
  }, []);

  return [profile, error];
};

export { useConsumerProfile };
