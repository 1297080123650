// Copied from Material with some modifications
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/useScrollTrigger/useScrollTrigger.js

import React from 'react';

function getScrollY(ref: any) {
  return ref.pageYOffset !== undefined ? ref.pageYOffset : ref.scrollTop;
}

function defaultTrigger(event: any, store: any, options: any) {
  const { disableHysteresis = false, threshold = 100 } = options;
  const previous = store.current;
  store.current = event ? getScrollY(event.currentTarget) : previous;

  if (!disableHysteresis && previous !== undefined) {
    // On mobile, when you scroll beyond the end of the page, the view will
    // bounce back when you let go basically scrolling up the page.
    // To prevent this upward scroll from triggering, check if the current
    // scroll position is greater than or equal to total page height.
    if (
      store.current >=
      window.document.documentElement.offsetHeight - window.innerHeight
    ) {
      return true;
    }
    if (store.current < previous) {
      return false;
    }
  }

  return store.current > threshold;
}

const defaultTarget = typeof window !== 'undefined' ? window : null;

/**
 * @deprecated Use the useScrollTrigger hook from @packages/core-components/src/hooks/ui/useScrollTrigger
 */
function useScrollTrigger(options: any = {}) {
  const {
    getTrigger = defaultTrigger,
    target = defaultTarget,
    ...other
  } = options;
  const store = React.useRef();
  const [trigger, setTrigger] = React.useState(() =>
    getTrigger(null, store, other)
  );

  React.useEffect(() => {
    const handleScroll = (event: any) => {
      setTrigger(getTrigger(event, store, other));
    };

    handleScroll(null); // Re-evaluate trigger when dependencies change
    target.addEventListener('scroll', handleScroll);
    return () => {
      target.removeEventListener('scroll', handleScroll);
    };
    // See Option 3. https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, getTrigger, JSON.stringify(other)]);

  return trigger;
}

export { useScrollTrigger };
