import gql from 'graphql-tag';
import { client } from './client';
import { ConsumerProfile } from '@packages/core-alle-graphql-types';
import { __DEV__, __TEST__ } from '../src/application.config';

const CONSUMER_PROFILE_QUERY = gql`
  query GetConsumerProfile {
    viewer {
      id
      profile {
        __typename
        ... on ConsumerProfile {
          id
          internalId
          firstName
          lastName
          emailAddress
          phoneNumber
          pendingPhoneNumber
          address {
            zipCode
          }
          gender
          birthDate {
            format(layout: "MM.DD.YYYY")
          }
          loyalty {
            id
            memberSince {
              format(layout: "YYYY")
            }
            points {
              available
            }
            promotions {
              id
            }
          }
          communicationPreferences {
            optInMarketingEmail
            optInMarketingPush
            optInMarketingSMS
            optInEarningEmail
            optInEarningPush
            optInEarningSMS
          }
        }
      }
    }
  }
`;

const consumerProfile = (): Promise<ConsumerProfile> => {
  if (__DEV__ && !__TEST__) {
    console.warn(
      '@packages/deprecated-shared-sdk is deprecated - please consider refactoring this code into the application'
    );
  }
  return client
    .query({
      query: CONSUMER_PROFILE_QUERY,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.viewer.profile as ConsumerProfile);
};

export { consumerProfile };
