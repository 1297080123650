import React from 'react';

export interface UseScrollThresholdOptions {
  // detect if page is scrolled beyond this pixel value
  threshold: number;
}

function getCurrentScroll() {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

/**
 * Returns `{ passedThreshold: true }` if the page is scrolled past
 * the input threshold value (in pixels).
 */
function useScrollThreshold({ threshold }: UseScrollThresholdOptions) {
  const [passedThreshold, setPassedThresholdState] = React.useState(
    getCurrentScroll() >= threshold
  );
  const passedThresholdRef = React.useRef(getCurrentScroll() >= threshold);

  const setPassedThreshold = React.useCallback((val: boolean) => {
    if (val !== passedThresholdRef.current) {
      setPassedThresholdState(val);
      passedThresholdRef.current = val;
    }
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      setPassedThreshold(getCurrentScroll() >= threshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setPassedThreshold, threshold]);

  return {
    passedThreshold,
  };
}

export { useScrollThreshold };
