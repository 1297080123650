import React from 'react';
import styled from 'styled-components';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { useLinkContext } from '../linkContext';
import { preloadRoute } from '../preload';
import { __DEV__ } from '../../environment';

const StyledLink = styled(RouterLink)`
  text-decoration: none;
`;

const StyledA = styled.a`
  text-decoration: none;
`;

export interface LinkProps<T extends unknown = unknown>
  extends RouterLinkProps<T> {
  external?: boolean;
  /**
  Force link to open in same tab, even if it is an external link
  */
  forceSameTab?: boolean;
  className?: string;
  activeWhenNotExact?: boolean;
  preload?: boolean;
}

export function isString(arg: any): arg is string {
  return typeof arg === 'string';
}

interface SimpleRouterLink {
  pathname: string;
}

export function hasPathname(arg: any): arg is SimpleRouterLink {
  return (arg as SimpleRouterLink)?.pathname !== undefined;
}

/**
 *  @deprecated use the link from the useEnvironmentContext hook. If trying to render a button that functions like a link, use a button from alle-elements with the prop as={Link} (Link should be from useEnvironmentContext hook).
 *
 *  Link element
 *
 *  You can use this component with generics to strongly type the location state.
 *
 * @example <caption>Example usage of Link Component.</caption>
 * interface MyCustomState { isCustom: boolean }
 *
 * <Link<MyCustomState> to={{ pathname: '/about', state: { isCustom: true } }}>
 *
 * @param {LinkProps} props
 * @returns {JSX.Element} Element
 */
const Link = <T extends unknown = unknown>({
  children,
  external,
  forceSameTab = false,
  to,
  activeWhenNotExact,
  preload,
  ...forwardProps
}: LinkProps<T>): JSX.Element => {
  let pathname: string;

  if (isString(to)) {
    pathname = to;
  } else if (hasPathname(to)) {
    pathname = to.pathname;
  } else {
    if (__DEV__) {
      throw new Error('link#pathname can not be null');
    }

    pathname = '/';
  }

  const { routes } = useLinkContext();
  const location = useLocation();
  let active = false;

  React.useEffect(() => {
    // No need to preload if external (opens in new tab)
    if (!preload || external) {
      return;
    }

    preloadRoute(routes, pathname);
  }, [pathname, routes, external, preload]);

  if (!external) {
    const match = matchPath(location.pathname, pathname);
    if (match && (activeWhenNotExact || match.isExact)) {
      active = true;
    }
  }

  const LinkComponent: React.ElementType = external ? StyledA : StyledLink;
  const linkProps = external
    ? {
        href: pathname,
        target: forceSameTab ? '_self' : '_blank',
        rel: 'noopener noreferrer',
        ...forwardProps,
      }
    : {
        to,
        ...forwardProps,
        className: `${active ? 'active' : ''} ${forwardProps.className || ''}`,
      };

  return <LinkComponent {...linkProps}>{children}</LinkComponent>;
};

export { Link };
