import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps } from '@allergan-data-labs/alle-elements-link';

/**
 * @returns Link element using Alle Elements Link component, as 'button'.
 * If implementing a ButtonLink (link that functions as a button) should use this instead of using alle-elements-link directly. If trying to implement a (navigational) Link, use the Link from the useEnvironmentContext hook.
 */
const ButtonLink = (props: Omit<LinkProps, 'as'>) => {
  return (
    <Link as="button" padding={0} backgroundColor={'transparent'} {...props} />
  );
};

export { ButtonLink };
