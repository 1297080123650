import { useMemo } from 'react';
import { Routes } from '../../constants/routes';
import { NavigationItemLinks } from './headerDrawerItem';

const setActiveRoute = (
  item: NavigationItemLinks,
  currentPath: string
): void => {
  item.isActive = item.href === currentPath;
  item.items?.forEach((nestedItem) => setActiveRoute(nestedItem, currentPath));
};

const brandLinks = {
  id: 'drawer-item-brands',
  title: 'Our Brands',
  hideOnLoggedInNav: true,
  href: '#',
  items: [
    {
      id: 'drawer-link-brand-botox',
      title: 'BOTOX® Cosmetic (onabotulinumtoxinA)',
      href: '/treatment-guide/botox',
    },
    {
      id: 'drawer-link-brand-juvederm',
      title: 'JUVÉDERM® Collection of Fillers',
      href: '/treatment-guide/juvederm',
    },
    {
      id: 'drawer-link-brand-coolsculpting',
      title: 'CoolSculpting®',
      href: '/treatment-guide/coolsculpting',
    },
    {
      id: 'drawer-link-brand-cooltone',
      title: 'CoolTone®',
      href: '/treatment-guide/cooltone',
    },
    {
      id: 'drawer-link-brand-skinmedica',
      title: 'SkinMedica®',
      href: '/treatment-guide/skinmedica',
    },
    {
      id: 'drawer-link-brand-diamondglow',
      title: 'DiamondGlow®',
      href: '/treatment-guide/diamondglow',
    },
    {
      id: 'drawer-link-brand-latisse',
      title: 'LATISSE® (bimatoprost ophthalmic solution)',
      href: '/treatment-guide/latisse',
    },
    {
      id: 'drawer-link-brand-kybella',
      title: 'KYBELLA® (deoxycholic acid) injection 10mg/mL',
      href: '/treatment-guide/kybella',
    },
    {
      id: 'drawer-link-brand-natrelle',
      title: 'Natrelle®',
      href: '/treatment-guide/natrelle',
    },
    {
      id: 'drawer-link-brand-skinvive',
      title: 'SKINVIVE by JUVÉDERM®',
      href: '/treatment-guide/skinvive',
    },
    {
      id: 'drawer-link-brand-botox',
      title: 'View all',
      href: Routes.ourBrands,
    },
  ],
};

const getLoggedInLinks = (currentPath: string): NavigationItemLinks[] => {
  const links = [
    {
      id: 'drawer-link-home',
      title: 'Home',
      href: Routes.accountDashboard,
    },
    {
      id: 'drawer-link-providers',
      title: 'Providers',
      href: Routes.providerSearch,
    },
    {
      id: 'drawer-link-wallet',
      title: 'Wallet',
      href: Routes.accountWallet,
    },
    brandLinks,
    {
      id: 'drawer-item-more',
      title: 'More',
      href: '#',
      items: [
        {
          id: 'drawer-link-how-it-works',
          title: 'How It Works',
          href: Routes.howItWorks,
        },
        {
          id: 'drawer-link-payment-plans',
          title: 'Payment Plans',
          href: Routes.paymentPlansLandingPage,
        },
        {
          id: 'drawer-link-about',
          title: 'About Allē & FAQ',
          href: Routes.about,
        },
      ],
    },
  ];

  links.forEach((item) => setActiveRoute(item, currentPath));

  return links;
};

const getLoggedOutLinks = (currentPath: string): NavigationItemLinks[] => {
  const links = [
    brandLinks,
    {
      id: 'drawer-item-rewards',
      title: 'Rewards',
      href: '#',
      items: [
        {
          id: 'drawer-link-how-it-works',
          title: 'How It Works',
          href: Routes.howItWorks,
        },
        {
          id: 'drawer-link-a-list-benefits',
          title: 'A-List Benefits',
          href: '/article/a-list-benefits',
        },
        {
          id: 'drawer-link-about',
          title: 'About Allē & FAQ',
          href: Routes.about,
          onClick: () => {},
        },
      ],
    },
    {
      id: 'drawer-link-payment-plans',
      title: 'Payment Plans',
      href: Routes.paymentPlansLandingPage,
    },
    {
      id: 'drawer-link-get-treated',
      title: 'Get Treated',
      href: Routes.providerSearch,
    },
  ];

  links.forEach((item) => setActiveRoute(item, currentPath));

  return links;
};

const useHeaderNavLinks = ({
  isLoggedIn,
  currentPath,
}: {
  isLoggedIn?: boolean;
  currentPath: string;
}) => {
  const links = useMemo(
    () =>
      isLoggedIn
        ? getLoggedInLinks(currentPath)
        : getLoggedOutLinks(currentPath),
    [currentPath, isLoggedIn]
  );

  return { links };
};

export {
  setActiveRoute,
  getLoggedInLinks,
  getLoggedOutLinks,
  useHeaderNavLinks,
};
