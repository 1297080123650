import update from 'immutability-helper';

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};

const getQueryParam = (name: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

const transformPayload = (
  payload: any,
  fbc: string | null,
  fbp: string | null
) => {
  const facebookData = {
    ...(fbc && { fbc }),
    ...(fbp && { fbp }),
  };

  return update(payload, {
    obj: (obj: any) =>
      update(obj || {}, {
        properties: (properties: any) =>
          update(properties || {}, {
            event: (event: any) =>
              update(event || {}, {
                extra_data: (extraDataCurrent: any) =>
                  update(extraDataCurrent || {}, { $merge: facebookData }),
              }),
          }),
      }),
    raw: (raw: any) =>
      update(raw || {}, {
        properties: (properties: any) =>
          update(properties || {}, {
            event: (event: any) =>
              update(event || {}, {
                extra_data: (extraDataCurrent: any) =>
                  update(extraDataCurrent || {}, { $merge: facebookData }),
              }),
          }),
      }),
  });
};

const fbcFbpValueMiddleware = ({ payload, next }: any): void => {
  try {
    let fbc = getCookie('_fbc');
    const fbp = getCookie('_fbp');
    const fbclid = getQueryParam('fbclid');

    if (fbclid) {
      fbc = `fb.1.${Date.now()}.${fbclid}`;
    }

    if (fbc || fbp) {
      payload = transformPayload(payload, fbc, fbp);
    }
  } catch (e) {
    console.error('segmentEvents#middleware', e);
  }

  next(payload);
};

export { fbcFbpValueMiddleware };
