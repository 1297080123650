import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

// MUI
import BaseButton from '@material-ui/core/Button';

// components
import { colors } from '../colors';
import { graphikFontFamily } from '../typography/typographyDEPRECATED';
import { Oval } from '../../components/loaders/oval';
import { __DEV__ } from '../../environment';

import {
  lightTheme,
  darkTheme,
  boneTheme,
  ButtonProps,
} from '../themes/alleButton';

const AlleStyledButton = styled(
  ({ marginTop, buttonType, dark, loading, variant, ...forwardProps }) => (
    <BaseButton {...forwardProps} />
  )
)<ButtonProps>`
  &.MuiButton-root {
    transition: initial;
    box-sizing: border-box;
    height: 48px;
    padding: 8px 16px;
    margin-top: ${(props) => (props.marginTop ? '16px' : '0')};
    border: ${(props) => props.theme[props.buttonType].borderColor};
    background-color: ${(props) =>
      props.theme[props.buttonType].backgroundColor};

    .MuiButton-label {
      font-family: ${graphikFontFamily};
      font-size: 10px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: 1px;
      text-align: center;
      color: ${(props) => props.theme[props.buttonType].color};
    }

    &.Mui-focusVisible,
    &:hover {
      border: ${(props) => props.theme[props.buttonType].hoverBorderColor};
      background-color: ${(props) =>
        props.theme[props.buttonType].hoverBackgroundColor};

      .MuiButton-label {
        color: ${(props) => props.theme[props.buttonType].hoverColor};
      }
      &.Mui-disabled {
        background-color: ${(props) =>
          props.theme[props.buttonType].hoverDisabledBackgroundColor};
      }
    }
    &.MuiButtonBase-root.MuiButton-root.loading {
      background-color: ${(props) =>
        props.theme[props.buttonType].loadingBackgroundColor};

      & svg {
        stroke: ${(props) => props.theme[props.buttonType].loadingStroke};
      }
    }

    &.disabled {
      opacity: ${(props) => props.theme[props.buttonType].disabledOpacity};
      border: ${(props) => props.theme[props.buttonType].disableBorder};
      background-color: ${(props) =>
        props.theme[props.buttonType].disabledBackgroundColor};

      .MuiButton-label {
        color: ${(props) => props.theme[props.buttonType].disabledColor};
      }
    }
  }
`;

const AlleButton: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    variant = 'light',
    loading = false,
    disabled = false,
    buttonType = 'outlined',
    dark,
    icon,
    className = '',
    children,
    ...forwardProps
  } = props;

  let theme;

  if (dark !== undefined) {
    if (__DEV__) {
      console.log(
        '----- Button Component ----- \n DARK prop has been deprecated \n please use VARIANT props instead'
      );
    }
    theme = dark ? darkTheme : lightTheme;
  } else {
    theme =
      variant === 'dark'
        ? darkTheme
        : variant === 'light'
        ? lightTheme
        : variant === 'bone'
        ? boneTheme
        : lightTheme;
  }

  return (
    <ThemeProvider theme={theme as any}>
      <AlleStyledButton
        {...forwardProps}
        buttonType={buttonType}
        loading={loading}
        disabled={loading || disabled}
        aria-disabled={loading || disabled}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        className={
          className +
          (loading ? ' loading' : '') +
          (disabled ? ' disabled' : '')
        }
      >
        {icon ? (
          icon
        ) : loading ? (
          <Oval
            color={
              dark
                ? buttonType === 'outlined'
                  ? colors.white
                  : colors.black
                : colors.white
            }
          />
        ) : (
          children
        )}
      </AlleStyledButton>
    </ThemeProvider>
  );
};

export { AlleButton };
