import { v4 as uuid } from 'uuid';

export const getSplitKey = (debuggerEnabled?: boolean) => {
  let splitKey: string;
  const segmentID = localStorage.getItem('ajs_anonymous_id');
  if (!segmentID) {
    const storedAlleAnonId = localStorage.getItem('alle_anonymous_id');
    const alleAnonId = storedAlleAnonId ? storedAlleAnonId : uuid();
    splitKey = alleAnonId;
    localStorage.setItem('alle_anonymous_id', alleAnonId);

    debuggerEnabled &&
      console.log(`SETTING ALLE ANON ID FOR SPLIT AND SEGMENT: ${alleAnonId}`);
  } else {
    splitKey = JSON.parse(segmentID);
    // remove alle anon id bc that's what's used to idicate if split used existing segment ID or not
    localStorage.removeItem('alle_anonymous_id');
    debuggerEnabled &&
      console.log(`USING SEGMENT ANON ID FOR SPLIT KEY: ${splitKey}`);
  }

  return splitKey;
};
