import React from 'react';
import styled from 'styled-components';
import { colors } from '../../core/colors';

const Container = styled.div`
  height: 25px;
  width: 25px;
  svg {
    stroke: ${({ color }: { color: string }) => color};
  }
`;

const StyledSvg = styled.svg`
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Oval: React.FunctionComponent<{ color?: string; className?: string }> = ({
  color = colors.white,
  className,
  ...forwardProps
}) => {
  return (
    <Container
      color={color}
      className={`oval-container ${className}`}
      {...forwardProps}
    >
      <StyledSvg width={25} height={25} viewBox="0 0 38 38">
        <g
          transform="translate(1 1)"
          strokeWidth={2}
          fill="none"
          fillRule="evenodd"
        >
          <circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
          <path d="M36 18c0-9.94-8.06-18-18-18" />
        </g>
      </StyledSvg>
    </Container>
  );
};

export { Oval };
