import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const MegaPhone: React.FunctionComponent = () => (
  <IconContainer pathFill="transparent" height="60px" width="60px">
    <SvgIcon
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="59.4375"
        y="0.5625"
        width="58.875"
        height="58.875"
        rx="29.4375"
        transform="rotate(90 59.4375 0.5625)"
        stroke="#D09284"
        strokeWidth="1.125"
        fill="none"
      />
      <path
        d="M33.5401 18.4942C33.2629 21.4677 38.288 35.3682 40.7575 34.6878C43.6302 33.8814 39.044 18.0313 35.0122 16.091C34.0042 15.6122 33.475 15.9146 32.6686 16.4438C30.5771 17.7541 28.536 19.14 26.4949 20.526C21.3291 24.0034 16.1129 27.4557 11.1739 31.2859C10.9975 31.4371 10.8211 31.5631 10.6195 31.7143C9.3344 32.7475 9.43519 34.8894 9.98957 36.4517C10.3676 37.4849 11.2495 38.518 12.3583 38.4424C12.5347 38.4424 12.6859 38.392 12.8371 38.2913C13.0891 38.1149 14.4547 37.1079 13.8751 34.7392C13.6987 33.9832 12.59 31.9925 11.7836 33.4289C11.0276 34.7644 11.2243 37.8377 13.2906 38.2661C13.8954 38.3921 18.7084 37.9889 20.4976 37.6613C21.3001 40.0496 23.2946 46.2884 23.7734 47.12C24.4538 48.3548 25.7641 48.3044 26.4949 47.6996C27.0745 47.2208 27.4502 46.4096 27.1501 45.6081C24.9001 39.5996 24.0001 36.8996 24.0001 36.8996C26.7216 36.572 33.8782 35.6706 37.3053 35.1918"
        stroke="#C08676"
        strokeWidth="1.125"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M40.3793 17.9449C41.2345 16.7848 42.9445 14.2198 44.7119 11.2468"
        stroke="#C08676"
        strokeWidth="1.125"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M43.3029 23.1187C44.038 22.7983 44.7557 22.4505 45.4907 22.1301C47.3109 21.3017 49.1514 20.4756 50.9716 19.6472"
        stroke="#C08676"
        strokeWidth="1.125"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M45.082 29.6104C47.5615 29.6104 50.0615 29.8371 52.5205 30.2907"
        stroke="#C08676"
        strokeWidth="1.125"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  </IconContainer>
);

export { MegaPhone };
