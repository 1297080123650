import React from 'react';

interface LoadingContextType {
  setIsLoading: (isLoading: boolean) => void;
  setDuration: (duration: number) => void;
  duration?: number;
  isLoading: boolean;
}

const LoadingContext = React.createContext<LoadingContextType>({
  setIsLoading: () => {},
  setDuration: () => {},
  isLoading: false,
});

const LoadingProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [duration, setDuration] = React.useState<number>();

  return (
    <LoadingContext.Provider
      value={{
        setIsLoading,
        isLoading,
        setDuration,
        duration,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingProvider, LoadingContext };
