import React from 'react';

export interface RouteConfig {
  path: string;
  component: React.FunctionComponent<any>;
}

interface LinkContextValue {
  routes: RouteConfig[];
}

const LinkContext = React.createContext<LinkContextValue>({
  routes: [],
});

const LinkProvider: React.FC<React.PropsWithChildren<LinkContextValue>> = ({
  routes,
  children,
}) => {
  return (
    <LinkContext.Provider value={{ routes }}>{children}</LinkContext.Provider>
  );
};

export const useLinkContext = () => React.useContext(LinkContext);

export { LinkProvider };
