import React, { CSSProperties } from 'react';

export const DEFAULT_GROUP_NAME = 'default';

export interface NotificationOptions {
  /**
   * Only one notification will be visible for any given
   * unique ID. In other words, if a notification is added
   * with an ID that matches an existing notification, the
   * old one will be removed from the UI.
   */
  id: string;
  /**
   * The main content to be shown in the notification.
   */
  text?: string | React.ReactNode;
  /**
   * An Icon component to be rendered to the left of the
   * text content.
   */
  startIcon?: React.ReactNode;
  /**
   * Additional styles to be applied to the notification.
   */
  style?: CSSProperties;
  /**
   * Control the height of the notification.
   * medium = 48px (default)
   * large = 72px
   */
  size?: 'medium' | 'large';
  /**
   * Number of ms before the notification should automatically
   * be dismissed. If a value is not provided, a Close icon will
   * be shown on the right side allowing the user to manually
   * dismiss the icon.
   *
   * See `persistent` if you would like prevent a notification from
   * being dismissed.
   */
  timeout?: number;
  /**
   * Boolean indicating a Close icon should be shown even when
   * notification will be automtically dismissed (set via timeout option).
   * Default behavior is to not show Close icon when notification has timeout.
   * NOTE: This option is only valid when timeout is set/'persistent' notification will never show close btn
   */
  showTimeoutCloseButton?: boolean;
  /**
   * If true, the notification will stay visible indefinitely. The
   * Close icon will not be displayed, preventing the user from
   * manually dismissing the notification as well.
   */
  persistent?: boolean;
  /**
   * By default, notifications will be dismissed on a route change.
   * You can provide a number here to tell the notification to stay
   * visible for `liveFor` number of route changes.
   * This option is useful if you are displaying a notification right
   * before going to a new route, and you want it to remain visible
   * on the next page.
   */
  liveFor?: number;
  /**
   * Allows the notification to only be displayed once a specific
   * route is visited.
   */
  loadImmediatelyOnRoute?: string;
  /**
   * Allows notifications to be displayed in an instance of the
   * NotificationBar component separate from the main instance.
   *
   * For example, if you trigger a notification with `groupName`
   * === 'group-1', then those notifications will only be shown
   * in a NotificationBar component rendered like:
   *
   * <NotificationBar groupName="group-1" />
   *
   */
  groupName?: string;
  /**
   * The variant to use for the notification, which will determine
   * the background color, text color, etc.
   */
  variant:
    | 'general'
    | 'positive'
    | 'positiveLight'
    | 'warning'
    | 'error'
    | 'errorLight'
    | 'errorLight2'
    | 'success';
  /**
   * Allows a callback after the notification is closed.
   * Ex: setting a cookie to say notification has been seen.
   */
  onCloseCallback?: (params: OnCloseCallbackParams) => void;
}

export interface OnCloseCallbackParams {
  id: string;
  isClearAll: boolean;
}

export interface ClearAllNotificationsOptions {
  groupName?: string;
}

export interface NotificationOptionsInternal extends NotificationOptions {
  closed?: Date;
  internalId?: number;
}

interface NotificationContextType {
  addNotification: (options: NotificationOptions) => void;
  clearNotification: (id: string) => void;
  clearAllNotifications: (options?: ClearAllNotificationsOptions) => void;
  notifications: NotificationOptions[];
}

const NotificationContext = React.createContext<NotificationContextType>({
  addNotification: () => {},
  clearNotification: () => {},
  clearAllNotifications: () => {},
  notifications: [],
});

interface NotificationProviderProps {
  children: React.ReactNode;
  pathname: string;
}

const NotificationProvider: React.FunctionComponent<
  NotificationProviderProps
> = ({ children, pathname }) => {
  const [notifications, setNotifications] = React.useState<
    NotificationOptionsInternal[]
  >([]);
  const pendingTimeouts = React.useRef<number[]>([]);

  const clearTimeouts = () => {
    pendingTimeouts.current.map((timeout: number) => clearTimeout(timeout));
  };

  React.useEffect(() => {
    return clearTimeouts;
  }, []);

  React.useEffect(() => {
    if (
      notifications.find(
        (notification) =>
          notification.loadImmediatelyOnRoute === pathname &&
          notification.closed
      )
    ) {
      setNotifications((prevNotifications) =>
        prevNotifications.map((pNotification) => {
          if (
            pNotification.loadImmediatelyOnRoute === pathname &&
            pNotification.closed
          ) {
            delete pNotification.closed;
          }
          return pNotification;
        })
      );
    }
  }, [notifications, pathname]);

  const addNotification = React.useCallback(
    (notification: NotificationOptions) => {
      setNotifications((prevNotifications) => [
        {
          ...notification,
          groupName: notification.groupName ?? DEFAULT_GROUP_NAME,
          // Generate a unique internal ID
          internalId: new Date().getTime(),
          ...(notification.loadImmediatelyOnRoute &&
          notification.loadImmediatelyOnRoute !== pathname
            ? { closed: new Date() }
            : {}),
        },
        ...prevNotifications
          .filter((n) => {
            // Clean up notifications that were closed more than 500ms ago
            return !n.closed || new Date().getTime() - n.closed.getTime() < 500;
          })
          .map((n) => {
            // Close any existing notifications with same id
            return n.id === notification.id
              ? {
                  ...n,
                  closed: new Date(),
                }
              : n;
          }),
      ]);
      if (notification.timeout) {
        pendingTimeouts.current.push(
          setTimeout(
            () => clearNotification(notification.id),
            notification.timeout
          ) as unknown as number
        );
      }
    },
    [pathname]
  );

  const clearNotification = (id: string) => {
    setNotifications((prevNotifications) => {
      return prevNotifications.map((n) => {
        if (n.loadImmediatelyOnRoute) {
          delete n.loadImmediatelyOnRoute;
        }
        if (n.id === id && n.onCloseCallback) {
          n.onCloseCallback({ id, isClearAll: false });
        }

        return n.id === id
          ? {
              ...n,
              closed: new Date(),
            }
          : n;
      });
    });
  };

  const clearAllNotifications = (options?: ClearAllNotificationsOptions) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((n) => {
        if (options?.groupName && options.groupName !== n.groupName) {
          return n;
        }
        if (n.persistent || n.loadImmediatelyOnRoute === pathname) {
          return n;
        }
        if (n.liveFor) {
          return {
            ...n,
            liveFor: n.liveFor - 1,
          };
        }
        if (n.onCloseCallback) {
          n.onCloseCallback({ id: n.id, isClearAll: true });
        }

        return {
          ...n,
          closed: new Date(),
        };
      })
    );
  };

  return (
    <NotificationContext.Provider
      value={{
        addNotification,
        clearNotification,
        clearAllNotifications,
        notifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, NotificationContext };
