import React from 'react';
import { useLocation } from 'react-router-dom';

import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';

interface SegmentProperties {
  [key: string]: string | null | undefined;
}

const useSegmentPageTracking = (
  baseUrl: string,
  properties?: SegmentProperties,
  shouldRun?: boolean
) => {
  const { pathname } = useLocation();
  const { page } = useSegment();
  const formattedPath = pathname.endsWith('/')
    ? pathname.toLowerCase().slice(0, pathname.lastIndexOf('/'))
    : pathname.toLowerCase();
  // Track a page event each time the path is updated, segment becomes enabled or properties are updated.
  React.useEffect(() => {
    if (shouldRun) {
      page(undefined, {
        path: formattedPath,
        url: baseUrl + formattedPath,
        ...properties,
      });
    }
  }, [baseUrl, shouldRun, page, properties, formattedPath]);
};

export { useSegmentPageTracking };
