import React, { PropsWithChildren } from 'react';
import {
  Responsify,
  TypographyPreset,
} from '@packages/deprecated-universal-component-library/src/atoms/typography';
import { Text, defaultParagraphPreset } from './defaultRichTextRenderOptions';

type Props = {
  paragraphPreset?: Responsify<TypographyPreset>;
};

export const Paragraph: React.FC<PropsWithChildren<Props>> = ({
  children,
  paragraphPreset = defaultParagraphPreset,
}) => <Text preset={paragraphPreset}>{children}</Text>;
