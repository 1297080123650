import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { LoadingContext } from '@packages/deprecated-component-library/src/loadingBar/loadingContext';
import { ConsumerProfileQueryDeprecated } from '../../../utils/hooks/useLazyConsumerProfile';

const QUERY_PROFILE = gql`
  query ConsumerAccountDashboard {
    viewer {
      id
      profile {
        __typename
        ... on ConsumerProfile {
          id
          firstName
          loyalty {
            id
            isBDMember
            tier
            points {
              id
              available
            }
            promotionsConnection {
              offsetPageInfo {
                totalResults
              }
            }
          }
        }
      }
    }
  }
`;

const useAccountDashboardData = () => {
  const { setIsLoading } = React.useContext(LoadingContext);

  const {
    data: apolloData,
    error: apolloError,
    loading: apolloLoading,
    refetch,
  } = useQuery<ConsumerProfileQueryDeprecated>(QUERY_PROFILE, {
    fetchPolicy: 'cache-first',
  });

  React.useEffect(() => {
    setIsLoading(apolloLoading);
  }, [apolloLoading, setIsLoading]);

  return {
    refetch,
    status: apolloError
      ? ('error' as const)
      : apolloLoading
      ? ('loading' as const)
      : ('completed' as const),
    profile: apolloData?.viewer?.profile,
    error: apolloError,
  };
};

export { useAccountDashboardData };
