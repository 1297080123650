import { useCallback } from 'react';
import update from 'immutability-helper';

const TTCLID_STORAGE_KEY = 'ttclid';

const useSaveTikTokClickId = () => {
  const saveTikTokClickId = useCallback((search: string) => {
    try {
      const urlParams = new URLSearchParams(search);
      const ttclid = urlParams.get('ttclid');

      if (ttclid) {
        const data = {
          value: ttclid,
          expireAt: new Date(new Date().setDate(new Date().getDate() + 28)), // expires 28 days later
        };
        localStorage.setItem(TTCLID_STORAGE_KEY, JSON.stringify(data));
      }
    } catch (e) {
      console.error('segmentEvents#save-ttclid#hook', e);
    }
  }, []);
  return { saveTikTokClickId };
};

const transformPayload = (payload: any, ttclid: string) => {
  return update(payload, {
    obj: (obj: any) =>
      update(obj || {}, {
        properties: (properties: any) =>
          update(properties || {}, {
            event: (event: any) =>
              update(event || {}, {
                extra_data: (extraDataCurrent: any) =>
                  update(extraDataCurrent || {}, {
                    $merge: { ttclid: ttclid },
                  }),
              }),
          }),
      }),
    raw: (raw: any) =>
      update(raw || {}, {
        properties: (properties: any) =>
          update(properties || {}, {
            event: (event: any) =>
              update(event || {}, {
                extra_data: (extraDataCurrent: any) =>
                  update(extraDataCurrent || {}, {
                    $merge: { ttclid: ttclid },
                  }),
              }),
          }),
      }),
  });
};

const ttclidValueMiddleware = ({ payload, next }: any): void => {
  try {
    const ttclidRawData = localStorage.getItem(TTCLID_STORAGE_KEY);
    if (ttclidRawData) {
      const ttclidData = JSON.parse(ttclidRawData);
      if (new Date(ttclidData.expireAt) <= new Date()) {
        localStorage.removeItem(TTCLID_STORAGE_KEY);
      } else {
        payload = transformPayload(payload, ttclidData.value);
      }
    }
  } catch (e) {
    console.error('segmentEvents#ttclid#middleware', e);
  }

  next(payload);
};

export { ttclidValueMiddleware, useSaveTikTokClickId };
