import React from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from './usePrevious';
import { NotificationContext } from '../notificationBar/notificationContext';

const useClearAllNotifications = () => {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname) ?? pathname;
  const { clearAllNotifications } = React.useContext(NotificationContext);

  React.useEffect(() => {
    if (prevPathname !== pathname) {
      clearAllNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevPathname, pathname]);
};

export { useClearAllNotifications };
