import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

export interface InstagramLogoProps {
  fill?: string;
}

const InstagramLogo: React.FunctionComponent<InstagramLogoProps> = ({
  fill,
}) => (
  <IconContainer pathFill={fill}>
    <SvgIcon width="22px" height="22px" viewBox="0 0 22 22">
      <path
        d="M15.0325443,2 L6.96724279,2 C4.22830678,2 2,4.22841324 2,6.96734926 L2,15.0326507 C2,17.7716932 4.22830678,20 6.96724279,20 L15.0325443,20 C17.7716932,20 20,17.7715868 20,15.0326507 L20,6.96734926 C20,4.22841324 17.7716932,2 15.0325443,2 Z M15.0325443,3.59704724 C16.8909749,3.59704724 18.4030592,5.10902509 18.4030592,6.96734926 L18.4030592,15.0326507 C18.4030592,16.8910814 16.8910814,18.4029528 15.0326507,18.4029528 L6.96724279,18.4029528 C5.10891862,18.4029528 3.59704724,16.8910814 3.59704724,15.0326507 L3.59704724,6.96734926 C3.59704724,5.10902509 5.10891862,3.59704724 6.96724279,3.59704724 L15.0325443,3.59704724 Z M11,6.28571429 C8.4005037,6.28571429 6.28571429,8.40055225 6.28571429,11.0001082 C6.28571429,13.599556 8.4005037,15.7142857 11,15.7142857 C13.5994963,15.7142857 15.7142857,13.599556 15.7142857,11.0001082 C15.7142857,8.40055225 13.5994963,6.28571429 11,6.28571429 Z M11,7.90890816 C12.7044281,7.90890816 14.0910209,9.2955328 14.0910209,11 C14.0910209,12.704359 12.7043199,14.0908754 11,14.0908754 C9.29568015,14.0908754 7.90897911,12.704359 7.90897911,11 C7.90897911,9.2955328 9.29557193,7.90890816 11,7.90890816 Z M16.1428571,4.57142857 C15.8050649,4.57142857 15.4732338,4.70818182 15.2346753,4.94779221 C14.9949481,5.18623377 14.8571429,5.51818182 14.8571429,5.85714286 C14.8571429,6.19505195 14.9950649,6.52688312 15.2346753,6.76649351 C15.4731169,7.00493506 15.8050649,7.14285714 16.1428571,7.14285714 C16.4818182,7.14285714 16.8125974,7.00493506 17.0522078,6.76649351 C17.2918182,6.52688312 17.4285714,6.19493506 17.4285714,5.85714286 C17.4285714,5.51818182 17.2918182,5.18623377 17.0522078,4.94779221 C16.8137662,4.70818182 16.4818182,4.57142857 16.1428571,4.57142857 Z"
        id="path-1"
      ></path>
    </SvgIcon>
  </IconContainer>
);

export { InstagramLogo };
