import React from 'react';
import {
  IBlockFooterFields,
  ILinkFields,
  IStaticCodeFields,
} from '@packages/core-contentful-rest';
import { RichText } from '@packages/domain-cms/src/components/rich-text/richText';

export interface CmsFooterProps {
  copyright: React.ReactNode;
  PRT?: string;
  links?: Array<ILinkFields | IStaticCodeFields>;
}

export interface FooterProps extends CmsFooterProps {
  isLoggedIn?: boolean;
  renderLink: (link: ILinkFields) => JSX.Element;
  renderStaticCodeComponent: (
    staticCode: IStaticCodeFields
  ) => JSX.Element | null;
}

// PRT prop must be passed in to component seperately, depending on whether or not PRT is part of copyright
export const cmsFooterToProps = (
  contentfulFooter?: IBlockFooterFields | null
): Omit<CmsFooterProps, 'PRT'> => {
  return {
    copyright: (
      <RichText
        richText={contentfulFooter?.footerCopy}
        nodeProps={{
          p: { textStyle: 'Body/Small/Regular' },
        }}
      />
    ),
    links: contentfulFooter?.linksReference?.[0].fields.links.map(
      (link) => link.fields
    ),
  };
};

export const isStaticCode = (
  link: ILinkFields | IStaticCodeFields | null
): link is IStaticCodeFields => {
  return (link as IStaticCodeFields)?.codeId !== undefined;
};
