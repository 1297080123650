import Slide from '@material-ui/core/Slide';
import { useMachine } from '@xstate/react';
import React, { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

// component library
import { Consumer_Tier } from '@packages/core-alle-graphql-types';
import { colors } from '@packages/deprecated-component-library/src/core/colors';
import {
  Link,
  LinkProps,
} from '@packages/deprecated-component-library/src/core/link';
import { SimpleIconButton } from '@packages/deprecated-component-library/src/core/simpleIconButton';
import { HeaderContainer } from '@packages/deprecated-component-library/src/header/headerContainer';
import { useScrollTrigger } from '@packages/deprecated-component-library/src/hooks/useScrollTrigger';
import { AlleLogo } from '@packages/deprecated-component-library/src/icons/alleLogo';

import { Menu } from '@packages/deprecated-component-library/src/icons/menu';
import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';
import { Typography } from '@packages/deprecated-universal-component-library/src/atoms/typography';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';

import { useAccountDashboardData } from '../../views/account/accountDashboard/accountDashboardDataLayer';
import { createDrawerStateMachine } from '../drawer/drawerStateMachine';

// constants
import { Routes } from '../../constants/routes';
const Drawer = React.lazy(() =>
  import(/* webpackChunkName: 'Drawer' */ '../drawer/drawer').then((mod) => ({
    default: mod.Drawer,
  }))
);

const StyledHeaderContainer = styled(HeaderContainer)<{
  $compact?: boolean;
}>`
  && {
    align-items: center;
    ${({ $compact }) =>
      $compact
        ? `
        height: 74px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: white;
      `
        : ''}

    ${({ $compact }) =>
      $compact
        ? above.tablet`
          height: 63px;
        `
        : ''};

    ${({ $compact }) =>
      $compact
        ? above.desktop`
            height: 70px;
            position: relative;
          `
        : ''};
  }
`;

const LogoLink = styled(Link)<{
  $compact?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  && svg {
    ${({ $compact }) =>
      $compact
        ? `
          width: 54px;
          `
        : ''}

    ${({ $compact }) =>
      $compact
        ? above.tablet`
        height: 42px;
      `
        : ''};

    ${({ $compact }) =>
      $compact
        ? above.desktop`
        height: 48px;
      `
        : ''};
  }
`;

const LinksContainer = styled.div<{
  $compact?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ $compact }) =>
    !$compact
      ? `
        @media (max-width: 1023px) {
          display: none;`
      : ''}
  }

  ${({ $compact }) =>
    $compact
      ? above.desktop`
      display: none;
    `
      : ''};
`;

const CenterLink: <T extends unknown = unknown>(
  props: LinkProps<T> & { isAList?: boolean; isAListProviders?: boolean }
) => JSX.Element = styled(({ isAList, isAListProviders, ...forwardProps }) => (
  <Link {...forwardProps} />
))`
  text-decoration: none;
  margin: 0 49px;

  ${(props) =>
    props.isAList && !props.isAListProviders
      ? `> p {
          color: ${colors.accent};
        }
        &:hover > p {
          color: ${colors.white};
        }
        &.active > p {
          color: ${colors.white};
          font-weight: 500;
        }`
      : `> p {
          color: #666666};
        }
        &:hover > p {
          color: ${colors.black};
        }
        &.active > p {
          color: ${colors.black};
          font-weight: 500;
        }`}
`;

const FixedContainer = styled.div<{
  $compact?: boolean;
}>`
  ${({ $compact }) =>
    !$compact
      ? `
    width: 100%;
    position: fixed;
    z-index: 100;
    `
      : ''}
`;

const DrawerFallback: React.FunctionComponent = () => {
  // Drawer is rendered off screen until visible, no need to display a fallback.
  return null;
};

const LoggedInHeader: React.FunctionComponent<{
  compact?: boolean;
}> = ({ compact }) => {
  const { trackConsumer } = useSegment();
  const machine = React.useMemo(createDrawerStateMachine, []);
  const [current, send] = useMachine(machine);
  const trigger = useScrollTrigger();
  const { profile } = useAccountDashboardData();
  const isAList = profile?.loyalty?.tier === Consumer_Tier.AList;
  const [isAListProviders, setIsAListProviders] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsAListProviders(pathname && pathname === '/search' ? true : false);
  }, [pathname]);

  const onAlleLogoClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Logged In - Allē Logo',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_in',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Allē',
      url: `${Routes.accountDashboard}`,
    });
  }, [trackConsumer]);

  const onTopNavLinkClick = useCallback(
    (e: SyntheticEvent, route: string) => {
      const linkText = (e.nativeEvent.target as HTMLParagraphElement).innerHTML;
      const formatLinkText: string =
        linkText[0].toUpperCase() + linkText.slice(1).toLowerCase();

      trackConsumer()?.siteNavigated({
        event: {
          action_source: 'consumer web',
          activator: `Top Nav - ${formatLinkText} Link`,
          explainer: `Tracking user engagement with top navigation`,
          user_type: 'consumer',
          user_status: 'logged_in',
          event_type: 'engagement',
          invoked_by: 'consumer',
        },
        site_type: 'consumer web',
        navigation_type: 'top navigation',
        event_source_url: `${window.location.href}`,
        text: `${formatLinkText}`,
        url: `${route}`,
      });
    },
    [trackConsumer]
  );

  const onHamburgerMenuClick = useCallback(() => {
    send('TOGGLE_DRAWER');
    trackConsumer()?.elementEngaged({
      action: 'Open',
      event: {
        activator: 'Top Nav',
        explainer: 'Tracking user engagement with top navigation',
        extra_data: {
          properties: 'Hamburger Menu',
        },
        invoked_by: 'consumer',
        // @ts-ignore
        type: 'consumer',
      },
    });
  }, [send, trackConsumer]);

  return (
    <>
      <Slide
        appear={compact || false}
        direction="down"
        in={compact || !trigger}
      >
        <FixedContainer data-testid="logged-in-header" $compact={compact}>
          <StyledHeaderContainer
            $compact={compact}
            variant={isAList ? 'newDark' : 'newNude'}
          >
            <LogoLink
              onClick={onAlleLogoClick}
              aria-label="ALLE"
              to={Routes.accountDashboard}
              $compact={compact}
            >
              <AlleLogo dark={isAList && !isAListProviders} />
            </LogoLink>

            {/* mui-fixed keeps these components from shifting when drawer is opened */}
            <LinksContainer className="mui-fixed">
              <CenterLink
                onClick={(e) => onTopNavLinkClick(e, Routes.accountDashboard)}
                activeWhenNotExact
                isAList={isAList}
                isAListProviders={isAListProviders}
                to={Routes.accountDashboard}
              >
                <Typography preset="caption-L-regular">Home</Typography>
              </CenterLink>
              <CenterLink
                onClick={(e) => onTopNavLinkClick(e, Routes.accountWallet)}
                activeWhenNotExact
                isAList={isAList}
                isAListProviders={isAListProviders}
                to={Routes.accountWallet}
              >
                <Typography preset="caption-L-regular">Wallet</Typography>
              </CenterLink>
              <CenterLink
                activeWhenNotExact
                isAList={isAList}
                isAListProviders={isAListProviders}
                to={Routes.providerSearch}
                onClick={(e) => onTopNavLinkClick(e, Routes.providerSearch)}
              >
                <Typography preset="caption-L-regular">Providers</Typography>
              </CenterLink>
            </LinksContainer>

            <SimpleIconButton
              onClick={onHamburgerMenuClick}
              variant={isAList && !isAListProviders ? 'dark' : 'light'}
              color={isAList && !isAListProviders ? colors.white : colors.black}
              data-testid="open-menu-button"
              aria-label="Menu button"
            >
              <Menu />
            </SimpleIconButton>
          </StyledHeaderContainer>
        </FixedContainer>
      </Slide>
      <React.Suspense fallback={<DrawerFallback />}>
        <Drawer
          open={current.matches('open')}
          onClose={() => send('TOGGLE_DRAWER')}
          current={current}
          send={send}
        />
      </React.Suspense>
    </>
  );
};

export { LoggedInHeader };
