import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const FacebookLogoCircular: React.FunctionComponent<{
  pathFill?: string;
  className?: string;
}> = ({ pathFill, className }) => (
  <IconContainer className={className} pathFill={pathFill}>
    <SvgIcon width="21" height="20" viewBox="0 0 21 20">
      <path
        id="fb-circular-icon-path"
        d="M20.5001 10.0611C20.5001 4.53828 16.023 0.0611267 10.5001 0.0611267C4.97727 0.0611267 0.500122 4.53828 0.500122 10.0611C0.500122 15.0523 4.15696 19.1894 8.93762 19.9396L8.93762 12.9518H6.39856V10.0611H8.93762V7.858C8.93762 5.35175 10.4306 3.96738 12.7148 3.96738C13.8089 3.96738 14.9532 4.16269 14.9532 4.16269V6.62363H13.6923C12.4501 6.62363 12.0626 7.39453 12.0626 8.18535V10.0611H14.8361L14.3927 12.9518H12.0626L12.0626 19.9396C16.8433 19.1894 20.5001 15.0525 20.5001 10.0611Z"
      />
    </SvgIcon>
  </IconContainer>
);

export { FacebookLogoCircular };
