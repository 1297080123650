import { Platform } from 'react-native';
import { Theme, ThemeVariant } from '../types/theme';
import { css } from '../styled-components';

export interface Themeable {
  variant?: ThemeVariant;
}
export enum alleColors {
  black = '#111111',
  absoluteBlack = '#000000',
  richBlack = '#020202',
  ravenBlack = '#090909',
  darkTransparent = 'rgba(2, 2, 2, 0.25)',
  darkGray = '#252525',
  lightGray = '#D1D1D1',
  lightGray2 = '#f2f2f2',
  mediumGray = '#787676',
  gray1 = '#A7A9AC',
  gray50 = '#bababa',
  gray100 = '#6a6a6a',
  gray200 = '#E6E6E6',
  gray300 = '#f8f8f8',
  gray500 = '#808080',
  gray600 = '#686868',
  greyLightTransparent = 'rgba(255, 255, 255, 0.2)',
  darkTransparent1 = 'rgba(2, 2, 2, 0.5)',
  darkTransparent2 = 'rgba(2, 2, 2, 0.05)',
  white = '#ffffff',
  red = '#C53425',
  red2 = '#FFDFDB',
  green = '#008A1A',
  green2 = '#D8FFE0',
  yellow = '#EFA200',
  yellow2 = '#FFF8D2',
  bisque = '#F9F3F1',
  caramel = '#EFE1DD',
  nude = '#F5E0D8',
  nude2 = '#E0AFA4',
  nude3 = '#B57466',
  nude5 = '#C08676',
  nude6 = '#906459',
  nudeDark = '#A65E4F',
  nudeDark2 = '#824F45',
  skin = '#C98373',
  lightSkin = '#D09284',
  shellBrown = '#9A6B5E',
  gold = '#C3A45E',
  bone = '#FBF6F5',
  oldRose = '#C08676',
  sunny = '#F8D98B',
  whiteChocolate = '#F1E0D9',
  whiteChocolate2 = '#F5EBE7',
  whiteChocolate3 = '#ECDBD6',
  whiteChocolate4 = '#F7EEEB',
  palePink = 'rgba(245, 224, 216, 0.5)',
  cultured = '#FAF6F5',
  dimGray = '#696969',
  roseEbony = '#60433B',
  sonicSilver = '#747474',
  spicyMix = '#835A50',
  roseTaupe = '#906458',
  pearlBush = '#E5E0DC',
  offWhite = '#ECEAE7',
  darkSalmon = '#C08676',
  silverPink = '#CFCBCA',
  lightGrayWhite = '#F7F6F5',
  ghostWhite = '#FBFBFB',
  grayishPinkColor = '#E5E0DD',
  lightShadeGrayishWhiteColorTransparent = 'rgba(251, 251, 251, 0)',
  lightShadeGrayishWhiteColor50 = 'rgba(251, 251, 251, 0.5)',
  lightShadeGrayishWhiteColor = 'rgba(251, 251, 251, 1)',
  blackTransparent = 'rgba(25, 25, 25, 0)',
  blackColor50 = 'rgba(25, 25, 25, 0.5)',
  blackOpaque = 'rgba(25, 25, 25, 1)',
  orange = '#EF8F00',
  shadeOfRed = '#C53425',
  forestGreen = '#006714',
  linen = '#FBF7F5',
  fuscousGray = '#7B706C',
  honeydew = '#EBF9EB',
  shadeOfGreen = '#9EE09D',
  charcoalGray = '#9D9D9D',
  neutral = '#B3B0AE',
  skin100 = '#FEFCFB',
  skin200 = '#FCF7F5',
  skin300 = '#FAEFEC',
  skin400 = '#F7E8E2',
  skin500 = '#F5E0D8',
  skin600 = '#B8A8A2',
  skin700 = '#7A706C',
  skin800 = '#3D3836',
  skin900 = '#191616',
  whiteTransparent = 'rgba(255, 255, 255, 0.08)',
  successLight = '#EBF9EB',
  warningLight = '#FEF8E8',
  darkWarnigLight = '#B68C15',
  error50 = '#DC3426',
}

const {
  black,
  richBlack,
  darkTransparent,
  darkTransparent1,
  darkTransparent2,
  bone,
  darkGray,
  error50,
  forestGreen,
  gray1,
  gray100,
  gray200,
  gray300,
  green,
  green2,
  greyLightTransparent,
  lightGray,
  lightGray2,
  nude,
  nude2,
  nude3,
  nude5,
  nudeDark,
  nudeDark2,
  orange,
  red,
  skin,
  white,
  oldRose,
  whiteChocolate,
  ravenBlack,
  shellBrown,
  mediumGray,
  bisque,
  caramel,
  gold,
  neutral,
  absoluteBlack,
  successLight,
  warningLight,
  darkWarnigLight,
  lightGrayWhite,
} = alleColors;

const headerStyle = css`
  font-family: petersburg;
  font-style: normal;
`;

const bodyStyle = css`
  font-family: graphik;
  font-style: normal;
`;

const bodyBoldStyle = css`
  font-family: graphik;
  font-weight: bold;
  font-style: normal;
`;

const DefaultTheme: Theme = {
  dark: false,
  colors: {
    // TODO: move these into theme fonts (don't add more!)
    primary: darkGray,
    background: white,
    card: darkGray,
    text: darkGray,
    border: 'transparent',
    bgDark: bone,
    bgLight: darkGray,
    error: red,
    success: green,
    header: white,
    headerDark: black,
    headerMedium: gray1,
    headerLight: bone,
    headerAccent: gray1,
    bodyDark: black,
    bodyLight: bone,
    bodyGray: gray1,
    shadow: darkGray,
    drawerBackground: black,
    drawerActive: skin,
    drawerInactive: bone,
    pageCallout: skin,
    font: black,
    disclosureBackground: gray200,
  },
  accordion: {
    accordionBackground: white,
    borderColor: white,
    contentBackground: white,
    underlayColor: white,
  },
  auth: {
    forgotPasswordColor: gray1,
  },
  globalNotifications: {
    positive: {
      backgroundColor: green2,
      textColor: black,
      closeIconColor: black,
    },
    warning: {
      backgroundColor: black,
      textColor: skin,
      closeIconColor: bone,
    },
    general: {
      backgroundColor: nudeDark,
      textColor: bone,
      closeIconColor: bone,
    },
  },
  fonts: {
    header: headerStyle,
    body: {
      regular: bodyStyle,
      bold: bodyBoldStyle,
    },
    fontFamily: Platform.OS === 'web' ? "'Graphik', sans-serif" : 'graphik',
  },
  icons: {
    color: black,
    hoverBackgroundColor: black,
    hoverColor: white,
  },
  image: {
    disclaimerTextColor: white,
  },
  toggles: {
    thumbColor: white,
  },
  loadingBars: {
    backgroundColor: gray1,
    foregroundColor: black,
    iconColor: white,
  },
  segmentedControls: {
    labelFont: bodyStyle,
    primary: {
      activeLabelColor: black,
      inactiveLabelColor: gray1,
    },
    alternate: {
      activeLabelColor: white,
      inactiveLabelColor: gray1,
    },
  },
  screen: {
    backgroundColor: black,
    contentBackgroundColor: white,
  },
  getStartedScreen: {
    fontColor: white,
  },
  pages: {
    primary: {
      backgroundColor: white,
      contentColor: black,
    },
    alternate: {
      backgroundColor: black,
      contentColor: white,
    },
  },
  swiper: {
    textColor: white,
    backgroundColor: black,
    dotColor: white,
    activeDotColor: skin,
  },
  banners: {
    primary: {
      backgroundColor: black,
      headerColor: white,
      dividerColor: nude,
      detailTitleColor: skin,
      detailLabelColor: white,
    },
    alternate: {
      backgroundColor: black,
      headerColor: white,
      dividerColor: nude,
      detailTitleColor: skin,
      detailLabelColor: white,
    },
  },
  badges: {
    primary: {
      backgroundColor: bone,
      headerColor: white,
      detailTitleColor: skin,
      detailLabelColor: black,
      iconColor: skin,
      borderColor: skin,
      tierMessageColor: nude2,
      tierStatusColor: black,
    },
    alternate: {
      backgroundColor: black,
      headerColor: black,
      detailTitleColor: skin,
      detailLabelColor: white,
      iconColor: gray1,
      borderColor: gray1,
      tierMessageColor: mediumGray,
      tierStatusColor: white,
    },
  },
  tabBar: {
    labelFontFamily: Platform.OS === 'web' ? 'graphik' : 'graphik-Semibold',
    backgroundColor: white,
    activeTintColor: darkGray,
    inactiveTintColor: gray1,
    borderShadowColor: black,
  },
  feed: {
    backgroundColor: white,
    banner: {
      primary: {
        backgroundColor: bone,
        textColor: black,
        labelColor: nudeDark,
        dividerColor: skin,
      },
      alternate: {
        backgroundColor: black,
        textColor: white,
        labelColor: skin,
        dividerColor: nude,
      },
    },
    contentCards: {
      titleColor: skin,
      textColor: darkGray,
    },
    carousel: {
      carouselButtonBackgroundColor: bone,
      carouselButtonIconColor: nudeDark,
      carouselButtonBackgroundDisabledColor: gray200,
      carouselDisabledIconColor: black,
      titleColor: nudeDark,
    },
    articleCards: {
      backgroundColor: white,
      borderColor: gray200,
      breadcrumbColor: nudeDark,
      titleColor: black,
      separatorColor: skin,
      isi: {
        color: gray100,
      },
    },
    rewardCards: {
      primary: {
        titleColor: bone,
        backgroundColor: black,
        backgroundContainer: darkGray,
        textColor: skin,
      },
      alternate: {
        titleColor: skin,
        backgroundColor: bone,
        backgroundContainer: skin,
        textColor: black,
      },
    },
    offerCards: {
      primary: {
        titleColor: bone,
        backgroundColor: black,
        backgroundContainer: darkGray,
        textColor: skin,
        isi: {
          color: gray100,
        },
      },
      alternate: {
        titleColor: skin,
        backgroundColor: bone,
        backgroundContainer: skin,
        textColor: black,
        isi: {
          color: gray200,
        },
      },
    },
  },
  placeholderLoader: {
    primary: {
      border: gray200,
      foreground: gray1,
      background: gray200,
      backgroundContainer: white,
      factSheeBackground: bone,
      factSheetLoaderBackground: gray200,
      factSheetLoaderForegroundColor: gray300,
    },
    alternate: {
      border: gray200,
      foreground: gray1,
      background: gray200,
      backgroundContainer: black,
      factSheeBackground: bone,
      factSheetLoaderBackground: gray200,
      factSheetLoaderForegroundColor: gray300,
    },
  },
  wallet: {
    header: {
      primary: {
        backgroundColor: white,
        titleColor: black,
        leftIcon: nude,
      },
      alternate: {
        backgroundColor: black,
        titleColor: white,
        leftIcon: darkGray,
      },
    },
    separatorColor: gray300,
    banner: {
      primary: {
        backgroundColor: bone,
        headerColor: white,
        shadowColor: skin,
        labelColor: black,
        wheelBackgroundColor: nude,
        wheelForegroundColor: skin,
      },
      alternate: {
        backgroundColor: black,
        headerColor: white,
        shadowColor: skin,
        labelColor: white,
        wheelBackgroundColor: darkGray,
        wheelForegroundColor: skin,
      },
    },
    rewardRow: {
      backgroundColor: gray300,
      cardBackgroundColor: white,
      labelColor: skin,
      titleColor: black,
      valueBackgroundColor: bone,
      isiColor: darkGray,
    },
    giftCard: {
      label: gray1,
      warning: red,
      backgroundColor: white,
      cardBackgroundColor5000: nude,
      cardBackgroundColor7500: nude2,
      cardBackgroundColor10000: nude3,
      cardBackgroundColor30000: black,
      dividerColor: 'rgba(255,255,255,0.10)',
      valueColor5000: nudeDark2,
      valueColor10000: white,
      valueColorImage: white,
      isiColor: gray100,
      isiBackgroundColor: bone,
    },
    giftCardRow: {
      rowTitleColor: gray1,
    },
    giftCardButton: {
      backgroundColor: gray300,
      iconColor: gray1,
    },
    detail: {
      ringColor: gray300,
      sectionHeaderColor: skin,
    },
    historyItem: {
      background: gray300,
      breakpoint: gray200,
      borderColor: gray300,
    },
  },
  offerSelection: {
    backgroundColor: black,
    backgroundColorLight: white,
    textTransparent: 'rgba(255,255,255,0.7)',
    caption: skin,
    title: black,
    text: black,
    closeIcon: gray300,
    isiColor: gray100,
    textLight: white,
  },
  offerDetail: {
    banner: {
      backgroundColor: black,
      headerColor: white,
      titleColor: white,
      labelColor: skin,
      valueColor: white,
    },
    isi: {
      color: darkGray,
      backgroundColor: bone,
    },
    textColor: black,
  },
  rewardDetail: {
    banner: {
      backgroundColor: black,
      headerColor: white,
      titleColor: white,
      labelColor: skin,
      valueColor: white,
    },
    isi: {
      color: darkGray,
      backgroundColor: bone,
    },
    textColor: black,
  },
  article: {
    banner: {
      backgroundColor: white,
      titleColor: black,
      contentTag: nudeDark,
    },
    content: {
      backgroundColor: white,
      textColor: black,
    },
    questions: {
      accordionBackground: white,
      borderColor: nude,
      contentBackground: white,
      underlayColor: white,
    },
  },
  treatmentGuide: {
    banner: {
      backgroundColor: black,
      titleColor: white,
    },
    content: {
      backgroundColor: white,
      breadcrumbColor: nudeDark,
      contentBackgroundColor: bone,
      borderColor: gray200,
      textColor: black,
      heading1Color: black,
      heading2Color: skin,
      factLabelColor: nudeDark,
      sidebarColor: nude3,
      iconColor: nudeDark,
      ctaColor: nudeDark,
      labelColor: nudeDark,
      factDescriptionColor: black,
      arrowIcon: black,
      factSheetBackground: white,
    },
    faq: {
      accordionBackground: white,
      borderColor: nude,
      contentBackground: white,
      underlayColor: white,
    },
    isi: {
      color: gray100,
    },
  },
  legal: {
    backgroundColor: gray300,
    textColor: black,
    bulletColor: black,
    accordion: {
      accordionBackground: gray300,
      borderColor: gray300,
      contentBackground: gray300,
      underlayColor: gray300,
    },
  },
  buttons: {
    contained: {
      primary: {
        labelColor: white,
        backgroundColor: black,
        labelDisabledColor: white,
        backgroundDisabledColor: gray200,
      },
      alternate: {
        labelColor: black,
        backgroundColor: white,
        labelDisabledColor: gray1,
        backgroundDisabledColor: gray300,
      },
    },
    outline: {
      primary: {
        labelColor: black,
        backgroundColor: white,
        borderColor: black,
        disabledBorderColor: gray1,
        labelDisabledColor: gray1,
        backgroundDisabledColor: gray300,
      },
      alternate: {
        labelColor: white,
        backgroundColor: black,
        borderColor: white,
        disabledBorderColor: gray1,
        labelDisabledColor: gray1,
        backgroundDisabledColor: gray300,
      },
    },
    transparent: {
      primary: {
        labelColor: black,
        labelDisabledColor: gray1,
      },
      alternate: {
        labelColor: white,
        labelDisabledColor: gray1,
      },
    },
    close: {
      backgroundColor: gray300,
      labelColor: black,
      labelDisabledColor: gray1,
    },
    small: {
      backgroundColor: skin,
      labelColor: white,
    },
    showPassword: {
      labelColor: black,
    },
    iconCircle: {
      backgroundColor: 'rgba(248,248,248,0.1)',
      labelColor: white,
    },
  },
  profile: {
    profileRows: {
      primary: {
        backgroundColor: gray300,
        borderColor: 'transparent',
        labelColor: black,
      },
      alternate: {
        backgroundColor: 'transparent',
        borderColor: gray200,
        labelColor: black,
      },
    },
    profileTier: {
      member: {
        backgroundColor: bone,
        iconColor: shellBrown,
        iconBackgroundColor: caramel,
        subtitle: mediumGray,
        title: ravenBlack,
        progressText: ravenBlack,
        perksText: ravenBlack,
        trackerSubtitle: ravenBlack,
      },
      aList: {
        backgroundColor: black,
        iconColor: gold,
        subtitle: `${white}B3`,
        iconBackgroundColor: `${white}14`,
        gradient: gold,
        title: white,
        progressText: gold,
        perksText: ravenBlack,
        trackerSubtitle: neutral,
      },
    },
    personalInformationRows: {
      placeholderTextColor: black,
    },
  },

  referFriend: {
    title: absoluteBlack,
    subtitle: ravenBlack,
    iconColor: absoluteBlack,
    iconBackgroundColor: caramel,
    alternateIconBackgroundColor: ravenBlack,
    alternateIconColor: white,
    pillBorder: skin,
    chipBorder: shellBrown,
    referralStatus: {
      awaitingTransaction: {
        textColor: darkWarnigLight,
        borderColor: darkWarnigLight,
        backgroundColor: warningLight,
      },
      expired: {
        textColor: mediumGray,
        borderColor: mediumGray,
        backgroundColor: lightGrayWhite,
      },
      limitExceeded: {
        textColor: mediumGray,
        borderColor: mediumGray,
        backgroundColor: lightGrayWhite,
      },
      completed: {
        textColor: green,
        borderColor: green,
        backgroundColor: successLight,
      },
    },
  },

  dialogCard: {
    backgroundColor: bone,
    labelColor: skin,
  },
  tray: {
    backgroundColor: white,
    overlayColor: black,
    gestureIndicatorColor: gray200,
    headerColor: black,
    textColor: black,
  },
  inputs: {
    multipleChoice: {
      choiceBackgroundColor: gray300,
      checkColor: black,
    },
    datePicker: {
      errorColor: red,
      placeholderColor: black,
    },
    textInputDEPRECATED: {
      titleColor: gray1,
      messageColor: skin,
      inputColor: black,
      errorColor: red,
      successColor: green,
      placeholderColor: gray1,
    },
    textInput: {
      primary: {
        backgroundColor: white,
        cursorColor: black,
        errorColor: red,
        focusedBackgroundColor: gray300,
        placeholderColor: gray1,
        secureInputPromptColor: black,
        successColor: green,
        underlineColor: gray200,
        valueColor: black,
      },
      alternate: {
        backgroundColor: black,
        cursorColor: white,
        errorColor: red,
        focusedBackgroundColor: black,
        placeholderColor: gray1,
        secureInputPromptColor: white,
        successColor: green,
        underlineColor: gray200,
        valueColor: white,
      },
    },
    onboardingTextInput: {
      backgroundColor: gray200,
      borderColor: gray1,
    },
    switchInput: {
      trackColor: skin,
      trackDisabledColor: gray1,
      thumbColor: white,
      titleColor: black,
      nameColor: black,
      labelColor: gray1,
    },
  },
  separator: {
    color: gray300,
  },
  header: {
    primary: {
      backgroundColor: white,
      titleColor: black,
      leftIcon: {
        backgroundColor: gray300,
        iconColor: black,
      },
      rightIcon: {
        backgroundColor: gray300,
        iconColor: black,
      },
    },
    providerHeader: {
      backgroundColor: bone,
      titleColor: black,
      leftIcon: {
        backgroundColor: 'rgba(248,248,248,0.1)',
        iconColor: black,
      },
      rightIcon: {
        backgroundColor: 'rgba(245, 224, 216,0.5)',
        iconColor: black,
      },
    },
    alternate: {
      backgroundColor: black,
      titleColor: white,
      leftIcon: {
        backgroundColor: 'rgba(248,248,248,0.1)',
        iconColor: white,
      },
      rightIcon: {
        backgroundColor: 'rgba(248,248,248,0.1)',
        iconColor: white,
      },
    },
  },
  molecules: {
    tableCellView: {
      titleColor: black,
      descriptionColor: gray1,
      separatorColor: gray300,
    },
  },
  providerSearch: {
    headerColor: bone,
    accentColor: nude3,
    searchBarBackground: bone,
    geoIconColor: gray100,
    searchBarText: white,
    currentLocation: gray100,
    searchPlaceholderText: gray100,
    searchIconColor: oldRose,
    closeIconColor: black,
    filterButtonBackgroundColor: caramel,
  },
  addGiftCard: {
    headerColor: white,
    explainerColor: 'rgba(255, 255, 255, 0.5)',
    inputBackgroundColor: darkGray,
    inputColor: white,
    placeholderColor: 'rgba(255, 255, 255, 0.2)',
    inputUnderlineColor: black,
  },
  checkIn: {
    ringColor: nude3,
    ringTextColor: white,
    titleColor: nude3,
    subtitleColor: white,
    termsColor: white,
    isiColor: lightGray,
    spinnerColor: nude3,
    loadingTitleColor: white,
    disclaimerBorder: nude,
    disclaimerBackground: 'rgba(245, 224, 216, 0.4)',
    countdown: {
      section: {
        backgroundColor: darkGray,
        headerColor: nude2,
        numberColor: white,
        redBorderColor: red,
      },
    },
    walletSummary: {
      backgroundColor: darkGray,
      headerColor: nude2,
      item: {
        descriptionColor: white,
        numberColor: white,
      },
    },
  },
  learnMore: {
    backgroundColor: black,
    titleColor: skin,
    contentColor: white,
  },
  ourBrands: {
    backgroundColor: white,
    header: {
      titleColor: nudeDark,
      subtitleColor: black,
    },
    treatmentCards: {
      borderColor: gray200,
      titleColor: nudeDark,
      separatorColor: skin,
    },
  },
  discover: {
    backgroundColor: white,
    header: {
      titleColor: nudeDark,
      subtitleColor: black,
    },
    articleCards: {
      backgroundColor: white,
      borderColor: gray200,
      titleColor: nudeDark,
      separatorColor: skin,
      isi: {
        color: gray100,
      },
    },
  },
  providers: {
    searchFilter: {
      backgroundColor: white,
      subtitleColor: mediumGray,
      checklist: {
        checkboxBreak: gray200,
        checkboxBorder: ravenBlack,
        checkboxFillChecked: shellBrown,
        checkboxFillUnchecked: gray100,
        checkboxText: black,
        checkboxIcon: white,
        checkboxSubtitle: gray100,
      },
      themeBreak: gray200,
      stepperButtonActive: shellBrown,
      stepperButtonInactive: gray300,
      stepperBackground: gray300,
      stepperButtonTextActive: white,
      stepperButtonTextInactive: black,
      flowButtonBackgroundActive: shellBrown,
      flowButtonBackgroundInactive: gray300,
      flowButtonTextActive: white,
      flowButtonTextInactive: black,
      disabledFilterIconColor: whiteChocolate,
      footer: {
        clearButton: shellBrown,
        borderColor: `${ravenBlack}14`,
        shadowColor: absoluteBlack,
        backgroundColor: white,
      },
    },
    contactInformation: nude5,
    contactDistance: 'rgba(2, 2, 2, 0.5)',
    list: {
      backgroundColor: bone,
      selectedBackgroundColor: nude,
      textColor: darkGray,
      locationIconColor: gray1,
      badge: {
        backgroundColor: bone,
      },
      providerCard: {
        borderLeftColor: nude3,
      },
    },
    details: {
      navigationTitleTextColor: white,
      navigationIconColor: white,
      navigationIconBackgroundColor: greyLightTransparent,
      ctaBackground: white,
      imageGalleryBackground: richBlack,
      header: {
        buttonBackgroundColor: 'rgba(255, 255, 255, 0.15)',
        buttonTextColor: white,
        badgeTextColor: mediumGray,
        providerNameTextColor: white,
        imagesCountTextColor: white,
        backButtonIconColor: white,
        backButtonBackgroundColor: darkTransparent,
        avatarBorder: white,
        badgeBackgroundColor: lightGrayWhite,
      },
      connect: {
        borderColor: 'rgba(9, 9, 9, 0.08)',
        iconColor: mediumGray,
        caretColor: ravenBlack,
        disclaimerTextColor: mediumGray,
      },
      treatmentsProducts: {
        disclosureTextColor: darkTransparent1,
        lineColor: darkTransparent2,
        priceTextColor: oldRose,
        backgroundColor: bisque,
        titleFactSheetTextColor: nude5,
        descriptionFactSheetTextColor: black,
        shadowColor: black,
        circleBackgroundColor: white,
        factSheetStatusIndicatorColor: shellBrown,
        tabTextColor: mediumGray,
        selectedTabTextColor: richBlack,
        tabCountColor: nude5,
        selectedTabHighlightColor: nude5,
      },
      practitioner: {
        backgroundColor: lightGray,
        certificationTitleColor: nude3,
        subTitleColor: gray1,
        list: {
          linearGradient: [
            'rgba(255, 255, 255, 0.75)',
            'rgba(255, 255, 255, 0.75)',
          ],
          color: nude3,
        },
      },
      officeHours: {
        workDayBorderColor: lightGray,
        isi: darkGray,
        mapOverlayColor: `${white}F7`,
        mapOverlayTextColor: nude5,
        mapOverlayIconBackgroundColor: bisque,
        mapBorderColor: lightGray2,
      },
      requestForm: {
        termsAndConditionsTextColor: gray100,
        success: {
          bodyTextColor: gray100,
          providerInfoBackgroundColor: lightGray,
        },
      },
    },
  },
  layout: {
    singleInput: {
      subtitleColor: gray100,
    },
  },
  helpLinks: {
    borderColor: lightGray,
  },
  toast: {
    alert: {
      backgroundColor: orange,
      iconColor: black,
      textColor: black,
    },
    error: {
      backgroundColor: error50,
      iconColor: white,
      textColor: white,
    },
    success: {
      backgroundColor: forestGreen,
      iconColor: white,
      textColor: white,
    },
  },
};

export { DefaultTheme };
