export type OneTrustGeoMiddlewarePayload = {
  obj: {
    context: {
      consent: {
        categoryPreferences: object;
        geo?: {
          state?: string;
          country?: string;
        };
      };
    };
  };
};

const oneTrustGeoMiddleware = ({
  payload,
  next,
}: {
  payload: OneTrustGeoMiddlewarePayload;
  next: (payload: object) => void;
}) => {
  const geo = (window as any)?.OneTrust?.getGeolocationData();

  if (payload?.obj) {
    // @ts-ignore
    payload.obj.context.consent = {
      ...payload.obj.context?.consent,
      geo,
    };
  }

  next(payload);
};

export { oneTrustGeoMiddleware };
