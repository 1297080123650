import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Oval } from '../components/loaders/oval';
import { colors } from '../core/colors';
import { usePrevious } from '../hooks';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  background-color: ${(props) =>
    props.theme?.loadingBars?.backgroundColor || '#3a3a3a'};
  border-radius: 4px;
  overflow: hidden;
`;

const moveX = (from: number, to: number) => keyframes`
  0% {
    transform: translateX(${-100 + from}%);
  }
  100% {
    transform: translateX(${-100 + to}%);
  }
`;

export interface ProgressProps {
  prevPercentDone: number;
  percentDone: number;
  durationMs: number;
}

const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: ${(props) =>
    props.theme?.loadingBars?.foregroundColor || colors.black};
  animation: ${({ prevPercentDone, percentDone }: ProgressProps) =>
      moveX(prevPercentDone, percentDone)}
    ${({ durationMs }: ProgressProps) => durationMs}ms linear;
  animation-fill-mode: forwards;
`;

const OvalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${(props) => props.theme?.loadingBars?.iconColor || colors.white};
  }
`;

export interface LoadingBarProps {
  percentDone?: number;
  durationMs?: number;
}

const LoadingBar: React.FunctionComponent<LoadingBarProps> = ({
  percentDone = 100,
  durationMs = 2000,
  ...forwardProps
}) => {
  const prevPercentDone = usePrevious(percentDone) || 0;
  return (
    <Container {...forwardProps}>
      <Progress
        className="progress"
        prevPercentDone={prevPercentDone}
        percentDone={percentDone}
        durationMs={durationMs}
      />
      <OvalContainer>
        <Oval />
      </OvalContainer>
    </Container>
  );
};

export { LoadingBar };
