import React from 'react';
import { useCookies } from 'react-cookie';

import { NotificationContext } from '../notificationBar/notificationContext';

export interface UseMaintenanceNotificationOptions {
  cookieName: string;
  isMaintenanceEnabled?: boolean;
  bannerCopy?: string;
  size?: 'medium' | 'large';
}

const useMaintenanceNotification = ({
  cookieName,
  isMaintenanceEnabled,
  bannerCopy,
  size,
}: UseMaintenanceNotificationOptions) => {
  const [cookies, setCookie] = useCookies([cookieName]);
  const { addNotification } = React.useContext(NotificationContext);

  const hasSeenNotificationBar = cookies[cookieName];

  React.useEffect(() => {
    if (!hasSeenNotificationBar && isMaintenanceEnabled && bannerCopy) {
      addNotification({
        id: 'scheduled-maintenance',
        text: <strong>{bannerCopy}</strong>,
        variant: 'general',
        liveFor: 5,
        size: size,
      });

      setCookie(cookieName, 'true', {
        maxAge: 3600,
        sameSite: true,
        path: '/',
      });
    }
  }, [
    addNotification,
    setCookie,
    hasSeenNotificationBar,
    cookieName,
    isMaintenanceEnabled,
    bannerCopy,
    size,
  ]);
};

export { useMaintenanceNotification };
