const green = {
  100: '#E5F3E8',
  200: '#BFE2C6',
  300: '#80C58C',
  400: '#40A753',
  500: '#008A1A',
  600: '#006813',
  700: '#00450D',
  800: '#002306',
  900: '#000E03',
};

export { green };
