//format phone number in payload context
function formatContextPhoneNumber(payload: any) {
  const context = payload.obj.context;
  const index = context?.externalIds?.findIndex(
    // @ts-ignore
    (externalId) => externalId.type === 'phone'
  );
  if (typeof index === 'number' && index > -1) {
    context.externalIds[index].id = context.externalIds[index].id.replace(
      /\D/g,
      ''
    );
  }
}

// format phone numbers in properties of payload
function formatPhoneNumberProps(props: any) {
  if (props) {
    props.phone = props.phone?.replace(/\D/g, '');
    props.alle_phone = props.alle_phone?.replace(/\D/g, '');
  }
}

function formatPhoneNumberMiddleware({ payload, next }: any) {
  const type = payload.obj.type;
  if (type === 'identify') {
    // phone numbers are in 'traits' for IDENTIFY calls
    const traits = payload.obj.traits;
    formatPhoneNumberProps(traits);
    formatContextPhoneNumber(payload);
  }

  if (type === 'track') {
    // phone numbers are in 'properties' for TRACK calls
    const properties = payload.obj.properties;
    formatPhoneNumberProps(properties);
    formatContextPhoneNumber(payload);
  }

  next(payload);
}

export { formatPhoneNumberMiddleware };
