const graphQLProxyUrls: { [key: string]: any } = {
  local: 'http://localhost:3000/graphql',
  dev: 'https://api.dev.alle.com/graphql',
  stage: 'https://api.stage.alle.com/graphql',
  demo: 'https://api.demo.alle.com/graphql',
  production: 'https://api.alle.com/graphql',
};

const oktaConfigs: { [key: string]: any } = {
  local: {
    issuer: 'https://auth.preview.stage.alle.com/oauth2/ausxct20afsN0Sc0k1d6',
    url: 'https://auth.preview.stage.alle.com',
    clientId: '0oawnncye2qqdaBpd1d6',
    nativeClientId: '0oawnn2yomQa94e7P1d6',
  },
  dev: {
    issuer: 'https://auth.preview.dev.alle.com/oauth2/ausxddruq1hRiUjnt1d6',
    url: 'https://auth.preview.dev.alle.com',
    clientId: '0oaxdgttkXb3WEwz51d6',
    nativeClientId: '0oaxdech4YPA6av1M1d6',
  },
  stage: {
    issuer: 'https://auth.preview.stage.alle.com/oauth2/ausxct20afsN0Sc0k1d6',
    url: 'https://auth.preview.stage.alle.com',
    clientId: '0oawnncye2qqdaBpd1d6',
    nativeClientId: '0oawnn2yomQa94e7P1d6',
  },
  demo: {
    issuer: 'https://auth.demo.alle.com/oauth2/aus1ji8l2jlUPnlDV0h8',
    url: 'https://auth.demo.alle.com',
    clientId: '0oa1hovbmybJ4Il2a0h8',
    nativeClientId: '0oa1llbgdt8maCUIi0h8',
  },
  production: {
    issuer: 'https://auth.alle.com/oauth2/aus5p3vkqpXlJ9Np0357',
    url: 'https://auth.alle.com',
    clientId: '0oa1al7ujj4QiWwRc357',
    nativeClientId: '0oa61b4q688EOrCYY357',
  },
};

const userGroups = {
  practice: 'alle-practices',
  admin: 'alle-admins',
};

const __PROD__ = process.env.NODE_ENV === 'production';
const __DEV__ = process.env.NODE_ENV === 'development';
const __TEST__ = process.env.NODE_ENV === 'test';

export {
  userGroups,
  graphQLProxyUrls,
  oktaConfigs,
  __PROD__,
  __TEST__,
  __DEV__,
};
