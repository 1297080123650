import { nude } from './nude';
import { skin } from './skin';
import { bone } from './bone';
import { grey, gray } from './grey';
import { yellow } from './yellow';
import { green } from './green';
import { red } from './red';
import { blue } from './blue';
import { brown } from './brown';

// TODO: create a theme follow Material UI documentation
// https://material-ui.com/customization/palette/#customization

const alleColors = {
  nude,
  skin,
  bone,
  grey,
  gray,
  yellow,
  green,
  red,
  blue,
  brown,
  ravenBlack: '#090909',
  white: '#ffffff',
  black: '#000000',
};

export { alleColors };
