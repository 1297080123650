import React from 'react';
import {
  AccessibilityRole,
  Platform,
  StyleProp,
  TextStyle,
} from 'react-native';
import { CSSObject } from 'styled-components';

import {
  TypographyBaseVariant,
  TypographyBaseSize,
  TypographyBaseWeight,
  TypographyBaseFontFamily,
  TypographyPreset,
  TypographyComponent,
  Responsify,
} from './types';

import {
  TypographyBase,
  TypographyBaseProps,
  typographyBaseStyles,
} from './typographyBase';

interface TypographyBaseStyleProps {
  variant: TypographyBaseVariant;
  size: TypographyBaseSize;
  weight: TypographyBaseWeight;
  fontFamily: TypographyBaseFontFamily;
}

const typographyBasePropsMap: Record<
  TypographyPreset,
  TypographyBaseStyleProps
> = {
  'headline-XL-graphik': {
    variant: 'headline',
    size: 'XL',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'headline-XL-petersburg': {
    variant: 'headline',
    size: 'XL',
    weight: 'regular',
    fontFamily: 'petersburg',
  },
  'headline-L-graphik': {
    variant: 'headline',
    size: 'L',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'headline-L-petersburg': {
    variant: 'headline',
    size: 'L',
    weight: 'regular',
    fontFamily: 'petersburg',
  },
  'headline-M-graphik': {
    variant: 'headline',
    size: 'M',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'headline-M-petersburg': {
    variant: 'headline',
    size: 'M',
    weight: 'regular',
    fontFamily: 'petersburg',
  },
  'headline-S-graphik': {
    variant: 'headline',
    size: 'S',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'headline-S-petersburg': {
    variant: 'headline',
    size: 'S',
    weight: 'regular',
    fontFamily: 'petersburg',
  },
  'body-XL-bold': {
    variant: 'body',
    size: 'XL',
    weight: 'bold',
    fontFamily: 'graphik',
  },
  'body-XL-regular': {
    variant: 'body',
    size: 'XL',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'body-L-bold': {
    variant: 'body',
    size: 'L',
    weight: 'bold',
    fontFamily: 'graphik',
  },
  'body-L-regular': {
    variant: 'body',
    size: 'L',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'body-M-bold': {
    variant: 'body',
    size: 'M',
    weight: 'bold',
    fontFamily: 'graphik',
  },
  'body-M-regular': {
    variant: 'body',
    size: 'M',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'body-S-bold': {
    variant: 'body',
    size: 'S',
    weight: 'bold',
    fontFamily: 'graphik',
  },
  'body-S-regular': {
    variant: 'body',
    size: 'S',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'caption-L-bold': {
    variant: 'caption',
    size: 'L',
    weight: 'bold',
    fontFamily: 'graphik',
  },
  'caption-L-regular': {
    variant: 'caption',
    size: 'L',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'caption-M-bold': {
    variant: 'caption',
    size: 'M',
    weight: 'bold',
    fontFamily: 'graphik',
  },
  'caption-M-regular': {
    variant: 'caption',
    size: 'M',
    weight: 'regular',
    fontFamily: 'graphik',
  },
  'caption-S-bold': {
    variant: 'caption',
    size: 'S',
    weight: 'bold',
    fontFamily: 'graphik',
  },
  'caption-S-regular': {
    variant: 'caption',
    size: 'S',
    weight: 'regular',
    fontFamily: 'graphik',
  },
};

export const convertPresetToBaseProps = (
  preset: Responsify<TypographyPreset>
) => {
  let baseProps: Omit<Omit<TypographyBaseProps, 'component'>, 'children'> = {};
  if (typeof preset === 'string') {
    baseProps = typographyBasePropsMap[preset];
  } else {
    baseProps = {
      variant: {
        s: typographyBasePropsMap[preset.s].variant,
        m: preset.m ? typographyBasePropsMap[preset.m].variant : undefined,
        l: preset.l ? typographyBasePropsMap[preset.l].variant : undefined,
      },
      size: {
        s: typographyBasePropsMap[preset.s].size,
        m: preset.m ? typographyBasePropsMap[preset.m].size : undefined,
        l: preset.l ? typographyBasePropsMap[preset.l].size : undefined,
      },
      weight: {
        s: typographyBasePropsMap[preset.s].weight,
        m: preset.m ? typographyBasePropsMap[preset.m].weight : undefined,
        l: preset.l ? typographyBasePropsMap[preset.l].weight : undefined,
      },
      fontFamily: {
        s: typographyBasePropsMap[preset.s].fontFamily,
        m: preset.m ? typographyBasePropsMap[preset.m].fontFamily : undefined,
        l: preset.l ? typographyBasePropsMap[preset.l].fontFamily : undefined,
      },
    };
  }
  return baseProps;
};

const convertToMobileStyleSheetProps = (props: any) => {
  if (!props) return;

  if (props.css) {
    if (Platform.OS !== 'web') {
      console.warn('Please use the `style` prop instead of `css` for native');
    }
    props.style = props.css;
    delete props.css;
  }

  return props;
};

export const typographyStyles = (preset: Responsify<TypographyPreset>) => {
  return typographyBaseStyles(convertPresetToBaseProps(preset));
};

export interface TypographyProps {
  preset: Responsify<TypographyPreset>;
  component?: TypographyComponent;
  children: string | React.ReactNode;
  className?: string;
  ['data-testid']?: string;
  'aria-label'?: string;
  'aria-describedby'?: string;
  'aria-labelledby'?: string;
  css?: StyleProp<TextStyle> | CSSObject | string;
  // FullStory un/mask outlet
  fsClass?: string;
  // for native only use
  accessible?: boolean;
  accessibilityLabel?: string;
  accessibilityRole?: AccessibilityRole;
  testID?: string;
  numberOfLines?: number;
  style?: StyleProp<TextStyle>; // NOTE: passing in css prop will replace this replace (see convertToMobileStyleSheetProps())
}

/**
 * Typography is the core atom of Alle Design System in which all other components should use internally for rendering text.
 *
 * The supported presets should always match up exactly with the font variations found in the Alle Design System.
 *
 * This Typography component is implemented with the lower-level [TypographyBase](https://storybook.stage.alle.com/?path=/story/alle-design-system-atoms-typographybase--typography) component. If you find yourself needing to style text differently than what is supported by this component, please double check if there is something wrong with the designs.
 *
 * ### Accessibility
 *
 * Text formatting does not always line up with structural hierarchy of a document. For this reason, the HTML tag (h1, h2, p, etc) is set separately than the style preset. Always be sure to use the `component` best suited for the indended purpose of the text:
 *
 * ```
 * <Typography
 *   preset="body-S-regular"
 *   component="h1"
 * />
 * ```
 *
 * ### Responsiveness
 *
 * Designs will sometimes show text being smaller on mobile than it is on tablet or desktop, usually this is on a case-by-case basis. The Typography component has this built in by supporting not only a single preset, but also an object with a preset selected for each device size:
 *
 * ```
 * <Typography
 *   preset={{
 *     s: 'body-S-regular', // small | web - mobile | mobile - < 480w
 *     m: 'body-M-regular', // medium | web - tablet | mobile - < 560w | optional
 *     l: 'body-L-regular', // // large i.| web - desktop | mobile - > 560w | optional
 *   }}
 * />
 * ```
 *
 */
const Typography = ({
  preset,
  component,
  children,
  className,
  ...props
}: TypographyProps) => {
  switch (Platform.OS) {
    case 'web':
      return (
        <TypographyBase
          {...convertPresetToBaseProps(preset)}
          component={component}
          className={className}
          data-testid={props['data-testid'] || props.testID}
          aria-label={props['aria-label'] || props.accessibilityLabel}
          aria-describedby={
            props['aria-describedby'] || props.accessibilityRole
          }
          aria-labelledby={props['aria-labelledby']}
        >
          {children}
        </TypographyBase>
      );
    default:
      return (
        <TypographyBase
          {...convertPresetToBaseProps(preset)}
          component={component}
          className={className}
          testID={props.testID}
          accessible={props.accessible}
          accessibilityLabel={props.accessibilityLabel}
          accessibilityRole={props.accessibilityRole}
          fsClass={props.fsClass}
          {...convertToMobileStyleSheetProps(props)}
        >
          {children}
        </TypographyBase>
      );
  }
};

export { Typography };
