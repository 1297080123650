import React from 'react';
import {
  ButtonSelect,
  ButtonSelectItem,
} from '@allergan-data-labs/alle-elements-button';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { Box } from '@allergan-data-labs/alle-elements-layout';
import { NavigationItemLinks } from './headerDrawerItem';
import { useHeaderColors } from './useHeaderColors';

type HeaderSelectProps = NavigationItemLinks & {
  isLoggedIn?: boolean;
};

const HeaderSelect = ({
  title,
  items,
  hideOnLoggedInNav,
  isLoggedIn,
}: HeaderSelectProps) => {
  const { Link } = useEnvironmentContext();
  const { navLinkColor, navLinkHoverColor } = useHeaderColors({
    isLoggedIn,
  });

  if (isLoggedIn && hideOnLoggedInNav) {
    return null;
  }

  return (
    <Box
      sx={{
        '> div > button': {
          padding: 0,
          height: '32px',
          color: navLinkColor,
          backgroundColor: 'transparent',
          fontSize: '4',
          div: {
            gap: 2,
          },
          _hover: {
            backgroundColor: 'transparent',
            color: navLinkHoverColor,
          },
          _active: {
            backgroundColor: 'transparent',
            _hover: {
              backgroundColor: 'transparent',
            },
          },
        },
      }}
    >
      <ButtonSelect label={title}>
        {items?.map((item) => {
          return (
            <ButtonSelectItem key={item.id}>
              <Link
                href={item.href}
                data-testid={`header-nav-${item.id}`}
                fontSize="14px"
                fontWeight={item.isActive ? '600' : '400'}
                maxWidth="234px"
                padding={1}
                isStandalone
                variant="action"
                _hover={{
                  textDecoration: 'none',
                }}
              >
                {item.title}
              </Link>
            </ButtonSelectItem>
          );
        }) || []}
      </ButtonSelect>
    </Box>
  );
};

export { HeaderSelect };
