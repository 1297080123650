import { createClient } from 'contentful';
const environment = process.env.REACT_APP_CMS_ENVIRONMENT || 'stage';
const spaceId = process.env.REACT_APP_CMS_SPACE_ID || 'owuwz5p532r4';
const CDAToken =
  process.env.REACT_APP_CMS_CDA ||
  'Lco7pz62fNrnvz9BDPOoy9Uia9o6y_p0HqvSurj1gp0';
const host = process.env.REACT_APP_CMS_HOST || 'cdn.contentful.com';

const contenfulRestClient = createClient({
  space: spaceId,
  environment: environment,
  accessToken: CDAToken,
  host: host,
});

export default contenfulRestClient;
