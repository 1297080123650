import styled from 'styled-components';
import { ThemeTypes } from '../components/theme';
import { colors } from '../core/colors';

export interface IconContainerProps extends ThemeTypes {
  pathFill?: string;
  width?: string;
  height?: string;
  keepPathFill?: boolean; // Prevent overriding icon's original path fill
  dataTestId?: string;
}

const IconContainer = styled.span.attrs((props: IconContainerProps) => ({
  'data-testid': props.dataTestId,
}))`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${(props: IconContainerProps) => (props.width ? props.width : '22px')};
  height: ${(props: IconContainerProps) =>
    props.height ? props.height : '22px'};

  .MuiSvgIcon-root {
    width: ${(props: IconContainerProps) =>
      props.width ? props.width : '22px'};
    height: ${(props: IconContainerProps) =>
      props.height ? props.height : '22px'};

    ${(props: IconContainerProps) =>
      props.keepPathFill && !props.pathFill
        ? ''
        : `
      path {
        fill:
          ${
            props.pathFill
              ? props.pathFill
              : props.dark
              ? colors.white
              : colors.black
          };
      }`}
  }
`;

export { IconContainer };
