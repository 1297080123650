import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';
import { colors } from '../colors';

const StyledIconButton = styled(({ variant, color, ...forwardProps }) => (
  <IconButton {...forwardProps} />
))`
  &.MuiIconButton-root {
    padding: 7px;
    margin: -7px;

    ${above.desktop`
      &:hover {
        background-color: ${(props: SimpleIconButtonProps & Theme) => {
          return props.theme.iconButton[props.variant || 'light']
            .hoverBackgroundColor;
        }};
        & .MuiSvgIcon-root path {
          fill: ${(props: SimpleIconButtonProps & Theme) => {
            return props.theme.iconButton[props.variant || 'light'].hoverColor;
          }};
        }
      }
    `}
  }
  && .MuiSvgIcon-root path {
    fill: ${(props) => props.color || props.theme?.icons?.color};
  }
`;

export interface SimpleIconButtonProps {
  variant?: 'light' | 'dark';
  onClick?: () => void;
  color?: string;
  type?: string;
  children: React.ReactNode;
  className?: string;
  ['data-testid']?: string;
  disabled?: boolean;
  ['aria-label']?: string;
}

interface Theme {
  theme: {
    iconButton: {
      light: {
        hoverBackgroundColor: string;
        hoverColor: string;
      };
      dark: {
        hoverBackgroundColor: string;
        hoverColor: string;
      };
    };
  };
}

const themes = {
  iconButton: {
    light: {
      hoverBackgroundColor: colors.black,
      hoverColor: colors.white,
    },
    dark: {
      hoverBackgroundColor: colors.white,
      hoverColor: colors.black,
    },
  },
};

/**
 * @deprecated You can probably use IconButton from @allergan-data-labs/alle-elements-button instead
 */
const SimpleIconButton: React.FunctionComponent<SimpleIconButtonProps> = ({
  variant = 'light',
  ...forwardProps
}) => {
  return (
    <ThemeProvider theme={themes as any}>
      <StyledIconButton
        variant={variant}
        {...forwardProps}
        disableFocusRipple
        disableTouchRipple
        disableRipple
      />
    </ThemeProvider>
  );
};

export { SimpleIconButton };
