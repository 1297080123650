import { Dispatch, SetStateAction, useState, useCallback } from 'react';

export const useSessionStorage = <
  T extends
    | boolean
    | number
    | Record<string | number | symbol, unknown>
    | string
    | Array<unknown>
    | null
    | undefined
>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.sessionStorage.getItem(key);

      if (value) {
        return JSON.parse(value);
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      const newValue = typeof value === 'function' ? value(storedValue) : value;
      try {
        window.sessionStorage.setItem(key, JSON.stringify(newValue));
      } catch (err) {
        console.error('useSessionStorage.setItem.error', err);
      }
      setStoredValue(newValue);
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
};
