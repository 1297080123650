const getEnvConfig = (
  configs: {
    [key: string]: any;
  },
  environmentKey?: string
) => {
  if (!configs.production || !configs.stage) {
    throw new Error(
      `No default environment found, please add a 'production' and 'stage' key`
    );
  }

  if (environmentKey && configs[environmentKey]) {
    return configs[environmentKey];
  }

  return configs.stage;
};

export { getEnvConfig };
