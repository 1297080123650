import { useQuery as useReactQuery } from '@tanstack/react-query';
import { EntryCollection } from 'contentful';

import { IBlockFooterFields } from '@packages/core-contentful-rest';
import { logger } from '@packages/core-datadog-logging/src/logger';
import contentfulRestClient from '@packages/core-contentful-rest/src/client/contentfulRestClient';

const FooterCodeID = {
  defaultConsumerFooter: 'defaultConsumerFooter',
} as const;

type UseCMSFooterQueryArgs = {
  codeId: string;
  skip?: boolean;
};

const useCMSFooterQuery = ({ codeId, skip }: UseCMSFooterQueryArgs) => {
  const contenfulFooterQuery = {
    'fields.codeId': codeId,
    include: 2,
    content_type: 'blockFooter',
  };

  const {
    data: contentfulFooter,
    isFetching: isLoadingContentfulFooter,
    error: contentfulFooterError,
  } = useReactQuery({
    queryKey: [JSON.stringify(contenfulFooterQuery)],
    queryFn: (): Promise<EntryCollection<IBlockFooterFields>> =>
      contentfulRestClient.getEntries(contenfulFooterQuery),
    enabled: !skip,
    refetchOnWindowFocus: false,
  });

  if (contentfulFooterError) {
    logger.error(`footer → profile → useCMSFooterQuery--error`, {
      error: `[Contentful error]: Message: ${contentfulFooterError}`,
    });
  }

  const contentfulFooterData = contentfulFooter?.items[0]?.fields ?? undefined;

  return {
    data: contentfulFooterData,
    isLoading: isLoadingContentfulFooter,
    hasError: !!contentfulFooterError,
  };
};

export { useCMSFooterQuery, FooterCodeID };
