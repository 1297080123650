import React, { Component } from 'react';
import styled from 'styled-components';
import MuiDialog, {
  DialogProps as MuiDialogProps,
} from '@material-ui/core/Dialog';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';
import { colors } from '../core/colors';
import { SimpleIconButton } from '../core/simpleIconButton';
import { AlleLogo } from '../icons/alleLogo';
import { Close } from '../icons/close';
import { ThemeTypes } from './theme';

const StyledDialog = styled(({ dark, ...forwardProps }) => (
  <MuiDialog {...forwardProps} />
))<ThemeTypes>`
  &.MuiDialog-root {
    .MuiDialog-container {
      .MuiPaper-root {
        background-color: ${(props) =>
          props.dark ? colors.black : colors.white};
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: calc(85vh);
        min-width: calc(100vw - 24px);

        ${above.desktop`
          min-height: 615px;
          min-width: 615px;
        `}
      }
    }
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: ${(props: { showAlleLogo?: boolean }) =>
    props.showAlleLogo ? '35px' : '17px'};
  right: 19px;

  ${above.desktop`
    top: 20px;
    right: 22px;
  `}
`;

const AlleLogoContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 33px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${above.desktop`
    display: none;
  `}
`;

interface DialogProps extends MuiDialogProps {
  open: boolean;
  onClose?: () => void;
  dark?: boolean;
  showAlleLogo?: boolean;
  backdropBackgroundColor?: string;
  children?: React.ReactNode;
  disableBackdropClick?: boolean;
  hideCloseButton?: boolean;
}

class Dialog extends Component<DialogProps> {
  render() {
    const {
      children,
      dark,
      open,
      onClose,
      showAlleLogo,
      backdropBackgroundColor = 'rgba(255, 255, 255, 0.9)',
      hideCloseButton = false,
      ...forwardProps
    } = this.props;
    return (
      <StyledDialog
        dark={dark}
        open={open}
        onClose={onClose}
        BackdropProps={{
          style: {
            backgroundColor: backdropBackgroundColor,
          },
        }}
        {...forwardProps}
      >
        {showAlleLogo ? (
          <AlleLogoContainer>
            <AlleLogo />
          </AlleLogoContainer>
        ) : null}
        {onClose && !hideCloseButton ? (
          <CloseButtonContainer showAlleLogo={showAlleLogo}>
            <SimpleIconButton
              onClick={onClose}
              color={dark ? colors.white : colors.black}
              aria-label="Close"
              data-testid="dialog-close-icon-button"
            >
              <Close />
            </SimpleIconButton>
          </CloseButtonContainer>
        ) : null}
        {children}
      </StyledDialog>
    );
  }
}

export { Dialog };
