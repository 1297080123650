import React from 'react';
import { Text } from 'react-native';

import styled, {
  ThemeProvider,
  css,
  StyledComponent,
  DefaultTheme,
  ThemeProps,
} from 'styled-components';

import {
  TypographyBaseVariant,
  TypographyBaseSize,
  TypographyBaseWeight,
  TypographyBaseFontFamily,
  Responsify,
  TypographyComponent,
} from './types';
import {
  defaultTypographyTheme,
  DEFAULT_TYPOGRAPHY_VARIANT,
  DEFAULT_TYPOGRAPHY_SIZE,
  DEFAULT_TYPOGRAPHY_WEIGHT,
  DEFAULT_TYPOGRAPHY_FONT_FAMILY,
} from './theme';
import { above } from '../../utils/mediaTemplate';
import { deviceSizeIsAvailable } from '../../utils/deviceSize';
import { Platform } from 'react-native';

export const TYPOGRAPHY_TEXT_COLOR = '#020202';

export const TypographyThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ThemeProvider
      theme={(parentTheme: any) => ({
        ...(parentTheme ?? {}),
        typography: defaultTypographyTheme,
      })}
    >
      {children}
    </ThemeProvider>
  );
};

export interface TypographyBaseProps {
  variant?: Responsify<TypographyBaseVariant>;
  size?: Responsify<TypographyBaseSize>;
  weight?: Responsify<TypographyBaseWeight>;
  fontFamily?: Responsify<TypographyBaseFontFamily>;
  component?: TypographyComponent;
  children: string | React.ReactNode;
  className?: string;
}

interface TypographyBaseStyleProps {
  variant: Responsify<TypographyBaseVariant>;
  size: Responsify<TypographyBaseSize>;
  weight: Responsify<TypographyBaseWeight>;
  fontFamily: Responsify<TypographyBaseFontFamily>;
}

const cssForScreenSize = (
  device: 's' | 'm' | 'l',
  props: TypographyBaseStyleProps,
  theme: DefaultTheme
) => {
  // @ts-ignore typography is not defined on the theme in all places
  const typographyTheme = theme.typography ?? defaultTypographyTheme;
  const variant =
    typeof props.variant === 'string'
      ? props.variant
      : props.variant[device] ?? props.variant.s;
  const size =
    typeof props.size === 'string'
      ? props.size
      : props.size[device] ?? props.size.s;
  const weightInput =
    typeof props.weight === 'string'
      ? props.weight
      : props.weight[device] ?? props.weight.s;
  const fontFamilyInput =
    typeof props.fontFamily === 'string'
      ? props.fontFamily
      : props.fontFamily[device] ?? props.fontFamily.s;
  const fontConfig = typographyTheme.fontConfigs[variant][size];
  if (fontConfig === undefined) {
    throw new Error(
      `Attempted to render font that is not supported. Variant: ${props.variant}, Size: ${props.size}`
    );
  }
  const weight = typographyTheme.fontWeights[weightInput];
  if (weight === undefined) {
    throw new Error(
      `Attempted to render font that is not supported. Weight: ${props.weight}`
    );
  }
  const fontFamily = typographyTheme.fontFamilys[fontFamilyInput];
  return `
    font-size: ${fontConfig.fontSize}px;
    line-height: ${fontConfig.lineHeight}px;
    letter-spacing: ${
      fontConfig.letterSpacing !== undefined
        ? `${fontConfig.letterSpacing}px`
        : Platform.OS === 'web'
        ? 'normal'
        : undefined
    };
    text-transform: ${fontConfig.textTransform ?? 'none'};
    font-weight: ${weight};
    font-family: ${fontFamily};
  `;
};

export const typographyBaseStyles = (
  inputProps: Partial<TypographyBaseStyleProps> = {}
) => {
  const propsWithDefaults: TypographyBaseStyleProps = {
    variant: DEFAULT_TYPOGRAPHY_VARIANT,
    size: DEFAULT_TYPOGRAPHY_SIZE,
    weight: DEFAULT_TYPOGRAPHY_WEIGHT,
    fontFamily: DEFAULT_TYPOGRAPHY_FONT_FAMILY,
    ...inputProps,
  };

  const shared = css`
    margin: 0;
    color: ${TYPOGRAPHY_TEXT_COLOR};
    font-style: normal;
  `;

  return Platform.OS === 'web'
    ? css`
        ${shared};
        ${(props: ThemeProps<DefaultTheme>) =>
          cssForScreenSize('s', propsWithDefaults, props.theme)}
        ${above.tablet`
          ${(props: ThemeProps<DefaultTheme>) =>
            cssForScreenSize('m', propsWithDefaults, props.theme)}
        `}
        ${above.desktop`
          ${(props: ThemeProps<DefaultTheme>) =>
            cssForScreenSize('l', propsWithDefaults, props.theme)}
        `}
      `
    : css`
        ${shared};
        ${(props: ThemeProps<DefaultTheme>) =>
          cssForScreenSize('s', propsWithDefaults, props.theme)}
        ${(props: ThemeProps<DefaultTheme>) => {
          if (!deviceSizeIsAvailable('m')) return '';
          return cssForScreenSize('m', propsWithDefaults, props.theme);
        }}
        ${(props: ThemeProps<DefaultTheme>) => {
          if (!deviceSizeIsAvailable('l')) return '';
          return cssForScreenSize('l', propsWithDefaults, props.theme);
        }}
      `;
};

// Extract props that we don't want to be passed down to the HTML element
const P = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <p {...forwardProps}>{children}</p>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const Div = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <div {...forwardProps}>{children}</div>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const Span = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <span {...forwardProps}>{children}</span>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const H1 = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <h1 {...forwardProps}>{children}</h1>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const H2 = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <h2 {...forwardProps}>{children}</h2>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const H3 = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <h3 {...forwardProps}>{children}</h3>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const H4 = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <h4 {...forwardProps}>{children}</h4>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const H5 = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <h5 {...forwardProps}>{children}</h5>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;
const H6 = styled(
  ({
    variant,
    size,
    weight,
    fontFamily,
    children,
    ...forwardProps
  }: TypographyBaseProps) =>
    Platform.OS === 'web' ? (
      <h6 {...forwardProps}>{children}</h6>
    ) : (
      <Text {...forwardProps}>{children}</Text>
    )
)`
  ${typographyBaseStyles}
`;

const componentTypeToComponentMap: Record<
  TypographyComponent,
  StyledComponent<any, any, TypographyBaseStyleProps>
> = {
  p: P,
  div: Div,
  span: Span,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
};

/**
 * TypographyBase provides full flexibility for rendering text by allowing you to specify variant, size, weight, and font family with props.
 *
 * Use with caution as not all combinations of props are supported by default.
 *
 * For most cases, you should use the higher-level [Typography](https://storybook.stage.alle.com/?path=/story/alle-design-system-atoms-typography--typography) component which provides a set of preset font settings that line up 1-to-1 with the Alle Design System.
 */
const TypographyBase = ({
  children,
  component = 'p',
  variant = DEFAULT_TYPOGRAPHY_VARIANT,
  size = DEFAULT_TYPOGRAPHY_SIZE,
  weight = DEFAULT_TYPOGRAPHY_WEIGHT,
  fontFamily = DEFAULT_TYPOGRAPHY_FONT_FAMILY,
  className,
  ...forwardProps
}: TypographyBaseProps) => {
  const Component = componentTypeToComponentMap[component];
  return (
    <Component
      variant={variant}
      size={size}
      weight={weight}
      fontFamily={fontFamily}
      className={className}
      {...forwardProps}
    >
      {children}
    </Component>
  );
};

export { TypographyBase };
