import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@allergan-data-labs/alle-elements-layout';
import { NavigationDrawer } from '@allergan-data-labs/alle-elements-nav-drawer';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';
import { Button, ButtonGroup } from '@allergan-data-labs/alle-elements-button';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { NavigationItem } from './headerDrawerItem';
import { useHeaderTrackingEvents } from './useHeaderTrackingEvents';
import { useHeaderNavLinks } from './utils';

const initialDrawerItemsState: Record<string, boolean> = {
  brands: false,
  rewards: false,
};

type HeaderDrawerProps = {
  isOpen?: boolean;
  isLoggedIn?: boolean;
  toggleDrawer: () => void;
  getLoginLinkProps: () => { href: string; referrerData?: { from: string } };
  getJoinLinkProps: () => { href: string };
};

const HeaderDrawer = ({
  isOpen,
  isLoggedIn,
  toggleDrawer,
  getLoginLinkProps,
  getJoinLinkProps,
}: HeaderDrawerProps) => {
  const { location } = useHistory();
  const { colorMode } = useColorMode();
  const { Link } = useEnvironmentContext();
  const { trackLoginLinkClick, trackJoinAlleClick } = useHeaderTrackingEvents();

  const { links } = useHeaderNavLinks({
    isLoggedIn,
    currentPath: location.pathname,
  });

  const [drawerItemsState, setDrawerItemsState] = useState(
    initialDrawerItemsState
  );

  const toggleNavigationItem = (id: string) => {
    setDrawerItemsState({
      ...drawerItemsState,
      [id]: !drawerItemsState[id],
    });
  };

  return (
    <Box
      zIndex={1}
      sx={{
        '> div': {
          zIndex: 1,
        },
        section: {
          width: '100vw',
        },
      }}
    >
      <NavigationDrawer isOpen={isOpen} colorMode={colorMode}>
        {links.map((item) => (
          <NavigationItem
            key={item.id}
            item={item}
            isNestedOpen={drawerItemsState[item.id as string]}
            handleNestedToggle={() => toggleNavigationItem(item.id as string)}
            handleItemClick={toggleDrawer}
          />
        ))}
        {!isLoggedIn && (
          <ButtonGroup alignItems="center" gap={8} marginTop={'auto'}>
            <Link
              onClick={trackLoginLinkClick}
              {...getLoginLinkProps()}
              flex={1}
            >
              <Button size="sm" variant="outline" width="100%">
                Log In
              </Button>
            </Link>
            <Link
              onClick={trackJoinAlleClick}
              {...getJoinLinkProps()}
              flex={1}
              marginInlineStart={`0 !important`}
            >
              <Button size="sm" width="100%">
                Join Allē
              </Button>
            </Link>
          </ButtonGroup>
        )}
      </NavigationDrawer>
    </Box>
  );
};

export { HeaderDrawer };
