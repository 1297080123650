import React from 'react';
import {
  Typography,
  TypographyProps,
} from '@packages/deprecated-universal-component-library/src/atoms/typography';

const CopyrightFallback = ({
  preset,
}: {
  preset?: TypographyProps['preset'];
}) => (
  <Typography
    preset={
      preset || {
        s: 'body-S-regular',
        m: 'body-S-regular',
        l: 'body-M-regular',
      }
    }
    css={`
      color: inherit;
    `}
    data-testid="copyright-2022"
  >
    &copy; 2024 AbbVie. All rights reserved. Allē and its design are service
    marks of Allergan, Inc., an AbbVie company. All trademarks are the property
    of their respective owners.
    <br />
    Allergan Aesthetics reserves the right to alter or cancel the Allē Consumer
    Loyalty Program with no advance notice or obligation.
  </Typography>
);

export { CopyrightFallback };
