const red = {
  100: '#F9EBE9',
  200: '#F0CCC8',
  300: '#E29992',
  400: '#D3675B',
  500: '#C53425',
  600: '#94271C',
  700: '#621A12',
  800: '#310D09',
  900: '#140504',
};

export { red };
