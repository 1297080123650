import React from 'react';
import styled from 'styled-components';
import { H1, H3, H5 } from '../../core/typography/typographyDEPRECATED';
import { colors } from '../../core/colors';
import { AlleButton as Button } from '../../core/alleButton';
import { LinkDEPRECATED } from '../../core/linkDEPRECATED';
import { RightArrow } from '../../icons/rightArrow';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  flex: 1;
  max-width: 846px;
  padding: 32px;
`;

const Header1 = styled(H1)`
  margin: 0;
  margin-bottom: 10px;
`;

const Header2 = styled(H3).attrs({ as: 'h2' })`
  margin: 0;
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    margin-top: 100px;
    padding-left: 41px;
    padding-right: 24px;
    border-radius: 0;
    min-width: 212px;

    & .MuiButton-label {
      justify-content: space-between;
    }

    & .MuiSvgIcon-root path {
      fill: ${colors.skin};
    }
  }
`;

const ButtonText = styled(H5).attrs({ as: 'span' })``;

interface ErrorPageProps {
  title: string;
  subtitle: string | React.ReactNode;
  buttonRoute?: string;
  buttonText?: string;
  dark?: boolean;
  className?: string;
}

const ErrorPage: React.FunctionComponent<ErrorPageProps> = ({
  title,
  subtitle,
  buttonRoute,
  buttonText,
  dark,
  className,
}) => {
  return (
    <Wrapper className={className} data-testid="error-page-container">
      <Container>
        <Header1 color={colors.nudeDark}>{title}</Header1>
        <Header2 color={dark ? colors.white : colors.black}>{subtitle}</Header2>
        {buttonRoute && (
          <LinkDEPRECATED textOnly={false} to={buttonRoute}>
            <StyledButton dark={dark} buttonType="contained">
              <ButtonText color={dark ? colors.black : colors.white}>
                {buttonText || 'Back Home'}
              </ButtonText>
              <RightArrow />
            </StyledButton>
          </LinkDEPRECATED>
        )}
      </Container>
    </Wrapper>
  );
};

export { ErrorPage };
