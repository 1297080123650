import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const YoutubeLogo: React.FunctionComponent<{
  pathFill?: string;
  className?: string;
}> = ({ pathFill, className }) => (
  <IconContainer className={className} pathFill={pathFill}>
    <SvgIcon width="21" height="14" viewBox="0 0 21 14">
      <path
        id="youtube-icon-path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3152 0.418176C19.1762 0.648667 19.8529 1.32532 20.0834 2.18637H20.0817C20.4999 3.74547 20.4999 7.00033 20.4999 7.00033C20.4999 7.00033 20.4999 10.2552 20.0817 11.8143C19.8512 12.6753 19.1746 13.352 18.3135 13.5825C16.7544 14.0007 10.4999 14.0007 10.4999 14.0007C10.4999 14.0007 4.24535 14.0007 2.68625 13.5825C1.8252 13.352 1.14854 12.6753 0.918054 11.8143C0.499878 10.2552 0.499878 7.00033 0.499878 7.00033C0.499878 7.00033 0.499878 3.74547 0.918054 2.18637C1.14854 1.32532 1.8252 0.648667 2.68625 0.418176C4.24535 0 10.4999 0 10.4999 0C10.4999 0 16.7544 0 18.3152 0.418176ZM13.6971 7.00033L8.49956 10V4.00066L13.6971 7.00033Z"
      />
    </SvgIcon>
  </IconContainer>
);

export { YoutubeLogo };
