import React from 'react';

interface ContentViewPayloadTypes {
  count?: number;
}

export interface ContentViewOptionTypes {
  id: string;
  payload?: ContentViewPayloadTypes | null;
}

interface ContentViewContextValue {
  content: ContentViewOptionTypes[] | undefined;
  addContentView: (content: ContentViewOptionTypes) => void;
}

const ContentViewContext = React.createContext<ContentViewContextValue>({
  content: [],
  addContentView: () => {},
});

interface ContentViewProviderProps {
  children: React.ReactNode;
}

const ContentViewProvider: React.FunctionComponent<
  ContentViewProviderProps
> = ({ children }) => {
  const [content, setContent] = React.useState<ContentViewOptionTypes[]>([]);

  const addContentView = React.useCallback(
    (contentView: ContentViewOptionTypes) => {
      setContent((previousContentView) => {
        return [
          {
            ...contentView,
          },
          ...previousContentView
            .filter((previousContent) => {
              if (previousContent.id !== contentView.id) return true;

              return false;
            })
            .map((previousContent) => {
              return { ...previousContent };
            }),
        ];
      });
    },
    []
  );

  return (
    <ContentViewContext.Provider value={{ content, addContentView }}>
      {children}
    </ContentViewContext.Provider>
  );
};

const useContentView = () => {
  const context = React.useContext<ContentViewContextValue>(ContentViewContext);

  if (context === undefined) {
    throw new Error('useContentView must be used within a ContentViewProvider');
  }
  return context;
};

export { ContentViewProvider, useContentView };
