import React from 'react';
import { ErrorPage } from '../components/errorPages/errorPage';

interface GeneralErrorProps {
  buttonRoute?: string;
  buttonText?: string;
}

const GeneralError: React.FunctionComponent<GeneralErrorProps> = ({
  buttonRoute,
  buttonText,
}) => (
  <ErrorPage
    title="Error"
    buttonRoute={buttonRoute}
    buttonText={buttonText}
    subtitle={
      <span>
        An unknown error has occurred.
        <br />
        Please delete your cookies and try again.
      </span>
    }
  />
);

export { GeneralError };
